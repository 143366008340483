import { nextAppEnv } from "./environment";

/*  due to the nature of static rendering
    this function should Not be used on initial render of the app
    without a fallback environment variable
*/
export const whereAmI = (): string => {
  if (typeof document !== "undefined") {
    return document?.location?.href ?? nextAppEnv.application.publicUrl;
  } else {
    return nextAppEnv.application.publicUrl ?? "";
  }
};
