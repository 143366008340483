import { IHeadingAndMessage } from "@Utils/snackbarHandlers";

export type TErrorCode =
  | "WF_COMMON_001"
  | "WF_COMMON_002"
  | "WF_COMMON_006"
  | "WF_CART_001"
  | "WF_CART_002"
  | "WF_CART_003"
  | "WF_CART_006"
  | "WF_CART_008"
  | "WF_CART_009"
  | "WF_CART_010"
  | "WF_CART_029"
  | "WF_CART_030"
  | "WF_CART_031"
  | "WF_FRESHDESK_001"
  | "WF_CUSTOMER_001"
  | "WF_CUSTOMER_002"
  | "WF_CUSTOMER_003"
  | "WF_CUSTOMER_004"
  | "WF_CUSTOMER_005"
  | "WF_CUSTOMER_006"
  | "WF_CUSTOMER_007"
  | "WF_CUSTOMER_008"
  | "WF_PAYMENT_001"
  | "WF_PAYMENT_002"
  | "WF_PAYMENT_003"
  | "WF_PAYMENT_004"
  | "WF_PAYMENT_005"
  | "WF_STORE_001"
  | "WF_STORE_002"
  | "WF_STORE_003"
  | "WF_STORE_004"
  | "WF_STORE_005"
  | "WF_ORDER_001";

export interface IErrorCodeMap extends Record<TErrorCode, TErrorCode> {}

interface ErrorMessages {
  WF_COMMON_006_MESSAGE: string;
}

export const ERROR_CODES: IErrorCodeMap = {
  WF_COMMON_001: "WF_COMMON_001", // Something Bad Happened; Used for generic service that has become unavailable.
  WF_COMMON_002: "WF_COMMON_002", // Generic Bad Request; Fall through handler for bad requests.
  WF_COMMON_006: "WF_COMMON_006", // Cart expired
  WF_CART_001: "WF_CART_001", // Invalid Cart ID
  WF_CART_002: "WF_CART_002", // Invalid SKU
  WF_CART_003: "WF_CART_003", // Insufficient Inventory
  WF_CART_006: "WF_CART_006", // Invalid zipcode.
  WF_CART_008: "WF_CART_008", // Error in tax calculation
  WF_CART_009: "WF_CART_009", // Invalid Discount Code
  WF_CART_010: "WF_CART_010", // Error in Processing the Request
  WF_CART_029: "WF_CART_029", // This discount code is no longer valid
  WF_CART_030: "WF_CART_030", // Minimum spend has not been met
  WF_CART_031: "WF_CART_031", // This discount code is not valid on this order
  WF_FRESHDESK_001: "WF_FRESHDESK_001", // Request limit reached, retry after sometime
  WF_CUSTOMER_001: "WF_CUSTOMER_001", // There is already an existing customer with the provided email
  WF_CUSTOMER_002: "WF_CUSTOMER_002", // Email  has wrong format
  WF_CUSTOMER_003: "WF_CUSTOMER_003", // Password does not meet the minimum requirement
  WF_CUSTOMER_004: "WF_CUSTOMER_004", // Customer account with the given credentials not found; This code is used during customer login, If the given credentials are invalid email or wrong password.
  WF_CUSTOMER_005: "WF_CUSTOMER_005", // First name is required; If any mandatory field is missed.
  WF_CUSTOMER_006: "WF_CUSTOMER_006", // No Customer Was Found with the specified Password Token ID
  WF_CUSTOMER_007: "WF_CUSTOMER_007", // Commercial Tool Internal Server Error
  WF_CUSTOMER_008: "WF_CUSTOMER_008", // No Customer was found with that email
  WF_PAYMENT_001: "WF_PAYMENT_001", // Payment Service call resulted in error; this error is more or less happening when payment service can not reach out Adyen
  WF_PAYMENT_002: "WF_PAYMENT_002", // Payment Service API error occurred; Payment Service API Call to Adyen failed.
  WF_PAYMENT_003: "WF_PAYMENT_003", // Original pspReference required for this operation; Payment Service API Call to Adyen failed while calling Adyen to do CancelAuth
  WF_PAYMENT_004: "WF_PAYMENT_004", // Field 'countryCode' is not valid; Payment Service API Call to Adyen failed while fetching payment Methods
  WF_PAYMENT_005: "WF_PAYMENT_005", // Invalid OrderNumber; Invalid order number to pull the cart
  WF_STORE_001: "WF_STORE_001", // dynamic message from backend error; when API try to fetch stores location and fails
  WF_STORE_002: "WF_STORE_002", // dynamic message from backend error; UNAUTHORIZED to call wildfox
  WF_STORE_003: "WF_STORE_003", // dynamic message from backend error; cart specified delivery window or shipping window service call failed?
  WF_STORE_004: "WF_STORE_004", // dynamic message from backend error; api call to create custom store hours failed
  WF_STORE_005: "WF_STORE_005", // dynamic message from backend error; Server runtime error
  WF_ORDER_001: "WF_ORDER_001", // Payment Auth failed; It will be thrown when Payment Auth call to the Payment Service is failed (or refused)
};

export const ERROR_MESSAGES: ErrorMessages = {
  WF_COMMON_006_MESSAGE: "The cart is not in active state.",
};

export type TErrorCodeTranslationValue = string | IHeadingAndMessage;

export interface IErrorCodeTranslation
  extends Partial<Record<TErrorCode, string | TErrorCodeTranslationValue>> {}

interface AxiosErrorCodes {
  ECONNABORTED: string;
}

export const AXIOS_ERROR_CODES: AxiosErrorCodes = {
  ECONNABORTED: "ECONNABORTED",
};
