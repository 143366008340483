import { getLocaleLanguageCode } from "@Utils/reduxCommon";
import { LOCALE } from "./common";

export interface ILocaleTranslations {
  esMX: string;
  frCA: string;
  enCA: string;
  enUS: string;
}
export interface IGetTranslationContent {
  languageCode?: string;
  localeTranslation: ILocaleTranslations;
}

export interface IGetTranslationFromErrorCode {
  [key: string]: (postalCode?: string, languageCode?: string) => string;
}

export const getTranslatedContent = (params: IGetTranslationContent) => {
  let result;
  const languageCode = params?.languageCode || getLocaleLanguageCode();
  switch (languageCode) {
    case LOCALE.ES_MX:
      result = params?.localeTranslation.esMX;
      break;
    case LOCALE.FR_CA:
      result = params?.localeTranslation.frCA;
      break;
    case LOCALE.EN_CA:
      result = params?.localeTranslation.enCA;
      break;
    case LOCALE.EN_US:
    default:
      result = params?.localeTranslation.enUS;
  }
  return result;
};

export const GENERIC_ERR_MSG: IGetTranslationFromErrorCode = {
  ERROR: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "Hubo un error",
        frCA: "Il y avait une erreur",
        enCA: "There was an error",
        enUS: "There was an error",
      },
    });
  },
};

export const GENERIC_EVENT_SUCCESS_HEADING: IGetTranslationFromErrorCode = {
  SUCCESS: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "Éxito",
        frCA: "Succès",
        enCA: "Success",
        enUS: "Success",
      },
    });
  },
};

export const GENERIC_EVENT_ERR_HEADING: IGetTranslationFromErrorCode = {
  FAILED: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "Fallido",
        frCA: "Manqué",
        enCA: "Failed",
        enUS: "Failed",
      },
    });
  },
  REQUEST_FAILED: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "Solicitud Fallida",
        frCA: "Demande Échoué",
        enCA: "Request Failed",
        enUS: "Request Failed",
      },
    });
  },
};

export const GENERIC_EVENT_SUCCESS_MSG: IGetTranslationFromErrorCode = {
  SUCCESS: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "La solicitud se ha realizado correctamente",
        frCA: "La demande a été acceptée",
        enCA: "The request was successful",
        enUS: "The request was successful",
      },
    });
  },
};

export const GENERIC_EVENT_ERR_MSG: IGetTranslationFromErrorCode = {
  GET_WINDOWS: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "Se ha producido un error al obtener la disponibilidad de entrega",
        frCA: "Il y a eu une erreur de récupération de la disponibilité de la livraison",
        enCA: `Sorry, this address is outside our delivery zone`,
        enUS: "There was an error fetching delivery availability",
      },
    });
  },
  RESERVE_WINDOWS: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "Hubo un error al reservar disponibilidad",
        frCA: "Une erreur s'est produite lors de la réservation de la disponibilité",
        enCA: "There was an error reserving availability",
        enUS: "There was an error reserving availability",
      },
    });
  },
  GET_STORES: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "Hubo un error al obtener la información de la tienda",
        frCA: "Une erreur s'est produite lors de la récupération des informations sur le magasin",
        enCA: "There was an error fetching store info",
        enUS: "There was an error fetching store info",
      },
    });
  },
  GET_CART_ITEMS: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "Hubo un error al obtener los artículos del carrito",
        frCA: "Une erreur s'est produite lors de la récupération des éléments du panier",
        enCA: "There was an error fetching cart items",
        enUS: "There was an error fetching cart items",
      },
    });
  },
  ADD_SHIPPING_ADDRESS: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "Se ha producido un error al agregar la dirección de envío",
        frCA: "Il y a eu une erreur dans l'ajout de l'adresse de livraison",
        enCA: "There was an error adding the shipping address",
        enUS: "There was an error adding the shipping address",
      },
    });
  },
};

export const COMMON_SUCCESS_TRANSLATION: IGetTranslationFromErrorCode = {
  CHECK_YOUR_EMAIL: (languageCode?: string) => {
    return getTranslatedContent({
      languageCode: languageCode,
      localeTranslation: {
        esMX: "La solicitud se ha realizado correctamente. Por favor, revise su correo electrónico.",
        frCA: "La demande a été acceptée. S'il vous plaît vérifier votre e-mail.",
        enCA: "The request was successful. Please check your email.",
        enUS: "The request was successful. Please check your email.",
      },
    });
  },
};

export const AUTO_ERROR_CODE_HEADING_TRANSLATION: IGetTranslationFromErrorCode =
  {
    SOME_ERROR_CODE_EXAMPLE: (languageCode?: string) => {
      // delete once we have more headings
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Peligro Will Robinson",
          frCA: "Danger Will Robinson",
          enCA: "Danger Will Robinson",
          enUS: "Danger Will Robinson",
        },
      });
    },
  };

export const AUTO_ERROR_CODE_MESSAGE_TRANSLATION: IGetTranslationFromErrorCode =
  {
    WF_CART_001: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "ID de carrito no válido",
          frCA: "ID de panier non valide",
          enCA: "Invalid Cart ID",
          enUS: "Invalid Cart ID",
        },
      });
    },
    WF_CART_006: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Código postal no válido",
          frCA: "Code postal non valide",
          enCA: "Invalid Zipcode",
          enUS: "Invalid Zipcode",
        },
      });
    },
    WF_CART_008: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Se ha producido un error en el cálculo de impuestos",
          frCA: "Il y a eu une erreur de calcul de l'impôt",
          enCA: "There was a tax calculation error",
          enUS: "There was a tax calculation error",
        },
      });
    },
    WF_CART_009: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Código de descuento no válido",
          frCA: "Code de remise non valide",
          enCA: "Invalid Discount Code",
          enUS: "Invalid Discount Code",
        },
      });
    },
    WF_CART_010: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Se ha producido un error al procesar la solicitud",
          frCA: "Il y a eu une erreur de traitement de la demande",
          enCA: "There was an error processing the request",
          enUS: "There was an error processing the request",
        },
      });
    },
    WF_COMMON_001: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Algo malo sucedió",
          frCA: "Quelque chose de mal s'est produit",
          enCA: "Something bad happened",
          enUS: "Something bad happened",
        },
      });
    },
    WF_COMMON_002: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Solicitud incorrecta genérica",
          frCA: "Mauvaise demande générique",
          enCA: "Generic bad request",
          enUS: "Generic bad request",
        },
      });
    },
    WF_COMMON_006: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Su carrito fue utilizado para un pedido anterior. Por favor crea un nuevo carrito",
          frCA: "Votre panier a été utilisé pour une précédente commande. Veuillez créer un nouveau panier",
          enCA: "Your cart was used for a previous order. Please create a new cart",
          enUS: "Your cart was used for a previous order. Please create a new cart",
        },
      });
    },
    WF_CUSTOMER_003: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Contraseña no válida",
          frCA: "Mot de passe non valide",
          enCA: "Invalid Password",
          enUS: "Invalid Password",
        },
      });
    },
    WF_CUSTOMER_006: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "No se encontró ningún cliente con el ID de token de contraseña especificado",
          frCA: "Aucun client n'a été trouvé avec l'ID de jeton de mot de passe spécifié",
          enCA: "No Customer Was Found with the specified Password Token ID",
          enUS: "No Customer Was Found with the specified Password Token ID",
        },
      });
    },
    WF_CUSTOMER_008: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "No se encontró ningún cliente con ese correo electrónico",
          frCA: "Aucun client n'a été trouvé avec cet e-mail",
          enCA: "No Customer was found with that email",
          enUS: "No Customer was found with that email",
        },
      });
    },
    WF_FRESHDESK_001: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Se alcanzó el límite de solicitudes, vuelva a intentarlo después de algún tiempo",
          frCA: "Limite de demandes atteinte, réessayez après un certain temps",
          enCA: "Request limit reached, retry after sometime",
          enUS: "Request limit reached, retry after sometime",
        },
      });
    },

    WF_PAYMENT_001: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Se ha producido un error al procesar el pago",
          frCA: "Il y a eu un paiement de traitement d'erreur",
          enCA: "There was an error processing payment",
          enUS: "There was an error processing payment",
        },
      });
    },
    WF_PAYMENT_002: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Se ha producido un error en el servicio de pago",
          frCA: "Une erreur de service de paiement s'est produite",
          enCA: "A payment service error occurred",
          enUS: "A payment service error occurred",
        },
      });
    },
    WF_PAYMENT_003: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Se ha producido un error de autenticación de pago",
          frCA: "Une erreur d'authentification de paiement s'est produite",
          enCA: "A payment authentication error occurred",
          enUS: "A payment authentication error occurred",
        },
      });
    },
    WF_PAYMENT_004: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Se ha producido un error de servicio de pago con un código de país no válido",
          frCA: "Une erreur de service de paiement s'est produite avec un code de pays non valide",
          enCA: "A payment service error occurred with an invalid country code",
          enUS: "A payment service error occurred with an invalid country code",
        },
      });
    },
    WF_PAYMENT_005: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Número de pedido no válido",
          frCA: "Numéro de commande non valide",
          enCA: "Invalid Order Number",
          enUS: "Invalid Order Number",
        },
      });
    },
    WF_STORE_001: (languageCode?: string) => {
      return getTranslatedContent({
        languageCode: languageCode,
        localeTranslation: {
          esMX: "Se ha producido un error al obtener ubicaciones de tiendas",
          frCA: "Il y a eu une erreur dans la récupération de l'emplacement des magasins",
          enCA: "There was an error fetching store locations",
          enUS: "There was an error fetching store locations",
        },
      });
    },
  };
