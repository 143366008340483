export const loadAsyncScript = (src: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.onerror = reject;
    script.onload = resolve;
    document.head.appendChild(script);
  });
};
