/* eslint-disable sonarjs/cognitive-complexity */
import algoliasearch, { SearchIndex } from "algoliasearch";
import algoliasearchHelper from "algoliasearch-helper";
import { SearchResponse } from "algoliasearch-helper/types/algoliasearch";
import { IFilter, FilterType } from "@Components/ui/filter";
import { ITooltipData } from "@Types/Contentful";
import {
  hitsResHit,
  SearchHitsByCategoryProps,
  IDisjunctiveFacetsRefinements,
} from "@Types/SearchUtils";
import { ICTProduct } from "@Types/product";
import logger from "@Utils/Logger";

import {
  algoliaAPIKey,
  algoliaAppId,
  algoliaIndex,
  /* algoliaProductIndexMostPopular,  // Hidden by Lauren's request.. will be uncommented uppon her request when she has the appropriate data to feed this. */
  algoliaProductIndexPriceAsc,
  algoliaProductIndexPriceDesc,
  algoliaProductIndexTitleAsc,
  algoliaProductIndexTitleDesc,
  algoliaSuggestionsIndex,
  algoliaRecipesIndex,
  SORT_BY,
  RECIPE_FACETS_KEYS,
  RECIPE_FACETS_TITLE_KEYS,
  initialStateAvailableFilters,
} from "./algoliaData";
import {
  facetsList,
  priceFilterData,
  customerFacingFilterNames,
  PRICE_VALUE,
  getCustomerFacingFilterSortOrder,
  AlgoliaFilterType,
} from "./searchUtilsData";

export const getProductAlgoliaIndexBySort = (sort?: string) => {
  switch (sort) {
    /* case SORT_BY.MOST_POPULAR: // Hidden by Lauren's request.. will be uncommented uppon her request when she has the appropriate data to feed this.
      return algoliaProductIndexMostPopular; */
    case SORT_BY.PRICE_ASC:
      return algoliaProductIndexPriceAsc;
    case SORT_BY.PRICE_DESC:
      return algoliaProductIndexPriceDesc;
    case SORT_BY.TITLE_ASC:
      return algoliaProductIndexTitleAsc;
    case SORT_BY.TITLE_DESC:
      return algoliaProductIndexTitleDesc;
    default:
      return algoliaIndex;
  }
};

export const returnHits = (callback: Function) => (content: any) => {
  content?.results?.hits?.length === 0
    ? callback([])
    : callback(content?.results?.hits);
};

export const getSuggestionsHits = (
  term: string,
  callback: Function,
  isCanadianUser?: boolean
) => {
  const searchParameter = term?.toLowerCase();
  const AlgoliaClient = algoliasearch(algoliaAppId, algoliaAPIKey);
  const querySuggestionsIndex: SearchIndex = AlgoliaClient.initIndex(
    algoliaSuggestionsIndex
  );

  querySuggestionsIndex
    .search(searchParameter)
    .then((res: SearchResponse<unknown>) => {
      const suggestionsArr: hitsResHit[] | any = [];

      if (isCanadianUser) {
        if (!res?.hits?.length) {
          callback([]);
          return [];
        }

        return callback(res?.hits);
      } else {
        const suggestions = res?.hits?.filter((hit: any) =>
          hit?.query?.startsWith(searchParameter)
        );
        suggestions?.forEach((el: any) => {
          suggestionsArr?.push(el?.query);
        });

        if (!suggestions?.length) {
          callback([]);
          return [];
        }

        return callback(suggestionsArr);
      }
    });
};

export const getSearchHits = (
  callback: Function,
  query?: string | string[]
) => {
  if (!query || Array.isArray(query)) return;

  const queryStr = query.toString(); // to avoid type error.
  // Algolia client. Mandatory to instantiate the Helper.
  const algolia = algoliasearch(algoliaAppId, algoliaAPIKey);

  // Algolia Helper
  const helper = algoliasearchHelper(algolia, algoliaIndex, {
    facets: ["name"],
    disjunctiveFacets: ["name", "category", "description", "sku", "attibutes"],
    hitsPerPage: 500,
    maxValuesPerFacet: 50,
    getRankingInfo: true,
    typoTolerance: true,
  });

  helper.on("error", (e) => {
    logger.error(e);
    return [];
  });
  helper.setQuery(queryStr).search();
  helper.on("result", returnHits(callback));
};

export const getSearchRecipesHits = (
  query = "",
  {
    page,
    locale = "",
    hitsPerPage = 12,
    disjunctiveFacetsRefinements,
  }: {
    page?: number;
    locale?: string;
    hitsPerPage?: number;
    disjunctiveFacetsRefinements?: IDisjunctiveFacetsRefinements;
  } = {},
  algoliaSearch = algoliasearch,
  algoliaSearchHelper = algoliasearchHelper
): Promise<{
  recipes: any[];
  page: number;
  nbPages: number;
  nbHits: number;
  filters: IFilter[];
}> => {
  return new Promise((resolve, reject) => {
    const queryStr = query.toString();
    const algolia = algoliaSearch(algoliaAppId, algoliaAPIKey);
    const helper = algoliaSearchHelper(algolia, algoliaRecipesIndex, {
      hitsPerPage,
      disjunctiveFacetsRefinements,
      maxValuesPerFacet: 50,
      getRankingInfo: true,
      typoTolerance: true,
      facets: [
        `${RECIPE_FACETS_KEYS.COOK_TIME}.${locale}`,
        `${RECIPE_FACETS_KEYS.RECIPE_CARD_SERVING}.${locale}`,
        `${RECIPE_FACETS_KEYS.RECIPE_CARD_DIFFICULTY}.${locale}`,
      ],
    });

    helper.setQuery(queryStr);
    if (typeof page === "number") {
      helper.setPage(page);
    }
    helper.search();

    helper.on("error", (e) => {
      logger.error(e);
      reject("Error while searching recipes");
    });

    helper.on("result", (algoliaResults) => {
      const hits = algoliaResults?.results?.hits;
      const formattedHits = formatAlgoliaRecipeHits(hits);
      const filters = Object.values(algoliaResults?.results?.facets ?? {}).map(
        ({ name, data }) => {
          let label;
          const key = name.replace(`.${locale}`, "");

          hits.some((hit) => {
            label = hit?.fields?.[RECIPE_FACETS_TITLE_KEYS[key]]?.[locale];

            return label;
          });

          return {
            value: name,
            label: label ?? name,
            type: FilterType.checkbox,
            options: Object.keys(data ?? {}).map((value) => ({
              value,
              label: value,
              checked: false,
            })),
          };
        }
      );

      resolve({
        filters,
        recipes: formattedHits,
        page: algoliaResults?.results?.page || 0,
        nbPages: algoliaResults?.results?.nbPages || 0,
        nbHits: algoliaResults?.results?.nbHits || 0,
      });
    });
  });
};

export const formatAlgoliaRecipeHits = (hits: any[], locale = "en-CA") => {
  return hits?.map((hit) => ({
    title: hit?.fields?.title?.[locale],
    slug: hit?.fields?.slug?.[locale],
    imageId: hit?.fields?.recipeBanner?.[locale]?.sys?.id,
  }));
};

export const getDisjunctiveFacetsRefinements = (
  filterBy?: SearchHitsByCategoryProps["options"]["filterBy"]
): IDisjunctiveFacetsRefinements => {
  const disjunctiveFacetsRefinements: IDisjunctiveFacetsRefinements = {};

  if (filterBy?.subCategories?.length) {
    disjunctiveFacetsRefinements.subCategories = filterBy.subCategories;
  }
  if (filterBy?.programDescription?.length) {
    disjunctiveFacetsRefinements.programDescription =
      filterBy.programDescription;
  }
  if (filterBy?.cut?.length) {
    disjunctiveFacetsRefinements.cut = filterBy.cut;
  }

  return disjunctiveFacetsRefinements;
};

export const returnCategoryValues = (callback: Function) => (content: any) => {
  content?.results?.hits?.length === 0
    ? callback([])
    : callback(content?.results);
};

const removePriceFilter = (filter: any) => {
  const newFilter: any = {};
  for (const key in filter) {
    if (key !== "price") newFilter[key] = filter[key];
  }
  return newFilter;
};

const handlePriceFilter = (priceFilter: any) => {
  let priceValues: any[] = [];

  priceFilter?.forEach((value: any) => {
    if (value === "under10") {
      priceValues = [`${PRICE_VALUE}<1000`];
    } else if (value === "50andAbove") {
      priceValues = [`${PRICE_VALUE}>=5000`];
    } else {
      const splitBy = value.includes("custom") ? "custom" : "to";
      const [minPrice, maxPrice] = value?.split(splitBy);

      if (!maxPrice && !minPrice) {
        return;
      } else if (!maxPrice) {
        priceValues = [`${PRICE_VALUE}>=${minPrice * 100}`];
      } else if (!minPrice) {
        priceValues = [`${PRICE_VALUE}<${maxPrice * 100}`];
      } else if (maxPrice === minPrice) {
        priceValues = [`${PRICE_VALUE}=${maxPrice * 100}`];
      } else {
        priceValues = [
          `${PRICE_VALUE}>=${minPrice * 100}`,
          `${PRICE_VALUE}<${maxPrice * 100}`,
        ];
      }
    }
  });
  return priceValues;
};

export const getSearchHitsByCategory = (
  callback: SearchHitsByCategoryProps["callback"],
  category: SearchHitsByCategoryProps["category"],
  options: SearchHitsByCategoryProps["options"] = {}
) => {
  if (!category || Array.isArray(category)) {
    return;
  }

  const categoryStr = category.toString(); // to avoid type error.
  // Algolia client. Mandatory to instantiate the Helper.
  const algolia = algoliasearch(algoliaAppId, algoliaAPIKey);

  const { sortBy, filterBy } = options;
  const priceFilter = filterBy?.price;
  const dynamicFilters = priceFilter ? removePriceFilter(filterBy) : filterBy;

  const priceFilterValue = handlePriceFilter(priceFilter);

  const searchOptions = {
    facets: facetsList,
    facetsRefinements: {
      categories: [categoryStr],
    },
    numericFilters: priceFilterValue,
    disjunctiveFacetsRefinements: dynamicFilters,
    hitsPerPage: 500, // TODO: reduce this and apply page when adding real pagination
    maxValuesPerFacet: 50,
    getRankingInfo: true,
    typoTolerance: true,
  };

  // Algolia Helper
  const helper = algoliasearchHelper(
    algolia,
    getProductAlgoliaIndexBySort(sortBy) || algoliaIndex,
    searchOptions
  );

  helper.on("error", (e) => {
    logger.error(e);
    return callback([]);
  });
  helper.on("result", returnCategoryValues(callback));

  helper.search();
};

export const algoliaToCTTransformer = (
  productList: {}[],
  lang: string,
  tooltipData: ITooltipData
): ICTProduct[] => {
  if (!productList || !productList.length) {
    return [];
  }

  const newProductList: ICTProduct[] = [];

  const transformCategories = (categories?: string[]) =>
    categories?.map((item) => ({
      typeId: item,
    })) ?? [];

  productList?.forEach((el: any) => {
    const validImages = [];
    if (el?.otherImage?.url) {
      if (el?.image?.url) validImages.push(el.image);
      if (el?.otherImage?.url) validImages.push(el.otherImage);
    }
    newProductList.push({
      ctData: {
        masterData: {
          current: {
            slug: { [`${lang}`]: el.slug },
            name: {
              [`${lang}`]: el.name,
            },
            description: {
              [`${lang}`]: el.description,
            },
            categories: transformCategories(el.categories),
            masterVariant: {
              sku: el.sku,
              prices: el.price,
              availability: {
                isOnStock: el.isOnStock,
              },
              attributes: el.attributes,
            },
          },
        },
        productType: {
          obj: {
            name: el.productType,
          },
        },
      },
      tooltipData: tooltipData,
      images: validImages,
      src: el.image,
    });
  });

  return newProductList;
};

export const reduceTooltipData = (
  titleProp?: string,
  descriptionProp?: string,
  exampleProp?: string
) => {
  return {
    title: titleProp ? titleProp : "",
    description: descriptionProp ? descriptionProp : "",
    example: exampleProp ? exampleProp : "",
  };
};

export const formatStickyFilters = (
  results: any,
  category?: string | string[]
) => {
  const filtersList: {}[] = [];
  const lowCaseCategory = category?.toString()?.toLowerCase();

  const addWhiteSpace = (str: string) => {
    return str.replace(/([A-Z])/g, " $1").trim();
  };

  const handleFilterName = (str: string) => {
    let value = str;

    customerFacingFilterNames?.map((itr) => {
      if (itr?.key === str) value = itr?.value;
    });
    return addWhiteSpace(value);
  };

  const structFacets = (items: any, facet: any) => {
    return items
      .map((el: any) => {
        if (facet?.name === "subCategories") {
          const [itemCategory, subCategory] = el.split("-");
          if (itemCategory?.toLowerCase() === lowCaseCategory) {
            return {
              name: addWhiteSpace(subCategory || el),
              value: el.toLocaleLowerCase(),
            };
          }
          return;
          //NOTE: for future reference
          // return {
          //   name: addWhiteSpace(`${itemCategory} ${subCategory}`),
          //   value: el.toLocaleLowerCase(),
          // };
        }
        return {
          name: el,
          value: el.toLocaleLowerCase(),
        };
      })
      .filter((elem: any) => elem !== undefined)
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
  };

  if (results?.facets?.length) {
    results?.facets?.map((facet: any) => {
      if (facet?.name === "categories" || facet?.name === "description") return;
      filtersList.push({
        type: facet.name,
        name: handleFilterName(facet.name),
        items: structFacets(Object.keys(facet.data), facet),
        sortOrder: getCustomerFacingFilterSortOrder(facet.name),
      });
    });
  }
  // Previous selected filters is present in _rawResults of Algolia response
  const selectedFacets = results?._rawResults
    ?.filter((arr: any, index: number) => index > 0 && arr.facets)
    .map((arr: any) => {
      for (const k in arr.facets) {
        return {
          type: k,
          name: handleFilterName(k),
          items: structFacets(Object.keys(arr.facets[k]), { name: k }),
        };
      }
    });
  const selectedFacetsList = {
    initialFacets: selectedFacets,
    sortOrder: getCustomerFacingFilterSortOrder(
      AlgoliaFilterType.SELECTED_FACETS_LIST
    ),
  };
  const filters = [
    ...initialStateAvailableFilters,
    selectedFacetsList,
    ...filtersList,
    priceFilterData,
  ];
  filters.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
  return filters;
};

export const formatDynamicFilters = (
  hits: any,
  category?: string | string[]
) => {
  if (hits && !hits.length) return initialStateAvailableFilters;

  const subCategoriesArr: string[] = [];
  const programDescriptionArr: string[] = [];
  const cutArr: string[] = [];
  const filtersList: {}[] = [];
  const lowCaseCategory = category?.toString()?.toLowerCase();

  /* istanbul ignore next */
  hits.forEach((el: any) => {
    if (el.subCategories && el.subCategories.length)
      subCategoriesArr.push(...el.subCategories);
    if (el.programDescription)
      programDescriptionArr.push(el.programDescription);
    if (el.cut) cutArr.push(el.cut);
  });

  if (subCategoriesArr.length)
    filtersList.push({
      type: "subCategories",
      name: "Type",
      items: [...new Set(subCategoriesArr)]
        .map((el) => {
          const [itemCategory, subCategory] = el.split("-");
          const addWhiteSpace = (str: string) => {
            return str.replace(/([A-Z])/g, " $1").trim();
          };
          if (itemCategory.toLowerCase() === lowCaseCategory)
            return {
              name: addWhiteSpace(subCategory || el),
              value: el.toLocaleLowerCase(),
            };
        })
        .filter((elem) => elem !== undefined),
      sortOrder: getCustomerFacingFilterSortOrder("subCategories"),
    });

  if (cutArr.length)
    filtersList.push({
      type: "cut",
      name: "Butchery",
      items: [...new Set(cutArr)].map((el) => ({
        name: el,
        value: el.toLocaleLowerCase(),
      })),
      sortOrder: getCustomerFacingFilterSortOrder("cut"),
    });
  if (programDescriptionArr.length)
    filtersList.push({
      type: "programDescription",
      name: "Options",
      items: [...new Set(programDescriptionArr)].map((el) => ({
        name: el,
        value: el.toLocaleLowerCase(),
      })),
      sortOrder: getCustomerFacingFilterSortOrder("programDescription"),
    });

  return [...filtersList, ...initialStateAvailableFilters];
};
