/*  validation
        the standard form and field validators can be found in:
            FieldValidator.ts && FormValidator.ts
        the following list of validators are for everything else
*/

import { IEverythingBagel } from "@Components/forms/helpers/FormValidator";

/**
 * @memberOf Utils.Types
 * @type {function}
 * @param {*} value
 * @param {string} type
 * @return {boolean}
 */
export const isTypeOf = (value: any, type: string) => typeof value === type;

/**
 * @memberOf Utils.Types
 * @type {function}
 * @param {*} value
 * @return {boolean}
 */
export const isObject = (value: any) => {
  return value && isTypeOf(value, "object") && !Array.isArray(value);
};

/**
 * @memberOf Utils.Types
 * @type {function}
 * @param {*} value
 * @return {boolean}
 */
export const isArray = (value: any) => {
  return value && isTypeOf(value, "object") && Array.isArray(value);
};

// this function verifies if a value is a number or a string that can be converted to a valid number
// for a strict number type check, just simply use typeof value === "number"
export const isANumber = (value: string | number, radix = 10): boolean => {
  return !Number.isNaN(parseInt(value as string, radix));
};

export const isKeyPresent = (key: string, obj: IEverythingBagel) => {
  return Object.keys(obj).some((k) => {
    return k === key;
  });
};

export const areAnyKeysPresent = (keys: string[], obj: IEverythingBagel) => {
  return keys.some((key) => {
    return Object.keys(obj).some((k) => {
      return key === k;
    });
  });
};

export const areAllKeysPresent = (keys: string[], obj: IEverythingBagel) => {
  return keys.every((key) => {
    return isKeyPresent(key, obj);
  });
};

export const keyIsNotIgnored = (key: string, ignoreKeys: string[]) => {
  let keyIsIgnored;
  if (ignoreKeys?.length) {
    keyIsIgnored = ignoreKeys.some((k) => {
      return k === key;
    });
  } else {
    keyIsIgnored = false;
  }
  return !keyIsIgnored;
};
