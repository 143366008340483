import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  show: false,
};

export const slice = createSlice({
  name: "taxDelayedCard",
  initialState,
  reducers: {
    setShowTaxDelayedCard: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { setShowTaxDelayedCard } = slice.actions;

export default slice.reducer;
