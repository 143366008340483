import { v4 as uuidv4 } from "uuid";
import { nextAppEnv } from "@Utils/environment";
import { MAX_AGE } from "@wff/constants/HTTP";
import { getLocaleFromPathParam } from "@wff/store/localeSlice";

/**
 * Default Fetch Configurations
 * @field {number} timeout - Default request timeout(15sec)
 * @field {object} headers - Default header values
 * @field {boolean} async - Controls async vs sync request
 * @field {boolean} cache - Controls cache header
 * @field {number} max-age - Controls cache validity
 * @field {array} skip_headers - List of headers which needs to be excluded
 * @exports DEFAULT_FETCH_CONFIG
 */
export const DEFAULT_FETCH_CONFIG: any = {
  timeout: 15000,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    //'x-channel': 'rrs-web',
    //'x-correlation-id': null,
  },
  async: true,
  cache: "no-store",
  [MAX_AGE]: 0,
  credentials: "same-origin", // forward cookie details only if the request is made to same origin
};

interface IGetHttpRequestConfigParams {
  url: String;
  config?: any;
}

export const getHttpRequestConfig = ({
  url,
  config = { headers: {} },
}: IGetHttpRequestConfigParams) => {
  const newConfig = { ...DEFAULT_FETCH_CONFIG, ...config };
  if (config?.headers) {
    newConfig.headers = { ...newConfig.headers, ...config?.headers };
  }

  const locale = getLocaleFromPathParam();
  // only add these headers to internal calls
  if (url.includes(nextAppEnv.api.baseUri)) {
    newConfig.headers["X-WILDFORK-REQ-ID"] = uuidv4();
    newConfig.headers["Ocp-Apim-Subscription-Key"] =
      nextAppEnv.api.apiKey || "";
    newConfig.headers["X-WILDFORK-LOCALE"] = locale;
  }
  return newConfig;
};
