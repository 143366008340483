module.exports = {
  i18n: {
    // Supported Locales:
    //English for US, Spanish for Mexico, English and French for Canada
    locales: ["en-US", "es-MX", "en-CA", "fr-CA"],
    defaultLocale:
      process.env.LOCALE || process.env.NEXT_PUBLIC_LOCALE || "en-US",
    localeDetection: false,
    // domains: [
    //   {
    //     domain: 'wildforkfoods.com',
    //     defaultLocale: 'en-US',
    //   },
    //   {
    //     domain: 'meatme.mx',
    //     defaultLocale: 'es-MX',
    //   },
    //   {
    //     domain: 'wildforkfoods.ca',
    //     defaultLocale: 'en-CA',
    //   },
    // ],
  },
};
