import { getCartIdForHeader } from "@Utils/reduxCommon";

export enum HeaderKeys {
  CartId = "X-WILDFORK-CART-ID",
  StoreId = "X-WILDFORK-STORE-ID",
  AdyenApiKey = "x-API-key",
}

export type Header = {
  [key in HeaderKeys]?: string;
};
type HeaderGenerator = (value?: string) => Header;
type StoreIdHeader = (storeId?: string) => HeaderGenerator;

export const cartIdHeader = (
  cartId: string = getCartIdForHeader()
): Header => ({
  ...(cartId && { [HeaderKeys.CartId]: cartId }),
});

export const storeIdHeader: StoreIdHeader = (storeId?: string) => () => ({
  ...(storeId && { [HeaderKeys.StoreId]: storeId }),
});

export const buildHeaders = (...headers: HeaderGenerator[]) => {
  return headers.reduce((acc, header) => {
    return { ...acc, ...header() };
  }, {});
};
