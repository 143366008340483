import { merge } from "lodash";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUpdatedDiscountCodeList } from "@Components/cart/helpers/helpers";
import { IEverythingBagel } from "@Components/forms/helpers/FormValidator";
import {
  MEMBERSHIP_CONSTANTS,
  MINIMUM_THRESHOLD_VALUE_FOR_DELIVERY_OPTION_CA,
  USER_CONTACT_FORM_TYPE,
} from "@Constants/common";
import {
  GENERIC_EVENT_ERR_HEADING,
  GENERIC_EVENT_ERR_MSG,
} from "@Constants/snackbar";
import { ThunkStatus } from "@Constants/stateMgmt";
import { COOKIE_KEYS, STORAGE_KEYS, STORAGE_TYPES } from "@Constants/storage";
import AddShippingAddressService from "@Services/addShippingAddressService/AddShippingAddressService";
import GetCartItemsService from "@Services/getCartItemsService/GetCartItemsService";
import {
  IBillingAddress,
  PaymentMethods,
  IShippingAddress,
  CreditCardFormValues,
  BillingAddressOptions,
} from "@Types/checkout";
import {
  removeItem as removeItemFromBrowserStorage,
  setItem as setItemInBrowserStorage,
  getItem as getItemInBrowserStorage,
} from "@Utils/browserStorage";
import { formatZipcode } from "@Utils/checkout";
import { cookieSettingsForUS, getCookie, setCookie } from "@Utils/cookies";
import { convertToDollar } from "@Utils/currency";
import { findErrorCode } from "@Utils/errors";
import {
  axiosErrorHandler,
  getScenariosFromErrorCodeTranslation,
  IScenario,
} from "@Utils/snackbarHandlers";
import {
  validateDiscountCode,
  removeDiscountFromCartApi,
} from "@wff/api/checkoutApi";
import { ERROR_CODES, IErrorCodeTranslation } from "@wff/api/constants/error";
import { removeCustomerShippingAddress } from "@wff/api/customerApi";
import {
  ICartApiResponse,
  ICartLineItem,
  ISetUserContactHandler,
  IUserContact,
} from "@wff/interfaces";
import { ICodeMessage } from "@wff/interfaces/error";
import { AppDispatch, RootState } from ".";

export interface IDiscount {
  code: string;
  status: ApiStatus;
  error?: {
    code: string;
    message: string;
  };
}

export enum DeliveryMethods {
  Delivery = "Delivery",
  PickUp = "PickUp",
}

type ApiStatus = "IDLE" | "PENDING" | "SUCCESS" | "ERROR"; // TODO from Jared: these values are defined and used in multiple areas

export interface ICartState extends ICartApiResponse {
  cartId: string; // TODO from Jared: we already have orderNumber from ICartApiResponse, we may want to consider moving cartId usage to orderNumber
  storeId: string;
  selectedDeliveryMethod: string;
  totalAmount: number;
  orderMinimumAmount: number;
  paymentMethod: PaymentMethods;
  creditCardFormValues?: CreditCardFormValues;
  billingAddressOption: BillingAddressOptions;
  discount: IDiscount;
  multipleDiscounts: IDiscount[];
  quantity: number;
  driverTip: number;
  status: ApiStatus;
  reassociateCartAddress: boolean; // TODO from Jared: we should not need this anymore or any of the functionality it is associated with
  isDeliveryPageLoaded: boolean; // TODO from Nilesh: Added this flag to hide continue button when delivery page is not loaded
  isSaveAddressFlag?: boolean; // If user clicks 'Add New Address' button this flag will be true
}

interface IGetCartItemsProps {
  checkInventory: any;
  setUserContactHandler?: ISetUserContactHandler;
  errorCodeTranslation?: IErrorCodeTranslation;
}

export interface DiscountResponse {
  discountCode: string;
}

interface IAddShippingAddress {
  data: IBillingAddress;
  validationTranslation: IEverythingBagel;
  errorCodeTranslation?: IErrorCodeTranslation;
}

export interface IApplyDiscountCode {
  discountCode: string;
  errorCodeTranslation: IErrorCodeTranslation;
}

export const initialDiscount: IDiscount = {
  code: "",
  status: ThunkStatus.Idle,
};

export const calculateQuantity: any = (item: any) => {
  const initialQuantity = 0;
  return item?.reduce(
    (previousQuantity: number, currentValue: any) =>
      previousQuantity + currentValue.quantity,
    initialQuantity
  );
};

export const initialAddress = {
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  postalCode: "",
  phone: "",
  state: "",
  country: "",
  dialCode: "",
};

const getSelectedDeliveryMethod = () => {
  const deliveryMethod = getItemInBrowserStorage(
    STORAGE_KEYS.ACTIVE_DELIVERY_TYPE_CA,
    STORAGE_TYPES.SESSION
  );
  if (deliveryMethod === DeliveryMethods.Delivery) {
    return DeliveryMethods.Delivery;
  } else if (deliveryMethod === DeliveryMethods.PickUp) {
    return DeliveryMethods.PickUp;
  } else {
    return DeliveryMethods.Delivery;
  }
};

export const initialState: ICartState = {
  cartId: "",
  storeId: "",
  orderNumber: "",
  selectedDeliveryMethod: getSelectedDeliveryMethod(),
  lineItems: [],
  subTotal: 0,
  rawSubTotal: 0,
  currencyType: "",
  totalPrice: 0,
  shippingTotal: 0,
  rawShippingTotal: 0,
  taxTotal: 0,
  discountAmount: 0,
  totalAmount: 0,
  orderMinimumAmount: 35, // TODO from Jared: make an OrderMinimum constant for this, maybe we should also differentiate by Locale via the locale env variable value
  paymentMethod: PaymentMethods.CreditCard,
  discount: {
    code: "",
    status: ThunkStatus.Pending,
    error: {
      code: "",
      message: "",
    },
  },
  multipleDiscounts: [],
  discountCodeList: [],
  discountCode: "",
  tipReceived: 0,
  driverTip: -1,
  shippingAddress: {
    ...initialAddress,
    email: "",
    saveAddress: false,
    dialCode: "",
  },
  shippingEligibility: {
    eligibilityAmountRemaining: 5000, // TODO from Jared: make a ShippingEligibility constant for these, maybe we should also differentiate by Locale via the locale env variable value
    eligibilityThreshold: 5000,
  },
  billingAddressOption: BillingAddressOptions.sameAsShippingAddress,
  billingAddress: { ...initialAddress },
  status: ThunkStatus.Idle,
  addresses: [],
  quantity: 0,
  removedLineItems: [],
  reassociateCartAddress: true,
  isDeliveryPageLoaded: false,
  isSaveAddressFlag: false,
};

export const formStructFromDiscountSuccess = (
  discountCode: string
): IDiscount => {
  return {
    code: discountCode ?? initialState.discount.code, // TODO from Jared: we may be able to change discount branch of ICartState to something like discountStatus (and rename its type to IDiscountStatus) and perform a minor refactor that would allow us to remove the code field here since we already have discountCode on the state object
    status: ThunkStatus.Success,
    error: {
      code: initialState.discount.error!.code,
      message: initialState.discount.error!.message,
    },
  };
};

export const formStructFromMultipleDiscountsSuccess = (
  discountCodeList: string[]
): IDiscount[] => {
  return discountCodeList?.map((discountCode) => ({
    code: discountCode ?? initialState.discount.code,
    status: ThunkStatus.Success,
    error: {
      code: initialState.discount.error!.code,
      message: initialState.discount.error!.message,
    },
  }));
};

export const formStructFromDiscountError = (
  discountCode: string,
  errorCode: string,
  errorMessage: string
): IDiscount => {
  return {
    code: discountCode ?? initialState.discount.code,
    status: ThunkStatus.Error,
    error: {
      code: errorCode ?? initialState.discount.error!.code,
      message: errorMessage ?? initialState.discount.error!.message,
    },
  };
};

export const formStructFromCartResponse = (
  state: ICartState,
  payload: ICartApiResponse
): ICartState => {
  // creating new state so we can first spread the payload dynamically
  const newState =
    payload && Object.keys(payload).length > 0
      ? merge(state, payload)
      : { ...state };
  // then we assign object/array values directly and also consider transformations to match differences in state structure
  newState.lineItems = payload?.lineItems
    ? payload.lineItems
    : initialState.lineItems;
  newState.removedLineItems = payload?.removedLineItems
    ? payload.removedLineItems
    : initialState.removedLineItems;
  newState.addresses = payload?.addresses
    ? payload.addresses
    : initialState.addresses;
  newState.shippingAddress = payload?.shippingAddress
    ? payload.shippingAddress
    : initialState.shippingAddress;
  newState.billingAddress = payload?.billingAddress
    ? payload.billingAddress
    : initialState.billingAddress;
  newState.shippingEligibility = payload?.shippingEligibility
    ? payload.shippingEligibility
    : initialState.shippingEligibility;
  newState.quantity = payload?.lineItems
    ? calculateQuantity(payload?.lineItems)
    : initialState.quantity;
  newState.discountAmount =
    payload?.discountAmount || initialState.discountAmount;
  newState.discount = payload?.discountAmount
    ? formStructFromDiscountSuccess(payload?.discountCode)
    : initialState.discount;
  newState.multipleDiscounts = payload?.discountAmount
    ? formStructFromMultipleDiscountsSuccess(payload?.discountCodeList)
    : initialState.multipleDiscounts;
  newState.totalAmount = payload?.totalPrice; // TODO from Jared: do we really need an extra field for this rather than just using totalPrice globally?
  newState.status = ThunkStatus.Success;
  return newState;
};

const handleDiscountError = (
  error: any,
  errorCodeTranslation: IErrorCodeTranslation,
  dispatch: any
) => {
  let scenarios: IScenario[] = [];
  if (errorCodeTranslation) {
    scenarios = getScenariosFromErrorCodeTranslation({
      errorCodeTranslation,
    });
  }
  axiosErrorHandler({
    error,
    dispatch,
    scenarios,
    errorCodeIgnoreList: [
      ERROR_CODES.WF_CART_008,
      ERROR_CODES.WF_CART_009,
      ERROR_CODES.WF_CART_010,
      ERROR_CODES.WF_CART_029,
      ERROR_CODES.WF_CART_030,
      ERROR_CODES.WF_CART_031,
    ],
  });
  const errorCodesFound: ICodeMessage[] = [];
  errorCodeTranslation &&
    Object.keys(errorCodeTranslation).forEach((errCode) => {
      const errorCodeFound: ICodeMessage | undefined = findErrorCode({
        code: errCode,
        error,
      });
      if (errorCodeFound) {
        errorCodesFound.push(errorCodeFound);
      }
    });

  if (
    errorCodesFound.length &&
    errorCodeTranslation &&
    Object.keys(errorCodeTranslation)?.length
  ) {
    const errCode = errorCodesFound[0].code;
    dispatch(
      setErrorDiscountCode({
        errorCode: errCode,
        message: errorCodeTranslation[errCode],
      })
    );
  }
};

// Note: this is a temporary function for US Cart Transition Period - Canada is already setting the cookie from the server, in the future US progbably will too
const temporarySetCookieFromCartIdForUS = (id: string) => {
  const cookieCartId = getCookie(COOKIE_KEYS.CART_ID);
  if (
    typeof document !== "undefined" &&
    document?.location?.href?.toLowerCase()?.includes("en-us") &&
    document?.location?.href?.includes("https") &&
    ((!cookieCartId && id) || (cookieCartId && id && cookieCartId !== id))
  ) {
    setCookie(COOKIE_KEYS.CART_ID, id, {
      path: cookieSettingsForUS.path,
      secure: true,
      sameSite: "None",
      expires: 1 / 24, // Note: specified in "days" for js-cookie - i'm temporarily using an hour during the cart transition [being cautious in the event we want to easily remove the simulated cookie] rather than 30 days [also we can't specify with maxAge with js-cookie] - The Canada server is using the following calculation in seconds: 30 * 24 * 60 * 60
    });
  }
};

export const getCartItems = createAsyncThunk(
  "cart/checkout",
  async (data: IGetCartItemsProps, { dispatch, getState }) => {
    let address: IShippingAddress;
    const { locale } = getState() as RootState;
    try {
      const response: any = await GetCartItemsService.invoke(
        data?.checkInventory
      );
      if (
        response?.payload &&
        response?.payload?.removedLineItems?.length === 0 &&
        response?.payload?.shippingAddress &&
        Object.keys(response?.payload?.shippingAddress).length
      ) {
        address = response.payload.shippingAddress;

        //Fix: CE-360: Update the membership quantity to 1 if quantity > 1
        const membershipIndex = response?.payload?.lineItems?.findIndex(
          (item: ICartLineItem) => item?.sku === MEMBERSHIP_CONSTANTS.MEMBERSHIP
        );
        if (
          membershipIndex !== -1 &&
          response?.payload?.lineItems[membershipIndex]?.quantity > 1
        ) {
          response.payload.lineItems[membershipIndex].quantity = 1;
        }

        if (
          locale?.countryShort?.toLowerCase() ===
          address?.country?.toLowerCase()
        ) {
          data?.setUserContactHandler?.(
            address as IUserContact,
            USER_CONTACT_FORM_TYPE.SHIPPING
          );
        } else {
          alert("shippingAddress does not match the locale");
        }
      }
      return response?.payload;
    } catch (error: any) {
      let scenarios: IScenario[] = [];
      if (data?.errorCodeTranslation) {
        scenarios = getScenariosFromErrorCodeTranslation({
          errorCodeTranslation: data?.errorCodeTranslation,
        });
      }
      if (error.code !== "API_FAIL" || scenarios.length > 0) {
        axiosErrorHandler({
          error,
          dispatch,
          scenarios,
          fallbackSnackbar: {
            heading: GENERIC_EVENT_ERR_HEADING.REQUEST_FAILED(
              locale?.languageCode
            ),
            message: GENERIC_EVENT_ERR_MSG.GET_CART_ITEMS(locale?.languageCode),
          },
        });
      }
      throw error;
    }
  }
);

export const addShippingAddress = createAsyncThunk(
  "cart/addShippingAddress",
  async (
    { data, validationTranslation, errorCodeTranslation }: IAddShippingAddress,
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const locale = state.locale;
    const loggedInUserEmail = state.customerInfo?.email;
    const savedAddressesStatus = state?.cart?.isSaveAddressFlag;
    const isNewUser = state?.orderHistory?.data?.length ? false : true;
    const userWithOrderHistoryAndNoSavedAddresses = state?.customerInfo?.address
      .length
      ? false
      : true;
    const selectedDeliveryMethod = state?.cart?.selectedDeliveryMethod;

    data.postalCode = formatZipcode(data.postalCode, data.country);
    data.loggedInUserEmail = loggedInUserEmail;
    /**
     * For /shipping API call,
     * saveAddress:true will be send for LoggedIn and for `New` Addresses and for New Users
     * saveAddress:false will be send for LoggedIn and for `Existing` Addresses
     * saveAddress:true will be send if a User Have a Order History and Doesn't have SavedAddresses
     */
    data.saveAddress =
      loggedInUserEmail &&
      selectedDeliveryMethod !== DeliveryMethods.PickUp &&
      (savedAddressesStatus ||
        isNewUser ||
        userWithOrderHistoryAndNoSavedAddresses)
        ? true
        : false;
    try {
      const response = await AddShippingAddressService.invoke(data);
      return response?.payload;
    } catch (error: any) {
      if (
        error?.code === 500 &&
        error?.serviceResponse?.payload?.errors[0].code ===
          ERROR_CODES.WF_CART_006
      ) {
        alert(validationTranslation?.addressNotServed);

        axiosErrorHandler({
          error,
          dispatch,
          leadingSnackbar: {
            heading: GENERIC_EVENT_ERR_HEADING.REQUEST_FAILED(),
            message: GENERIC_EVENT_ERR_MSG.GET_WINDOWS(),
          },
          errorCodeIgnoreList: [ERROR_CODES.WF_CART_006],
        });
      } else {
        let scenarios: IScenario[] = [];
        if (errorCodeTranslation) {
          scenarios = getScenariosFromErrorCodeTranslation({
            errorCodeTranslation,
          });
        }
        axiosErrorHandler({
          error,
          dispatch,
          scenarios,
          fallbackSnackbar: {
            heading: GENERIC_EVENT_ERR_HEADING.REQUEST_FAILED(
              locale?.languageCode
            ),
            message: GENERIC_EVENT_ERR_MSG.ADD_SHIPPING_ADDRESS(
              locale?.languageCode
            ),
          },
        });
      }
      throw error;
    }
  }
);

export const removeShippingAddress = createAsyncThunk(
  "cart/removeShippingAddress",
  async (addressId: string, { dispatch }) => {
    try {
      const response = await removeCustomerShippingAddress(addressId);
      return response?.data;
    } catch (error: any) {
      axiosErrorHandler({
        error,
        dispatch,
        fallbackSnackbar: {
          heading: GENERIC_EVENT_ERR_HEADING.REQUEST_FAILED(),
          message: error?.response?.data?.errors?.[0]?.message,
        },
      });
      throw error;
    }
  }
);

export const applyDiscountCode = createAsyncThunk(
  "cart/discount",
  async (
    { discountCode, errorCodeTranslation }: IApplyDiscountCode,
    { dispatch }
  ) => {
    try {
      const response = await validateDiscountCode({ discountCode });
      return response?.data;
    } catch (error: any) {
      handleDiscountError(error, errorCodeTranslation, dispatch);
      throw error;
    }
  }
);

export const removeDiscountCode = createAsyncThunk(
  "cart/removeDiscount",
  async (
    {
      discountCode,
      errorCodeTranslation,
      appliedDiscounts,
    }: {
      discountCode: string;
      errorCodeTranslation: IErrorCodeTranslation;
      appliedDiscounts: IDiscount[];
    },
    { dispatch }
  ) => {
    try {
      const response = await removeDiscountFromCartApi(discountCode);
      if (response) {
        response.data.discountCodeList = getUpdatedDiscountCodeList(
          appliedDiscounts,
          discountCode
        );
      }
      return response?.data;
    } catch (error: any) {
      handleDiscountError(error, errorCodeTranslation, dispatch);
      throw error;
    }
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartId(state, action: PayloadAction<string>) {
      state.cartId = action.payload;
    },
    setStoreId(state, action: PayloadAction<string>) {
      state.storeId = action.payload;
    },
    resetCartId(state) {
      state.cartId = initialState.cartId;
    },
    setSelectedDeliveryMethod(state, action: PayloadAction<string>) {
      state.selectedDeliveryMethod = action.payload;
    },
    setCartFromApiResponse(state, action: PayloadAction<ICartApiResponse>) {
      return formStructFromCartResponse(state, action?.payload);
    },
    resetCart() {
      return { ...initialState };
    },
    addToCart(state, action: PayloadAction<any>) {
      state.lineItems = { ...action?.payload?.data };
      state.quantity = calculateQuantity(action?.payload?.data?.lineItems);
    },
    removeFromCart(state, action: PayloadAction<any>) {
      state.lineItems = { ...action?.payload?.data };
      state.quantity = calculateQuantity(action?.payload?.data?.lineItems);
    },
    setDiscountCode(state, action: PayloadAction<any>) {
      state.discount = formStructFromDiscountSuccess(
        action?.payload?.data?.discountCode
      );
    },
    setErrorDiscountCode(state, action: PayloadAction<any>) {
      const { discountCode, errorCode, message } = action?.payload;
      state.discount = formStructFromDiscountError(
        discountCode,
        errorCode,
        message
      );
    },
    setShippingAddress(state, action: PayloadAction<IShippingAddress>) {
      state.shippingAddress = action?.payload;
    },
    saveAddressFlag(state, { payload }) {
      return { ...state, isSaveAddressFlag: payload };
    },
    setBillingAddress(
      state,
      action: PayloadAction<IBillingAddress | undefined>
    ) {
      state.billingAddress = action.payload
        ? { ...action.payload }
        : state.billingAddress;
    },
    setBillingAddressOption(
      state,
      action: PayloadAction<BillingAddressOptions>
    ) {
      state.billingAddressOption = action.payload;
    },
    setCreditCardFormValues(
      state,
      action: PayloadAction<CreditCardFormValues>
    ) {
      state.creditCardFormValues = action.payload;
    },
    setDriverTip(state, action: PayloadAction<number>) {
      state.driverTip = action.payload;
    },
    setPaymentMethod(state, action: PayloadAction<PaymentMethods>) {
      state.paymentMethod = action.payload;
    },
    setRemovedLineItems(state, action: PayloadAction<Array<any>>) {
      state.removedLineItems = action.payload;
    },
    setCartItems(state, action: PayloadAction<any>) {
      state.lineItems = { ...action?.payload?.data };
      state.quantity = calculateQuantity(action?.payload?.data?.lineItems);
      state.totalAmount = action?.payload?.data?.totalPrice;
    },
    setDeliverypageLoaded(state, { payload }) {
      return { ...state, isDeliveryPageLoaded: payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCartItems.fulfilled, (state, action) => {
      return formStructFromCartResponse(state, action?.payload);
    });
    builder.addCase(addShippingAddress.fulfilled, (state, action) => {
      return {
        ...state,
        shippingAddress: {
          ...action?.payload?.address,
          country: action?.payload?.address?.country,
        },
        status: ThunkStatus.Success,
        reassociateCartAddress: false,
      };
    });
    builder.addCase(removeShippingAddress.fulfilled, (state) => {
      return {
        ...state,
        shippingAddress: {
          ...initialAddress,
          email: "",
          saveAddress: false,
          dialCode: "",
        },
        status: ThunkStatus.Idle,
        reassociateCartAddress: true,
      };
    });
    builder.addCase(applyDiscountCode.fulfilled, (state, action) => {
      return formStructFromCartResponse(state, action?.payload);
    });
    builder.addCase(removeDiscountCode.fulfilled, (state, action) => {
      return formStructFromCartResponse(state, action?.payload);
    });
  },
});

export const {
  addToCart,
  removeFromCart,
  resetCart,
  resetCartId,
  setBillingAddress,
  setBillingAddressOption,
  setCartId,
  setStoreId,
  setSelectedDeliveryMethod,
  setCartFromApiResponse,
  setCartItems,
  setCreditCardFormValues,
  setDiscountCode,
  setDriverTip,
  setErrorDiscountCode,
  setPaymentMethod,
  setRemovedLineItems,
  setShippingAddress,
  saveAddressFlag,
  setDeliverypageLoaded,
} = cartSlice.actions;

export const cartSliceSelector = (state: RootState) => state.cart;
export const totalAmountSelector = (state: RootState) =>
  state.cart?.totalAmount;
export const cartQuantitySelector = (state: RootState) => state.cart?.quantity;

export const discountCodeSelector = (state: RootState) => state.cart?.discount;

export const amountToReachOrderMinimumSelector = (state: RootState) => {
  const { totalAmount, orderMinimumAmount } = state.cart;

  const minimumAmount =
    state.locale.languageCode === "en-US"
      ? orderMinimumAmount
      : MINIMUM_THRESHOLD_VALUE_FOR_DELIVERY_OPTION_CA;

  return Number(minimumAmount) - convertToDollar(Number(totalAmount));
};

export const setCartIdHandler =
  (id: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const cart = getState().cart;
    const cartIdInRedux = cart?.cartId;
    if (id !== cartIdInRedux) {
      dispatch(setCartId(id));
    }
    temporarySetCookieFromCartIdForUS(id); // TODO from Jared: after we remove this temp cookie functionality, we may be able to remove the handler entirely
  };

export const setStoreIdHandler =
  (
    storeId: string,
    setItem = setItemInBrowserStorage,
    removeItem = removeItemFromBrowserStorage
  ) =>
  (dispatch: AppDispatch) => {
    if (storeId) {
      setItem(STORAGE_KEYS.STORE_ID, storeId, STORAGE_TYPES.LOCAL);
    } else {
      removeItem(STORAGE_KEYS.STORE_ID, STORAGE_TYPES.LOCAL);
    }
    dispatch(setStoreId(storeId));
  };

export const orderMinimumAmountSelector = (state: RootState) =>
  state.locale.languageCode === "en-US"
    ? state.cart.orderMinimumAmount
    : MINIMUM_THRESHOLD_VALUE_FOR_DELIVERY_OPTION_CA;

export const getAddresses = (state: RootState) => state?.cart?.addresses;

export const getRemovedLineItems = (state: RootState) =>
  state?.cart?.removedLineItems;

export const getShippingAddress = (state: RootState) =>
  state?.cart?.shippingAddress;

export { initialState as initialCartState };

export default cartSlice.reducer;
