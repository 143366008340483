export interface IBrowserStorage {
  getItem: (key: string, type?: StorageType) => string | null;
  setItem: (key: string, value: string, type?: StorageType) => void;
  removeItem: (key: string, type?: StorageType) => void;
}

export type StorageType = "session" | "local";

const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();

const getStorageType = (
  type?: StorageType
): "localStorage" | "sessionStorage" => `${type ?? "session"}Storage`;

export const getItem = (key: string, type?: StorageType) => {
  if (isBrowser) {
    return window[getStorageType(type)].getItem(key) || "";
  }

  return "";
};

export const setItem = (key: string, value: string, type?: StorageType) => {
  if (isBrowser) {
    return window[getStorageType(type)].setItem(key, value);
  }
};

export const removeItem = (key: string, type?: StorageType) => {
  if (isBrowser) {
    return window[getStorageType(type)].removeItem(key);
  }
};
