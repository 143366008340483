import { IStoreFulfillmentResponse } from "@wff/interfaces";
import api from "./api";
import RestEndpoints from "./constants/RestEndpoints";

export const getStores = async (
  latitude = 33.0787106,
  longitude = -96.91018059999999,
  distance = 100
) => {
  const queryParams = new URLSearchParams({
    latitude: latitude.toString(),
    longitude: longitude.toString(),
    distance: distance.toString(),
  }).toString();

  return api.get<IStoreFulfillmentResponse>(
    `${RestEndpoints.PICKUP_STORES}/?${queryParams}`
  );
};
