import { useCallback, useMemo } from "react";
import { axiosErrorHandler } from "@Utils/snackbarHandlers";
import { getOrderHistory } from "@wff/api/order/orderHistoryApi";
import { useCustomer } from "@wff/hooks/useCustomer";
import { useAppDispatch, useAppSelector } from "@wff/store/hooks";
import { setOrderHistoryResponse } from "@wff/store/orderHistorySlice";

export const useCustomerOrders = () => {
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useCustomer();

  const orders = useAppSelector((state) => state.orderHistory?.data || []);

  const getMyOrders = useCallback(() => {
    if (!isLoggedIn) return;

    getOrderHistory()
      .then((res) => {
        dispatch(setOrderHistoryResponse(res.data));
      })
      .catch((err) => {
        axiosErrorHandler({ error: err });
      });
  }, [dispatch, isLoggedIn]);

  const hasOrdersInPastTwoYears = useMemo(() => {
    const today = new Date().getTime();
    const lastestOrderDate =
      orders.length && new Date(orders[0].createdAt).getTime(); // first item in orders is latest order placed
    const timeFromLatestPurchase = today - lastestOrderDate; // difference in miliseconds
    const monthsFromLatestPurchase = Math.floor(
      timeFromLatestPurchase / (1000 * 60 * 60 * 24 * 30)
    ); // difference converted from miliseconds to months
    return monthsFromLatestPurchase < 24 ? true : false;
  }, [orders]);

  return {
    getMyOrders,
    hasOrdersInPastTwoYears,
    orders: [...orders],
  };
};
