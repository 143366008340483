import { createSlice } from "@reduxjs/toolkit";
import localeDetails from "@Constants/localeDetails.json";
import { nextAppEnv } from "@Utils/environment";
import { ILocaleDetail } from "@wff/interfaces";
import nextI18nextConfig from "@wff/next-i18next.config";
import { RootState } from ".";

export const getLocaleDetail = (languageCode: string | undefined) => {
  const langCode = languageCode ? languageCode : localeDetails[0].languageCode; // should default to 'en-US'
  const localeDetail = localeDetails.find((el: ILocaleDetail) => {
    return el.languageCode === langCode;
  });
  return localeDetail || localeDetails[0];
};

export const getLocaleFromPathParam = () => {
  // first we try to get the locale from the path param, then fallback triple redundandtly to the env variable
  const href =
    global.window?.location?.href ??
    `http://andre:3000/${nextAppEnv.application.locale}`; // fallback dummy parameter to match string manipulation
  const hrefSplit = href.split("/"); // split on the slash / and the locale path parameter should be the 4th element after http://domain.com/
  const localePathParam =
    hrefSplit?.length > 3
      ? hrefSplit[3]
      : nextAppEnv.application.locale ?? "en-US";
  return nextI18nextConfig.i18n.locales.includes(localePathParam)
    ? localePathParam
    : nextAppEnv.application.locale ?? "en-US";
};

const initialState: ILocaleDetail = {
  ...getLocaleDetail(getLocaleFromPathParam()),
};

export const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    resetLocale(_state, action) {
      // this should only be called one time in the app, if you need to change the locale do it by pushing to the locale path such as /fr-CA/
      const routeOverride = action?.payload;
      const localeDetail = routeOverride
        ? getLocaleDetail(routeOverride)
        : getLocaleDetail(getLocaleFromPathParam());
      return { ...localeDetail };
    },
  },
});

export const localeActions = localeSlice.actions;

export const localeSelector = (state: RootState) => state.locale;

export default localeSlice.reducer;
