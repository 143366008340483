import { TWithAsyncFn, TApiCatchBlock } from "@Types/error";

export async function withAsync<TData = unknown, TError = unknown>(
  fn: TWithAsyncFn<TData>
): Promise<TApiCatchBlock<TData, TError>> {
  try {
    if (typeof fn !== "function")
      throw new Error("The first argument must be a function");
    const response = await fn();
    return {
      response,
      error: null,
    };
  } catch (error) {
    return {
      error,
      response: null,
    };
  }
}
