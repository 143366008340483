import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBreadcrumbsState, ISlug } from "@Types/Breadcrumbs";
import { RootState } from ".";

export const initialState: IBreadcrumbsState = {
  homeSlug: { title: "Home", link: "/" },
  collectionSlug: { title: "", link: "" },
  currentSlug: { title: "", link: "" },
  slugs: [{ title: "Home", link: "/" }],
};

const addHomeSlug = (state: IBreadcrumbsState, homeSlug: ISlug) => {
  const collectionSlug = state.collectionSlug;
  const currentSlug = state.currentSlug;
  return [homeSlug, collectionSlug, currentSlug];
};

const addCollectionSlug = (state: IBreadcrumbsState, collectionSlug: ISlug) => {
  const homeSlug = state.homeSlug;
  const currentSlug = state.currentSlug;
  return [homeSlug, collectionSlug, currentSlug];
};

const addCurrentSlug = (state: IBreadcrumbsState, currentSlug: ISlug) => {
  const homeSlug = state.homeSlug;
  const collectionSlug = state.collectionSlug;
  return [homeSlug, collectionSlug, currentSlug];
};

export const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setHomeSlug(state, action: PayloadAction<ISlug>) {
      state.homeSlug = action.payload;
      state.slugs = addHomeSlug(state, action.payload);
    },
    setCollectionSlug(state, action: PayloadAction<ISlug>) {
      state.collectionSlug = action.payload;
      state.slugs = addCollectionSlug(state, action.payload);
    },
    setCurrentSlug(state, action: PayloadAction<ISlug>) {
      state.currentSlug = action.payload;
      state.slugs = addCurrentSlug(state, action.payload);
    },
  },
});
export const { setHomeSlug, setCollectionSlug, setCurrentSlug } =
  breadcrumbsSlice.actions;

export const breadcrumbsSelector = (state: RootState) => state.breadcrumbs;

export default breadcrumbsSlice.reducer;
