import { IFilter, IFilterOption } from "@Components/ui/filter";
import {
  IRecipe,
  IRecipesListingPageContent,
} from "@Types/recipes-listing-page";
import { contextFactory } from "@wff/context/helpers/contextFactory";

interface RecipeListingProps {
  count: number;
  recipes: IRecipe[];
  filters: IFilter[];
  searchQuery: string;
  showLoadMore: boolean;
  loadMoreHandler: () => void;
  clearAllFilterHandler: () => void;
  filterChangeHandler: (option: IFilterOption, filter: IFilter) => () => void;
  contenfulContent?: Partial<IRecipesListingPageContent>;
  searchByQueryHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const [useRecipe, RecipesContext] = contextFactory<RecipeListingProps>();

export { useRecipe };

interface RecipesContextProviderProps extends RecipeListingProps {
  children?: React.ReactNode;
}

export const RecipesContextProvider = (props: RecipesContextProviderProps) => (
  <RecipesContext.Provider value={props}>
    {props.children}
  </RecipesContext.Provider>
);
