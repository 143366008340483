import { formatZipcode } from "@Utils/checkout";
import {
  cartIdHeader,
  buildHeaders,
  storeIdHeader,
} from "@wff/configs/HeadersConfig";
import {
  IDeliveryWindowPayload,
  IAvailableWindowFromDb,
  IOrderData,
  IOrderSubmitPayload,
  IGetPaymentMethodsPayload,
  IGetPaymentMethodsResponse,
  applyDiscountPayload,
  IStoreFulfillmentResponse,
  ICartApiResponse,
  IPostRemovePaymentMethodsPayload,
  IUpdateCartFulfillmentType,
} from "@wff/interfaces";
import { getLocaleFromPathParam } from "@wff/store/localeSlice";
import api from "./api";
import RestEndpoints from "./constants/RestEndpoints";

interface IGetAvailableWindowsPayload {
  storeId?: string;
}

export const getAvailableWindows = ({
  storeId,
}: IGetAvailableWindowsPayload = {}) => {
  const headers = buildHeaders(cartIdHeader, storeIdHeader(storeId));

  return api.get<IAvailableWindowFromDb[]>(
    RestEndpoints.CHECKOUT.DELIVERY_WINDOWS,
    {
      headers,
    }
  );
};

export const reserveAvailabilityWindow = (payload: IDeliveryWindowPayload) => {
  const headers = buildHeaders(cartIdHeader);

  return api.put<IAvailableWindowFromDb[]>(
    RestEndpoints.CHECKOUT.FULFILLMENT_WINDOW,
    payload,
    {
      headers,
    }
  );
};

export const validateDiscountCode = (payload: applyDiscountPayload) => {
  const headers = buildHeaders(cartIdHeader);

  return api.post<ICartApiResponse>(
    RestEndpoints.CART.APPLY_DISCOUNT,
    payload,
    {
      headers,
    }
  );
};

export const removeDiscountFromCartApi = (discountCode: string) => {
  const headers = buildHeaders(cartIdHeader);

  return api.delete<ICartApiResponse>(
    `${RestEndpoints.CART.REMOVE_DISCOUNT}/${discountCode}`,
    { headers }
  );
};

export const getPaymentMethodsAPI = (payload: IGetPaymentMethodsPayload) => {
  const headers = buildHeaders(cartIdHeader);

  return api.post<IGetPaymentMethodsResponse>(
    RestEndpoints.PAYMENT.GET_ADYEN_PAYMENT_METHODS,
    payload,
    { headers }
  );
};

export const handlePaypalSubmission = (
  state: any,
  dropin: any,
  service: string
) => {
  const headers = buildHeaders(cartIdHeader);

  return api.post<IGetPaymentMethodsResponse>(
    service,
    {
      state,
      dropin,
    },
    { headers }
  );
};

export const submitOrder = (payload: IOrderSubmitPayload) => {
  const headers = buildHeaders(cartIdHeader);

  return api.post<IOrderData>(RestEndpoints.CHECKOUT.ORDER_SUBMIT, payload, {
    headers,
  });
};

export const submitAdditionalDetails = (payload: IOrderSubmitPayload) => {
  const headers = buildHeaders(cartIdHeader);

  return api.post<IOrderData>(
    RestEndpoints.CHECKOUT.ORDER_SUBMIT_ADD_DETAILS,
    payload,
    { headers }
  );
};

export const submitZipcode = (payload: any) => {
  const country = getLocaleFromPathParam().slice(3);
  const postalCode =
    country?.toUpperCase() === "CA"
      ? formatZipcode(payload, country).slice(0, 3)
      : payload;

  return api.get<IStoreFulfillmentResponse>(RestEndpoints.ZIPCODE, {
    params: {
      postalCode,
      distance: 100, // As required, as a constant since there's no development to get the distance yet.
    },
  });
};

//CA-ONLY
export const setCartFulfillment = (payload: IUpdateCartFulfillmentType) => {
  const headers = buildHeaders(cartIdHeader, storeIdHeader(payload.storeId));
  return api.put<void>(RestEndpoints.CART.FULFILLMENT, payload, { headers });
};

//CA-ONLY
export const setCartReservation = ({
  storeId,
}: IGetAvailableWindowsPayload = {}) => {
  const headers = buildHeaders(cartIdHeader, storeIdHeader(storeId));

  return api.put<IAvailableWindowFromDb[]>(
    RestEndpoints.CART.RESERVATION,
    null,
    {
      headers,
    }
  );
};

export const postRemovePaymentMethodsAPI = (
  payload: IPostRemovePaymentMethodsPayload
) => {
  const headers = buildHeaders(cartIdHeader);

  return api.post(RestEndpoints.PAYMENT.POST_ADYEN_METHODS_DISABLE, payload, {
    headers,
  });
};
