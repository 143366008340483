import { createListenerMiddleware, isAsyncThunkAction } from "@reduxjs/toolkit";
import logger from "@Utils/Logger";

// Create the middleware instance and methods
export const thunkTimingMiddleware = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
thunkTimingMiddleware.startListening({
  matcher: isAsyncThunkAction,
  effect: async (action) => {
    // Run whatever additional side-effect-y logic you want here
    logger.info("thunk middleware: ", {
      type: action.type,
      requestId: action.meta.requestId,
      requestStatus: action.meta.requestStatus,
    });
  },
});
