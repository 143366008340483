import ContentfulService from "@Services/contentfulService/ContentfulService";
import { JsonObject } from "@Types/basic";
import logger from "@Utils/Logger";

export const addProductDataToPageContent = (
  pageContent: any = {},
  productData: any = {},
  productRecipes: any[] = []
) => {
  if (!pageContent) {
    return pageContent;
  }
  const newProductData = { ...productData, recipes: productRecipes };
  return { ...pageContent, productData: newProductData };
};

export const getCmsPageBySlug = async (query: string, payload: JsonObject) => {
  let pageModel;

  try {
    const res = await ContentfulService.invoke({
      query: query,
      variables: payload,
    });

    logger.info(
      "Contentful.helper.ts getCmsPageBySlug()",
      `contentful query cost - ${res?.headers?.get(
        "x-contentful-graphql-query-cost"
      )}`
    );
    const pageContent = res?.payload?.data?.page?.items?.[0] ?? {};
    const resourcesSet = res?.payload?.data?.resourcesSet?.items ?? [];
    const pageContentWithResourceSet = mergePageContentAndResourceSet(
      pageContent,
      resourcesSet
    );
    const productData = res?.payload?.data?.productData?.items[0] ?? {};
    const productRecipes = res?.payload?.data?.productRecipes?.items ?? [];
    const extendedPageContent = addProductDataToPageContent(
      pageContentWithResourceSet,
      productData,
      productRecipes
    );

    pageModel = {
      pageContent: extendedPageContent,
      siteGlobal: res?.payload?.data?.siteGlobal?.items?.[0] ?? {},
    };
  } catch (e) {
    logger.error(
      "Contentful.helper.ts [failed to get content from contentful]",
      e
    );
  }

  return pageModel ?? {};
};

export const doQuery = async (query: string, payload: JsonObject) => {
  try {
    const res = await ContentfulService.invoke({
      query: query,
      variables: payload,
    });

    logger.info(
      "Contentful.helper.ts doQuery()",
      `contentful query cost - ${res?.headers?.get(
        "x-contentful-graphql-query-cost"
      )}`
    );

    return res?.payload?.data ?? {};
  } catch (e) {
    logger.error(
      "Contentful.helper.ts [failed to get content from contentful]",
      e
    );
  }
};

type StaticResources = Record<string, string>;

export const mergePageContentAndResourceSet = (
  pageContent: any,
  staticResources: any[] = []
) => {
  if (!pageContent || !staticResources?.length) {
    return pageContent;
  }

  const joinStaticResources: {}[] = [];
  staticResources.map((res) => {
    if (res?.resources?.items.length)
      joinStaticResources.push(...res?.resources?.items);
  });
  const resources = joinStaticResources.reduce(
    (acc: StaticResources, resource: StaticResources) => {
      const key = resource?.key || "";
      const value = resource?.value || "";

      if (key && value) {
        acc[key] = value;
      }

      return acc;
    },
    {}
  );

  return { ...pageContent, ...resources };
};
