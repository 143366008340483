// Set different base URL based on the environment
import { nextAppEnv } from "@Utils/environment";

const baseURL = nextAppEnv.api.baseUri;
const staticURL = nextAppEnv.api.staticUri;

const RestEndpoints = {
  CUSTOMER: {
    ACCOUNT_RESET: `${baseURL}customer/password/reset`,
    CONTACT: `${baseURL}customer/contact`,
    FORGOT_PASSWORD: `${baseURL}customer/password/forgot`,
    GET_DETAILS: `${baseURL}customer`,
    LOGIN: `${baseURL}customer/login`,
    LOGOUT: `${baseURL}customer/logout`,
    SIGNUP: `${baseURL}customer/`,
    UPDATE_DETAILS: `${baseURL}customer`,
    SUBSCRIBE_TO_KLAVIYO_LIST: `${baseURL}customer/klaviyo/list`,
    SHIPPING_ADDRESS: `${baseURL}customer/address`,
  },
  CART: {
    TRANSITION: `${baseURL}cart/transition`,
    APPLY_DISCOUNT: `${baseURL}cart/discount`,
    REMOVE_DISCOUNT: `${baseURL}cart/discount`,
    ADD_ITEM: `${baseURL}cart/item`,
    UPDATE_ITEM: `${baseURL}cart/item`,
    REMOVE_CART_ITEM: `${baseURL}/cart/item`,
    FULFILLMENT: `${baseURL}cart/fulfillment`,
    RESERVATION: `${baseURL}cart/reservation`,
  },
  CHECKOUT: {
    DELIVERY_WINDOWS: `${baseURL}store/available/windows`,
    FULFILLMENT_WINDOW: `${baseURL}cart/available/windows`,
    ORDER_SUBMIT: `${baseURL}order/submit`,
    ORDER_SUBMIT_ADD_DETAILS: `${baseURL}order/submit?step=details`,
  },
  PAYMENT: {
    GET_ADYEN_PAYMENT_METHODS: `${baseURL}payment/methods`,
    GET_ADYEN_PAYMENT: `${baseURL}payment/auth`,
    POST_ADYEN_METHODS_DISABLE: `${baseURL}payment/methods/disable`,
  },
  PICKUP_STORES: `${baseURL}store`,
  ZIPCODE: `${baseURL}store/fulfillment`,
  GOOGLE_MAPS: "https://maps.googleapis.com/maps/api/js",
  CONTENTFUL: `${nextAppEnv.contentful.url}/spaces/${nextAppEnv.contentful.spaceId}/environments/${nextAppEnv.contentful.environment}`,
  KLAVIYO: {
    TRACK: "track",
    IDENTIFY: "identify",
    SCRIPT_URL: "https://static.klaviyo.com/onsite/js/klaviyo.js",
  },
  ORDER: {
    ORDER_HISTORY: `${baseURL}order/history`,
  },
  YOTPO: {
    PRODUCTS: "products",
    STAR_RATING: "bottomline",
  },
  FAVORITES: {
    ENDPOINT: `${baseURL}customer/favorites`,
  },
  PREVIOUSLY_ORDERS: {
    ENDPOINT: `${baseURL}order/previously-ordered`,
  },
  PRODUCTS: {
    DATA_BY_SKU: `${staticURL}/sku-data`,
  },
  INVENTORY: {
    ENDPOINT: `${baseURL}product/inventory/rough/store`,
  },
  DYNAMICYIELD: {
    DYID_SERVER_COOKIE: `${baseURL}dynamicyield/user`,
  },
};

export default RestEndpoints;
