import { nextAppEnv } from "@Utils/environment";

const algoliaAppId = nextAppEnv.algolia.appId ?? "";
const algoliaAPIKey = nextAppEnv.algolia.apiKey ?? "";
const algoliaIndex = nextAppEnv.algolia.index ?? "";
const algoliaProductIndexMostPopular =
  nextAppEnv.algolia.indexProductMostPopular ?? "";
const algoliaProductIndexPriceDesc =
  nextAppEnv.algolia.indexProductPriceDesc ?? "";
const algoliaProductIndexPriceAsc =
  nextAppEnv.algolia.indexProductPriceAsc ?? "";
const algoliaProductIndexTitleAsc =
  nextAppEnv.algolia.indexProductTitleAsc ?? "";
const algoliaProductIndexTitleDesc =
  nextAppEnv.algolia.indexProductTitleDesc ?? "";
const algoliaSuggestionsIndex = nextAppEnv.algolia.indexSuggestions ?? "";
const algoliaRecipesIndex = nextAppEnv.algolia.indexRecipes ?? "";

const SORT_BY = {
  NONE: undefined,
  MOST_POPULAR: "most_popular",
  PRICE_ASC: "price_asc",
  PRICE_DESC: "price_desc",
  TITLE_ASC: "title_asc",
  TITLE_DESC: "title_desc",
};

const initialSortFilterItemsList = [
  /* { // Hidden by Lauren's request.. will be uncommented uppon her request later
    name: "Most Popular",
    value: SORT_BY.MOST_POPULAR,
  }, */
  {
    name: "Title (A-Z)",
    value: SORT_BY.TITLE_ASC,
  },
  {
    name: "Title (Z-A)",
    value: SORT_BY.TITLE_DESC,
  },
  {
    name: "Price (LOW-HIGH)",
    value: SORT_BY.PRICE_ASC,
  },
  {
    name: "Price (HIGH-LOW)",
    value: SORT_BY.PRICE_DESC,
  },
];

const initialStateAvailableFilters = [
  {
    type: "sort-by",
    name: "Sort By",
    items: initialSortFilterItemsList,
  },
];

const RECIPE_FACETS_KEYS = {
  COOK_TIME: "fields.cookTime",
  RECIPE_CARD_SERVING: "fields.recipeCardServingValue",
  RECIPE_CARD_DIFFICULTY: "fields.recipeCardDifficultyValue",
};

const RECIPE_FACETS_TITLE_KEYS = {
  [RECIPE_FACETS_KEYS.COOK_TIME]: "recipeCardTimeTitle",
  [RECIPE_FACETS_KEYS.RECIPE_CARD_SERVING]: "recipeCardServingTitle",
  [RECIPE_FACETS_KEYS.RECIPE_CARD_DIFFICULTY]: "recipeCardDifficultyTitle",
};

export {
  algoliaAPIKey,
  algoliaAppId,
  algoliaIndex,
  algoliaProductIndexMostPopular,
  algoliaProductIndexPriceAsc,
  algoliaProductIndexPriceDesc,
  algoliaProductIndexTitleAsc,
  algoliaProductIndexTitleDesc,
  algoliaRecipesIndex,
  algoliaSuggestionsIndex,
  initialSortFilterItemsList,
  initialStateAvailableFilters,
  SORT_BY,
  RECIPE_FACETS_KEYS,
  RECIPE_FACETS_TITLE_KEYS,
};
