interface ICommerceToolsDomains {
  LOCAL: string;
  DEV: {
    US: string;
    CA: string;
  };
  QA: {
    US: string;
    CA: string;
  };
  STAGING: {
    US: string;
  };
  PROD: {
    US: string;
    CA: string;
    ORIGINAL: string;
  };
}

export const COMMERCE_TOOLS_DOMAINS: ICommerceToolsDomains = {
  LOCAL: "http://localhost:3000/",
  DEV: {
    US: "https://www-dev.wildforkfoods.com/",
    CA: "https://wfretail-dev.wildfork.ca/",
  },
  QA: {
    US: "https://www-qa.wildforkfoods.com/",
    CA: "https://wfretail-qa.wildfork.ca/",
  },
  STAGING: {
    US: "https://www-staging.wildfork.com/",
  },
  PROD: {
    US: "https://wildfork.com/",
    CA: "https://wildfork.ca/",
    ORIGINAL: "https://www.wildforkfoods.com/", // TODO: we will probably name this key something else in the future
  },
};

export const wfFacebookUrl = "https://www.facebook.com/WildForkFoods/";
export const wfInstagramUrl = "https://www.instagram.com/wildforkfoods/";
export const imagePlaceholder10PxPath =
  "@Assets/images/imagePlaceholder10px.png";
export const commonThreadsWildForkUrl =
  "https://www.commonthreads.org/wildfork/";
export const commonThreadsHomeWebDomain = "https://www.commonthreads.org/";

export const shopifyQaDomain = "https://wildforkfoodsdev.myshopify.com/";
export const grillingPicksUrl = "/collections/grilling-picks/";
export const seafoodPicksUrl = "/collections/seafood-picks/";
export const premiumIndulgencesUrl = "/collections/premium-products/";
export const deliciousDessertsUrl = "/collections/delicious-desserts/";
export const exoticSpecialtyUrl = "/collections/exotic-specialty/";
export const fiveStarFavoritesUrl = "/collections/five-star-rated-products/";
export const chefInspiredFlavorUrl = "/collections/chef-inspired-gifts/";
export const amazingAppetizersUrl = "/collections/amazing-appetizers/";
export const classicSurfAndTurfUrl =
  "/products/classic-surf-turf?algoliaHit=true&variant=39335037730882";
export const wildForWagyuUrl =
  "/products/wild-for-wagyu?algoliaHit=true&variant=32549867880514";
export const wildForkGiftingUrl = "/collections/wild-fork-gifting/";
export const membershipTiersUrl = "/pages/membership-tiers/";
export const memorialDayShopUrl = "/collections/memorial-day/";
export const newProductShopUrl = "/collections/new-products/";
export const quickAndEasyShopUrl = "/collections/quick-easy/";
export const wfCustomFoodGiftsYouTubeVideoUrl =
  "https://www.youtube.com/embed/8sKRBmy5HWk?start=0&end=0&autoplay=1&loop=1&mute=1&playlist=8sKRBmy5HWk&version=3&rel=0";
export const ourStoryUrl = "/our-story/";
export const shopifyLoginUrl =
  "https://wildforkfoodsdev.myshopify.com/account/login?return_url=%2F";

export const KIOSK_VIEW_MODE_QUERY = "kiosk";
