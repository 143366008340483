import clsx from "clsx";
import { useState, ChangeEvent } from "react";
import Icon from "@Components/ui/icon";

import classes from "./Filter.module.scss";

export enum FilterType {
  checkbox = "checkbox",
  radio = "radio",
}

export interface IFilterOption {
  label: string;
  value: string;
  checked: boolean;
  className?: string;
}

export interface IFilter {
  label: string;
  value: string;
  type: FilterType;
  className?: string;
  options: IFilterOption[];
}

interface IFilterProps {
  data: IFilter[];
  className?: string;
  openByDefault?: string;
  filterIconLabel?: string;
  clearButtonLabel?: string;
  onClearAllFilterOptions?: () => void;
  onValuesChange?: (
    option: IFilterOption,
    filter: IFilter
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Filter = ({
  data,
  className,
  openByDefault,
  onValuesChange,
  filterIconLabel = "",
  onClearAllFilterOptions,
  clearButtonLabel = "clear all",
}: IFilterProps) => {
  const [activeFilterKey, setActiveFilterKey] = useState<string | undefined>(
    openByDefault
  );
  const activeFilter = data.find(({ value }) => value === activeFilterKey);
  const isClearAllButtonActive = data.some(({ options }) =>
    options.some(({ checked }) => checked)
  );

  return (
    <aside className={clsx(classes.wrapper, className)}>
      <div className={clsx(classes.filterRow, activeFilter && classes.active)}>
        <Icon iconName="filter" className={classes.filterIcon} />
        <span className={classes.filterIconLabel}>{filterIconLabel}:</span>

        {data.map((filter, index) => (
          <button
            key={`${filter.value}-${index}`}
            onClick={() =>
              setActiveFilterKey(
                activeFilterKey === filter.value ? undefined : filter.value
              )
            }
            className={clsx(
              classes.button,
              activeFilterKey === filter.value && classes.active,
              filter?.className
            )}
          >
            {filter.label}
            <Icon
              className={classes.arrowIcon}
              iconName="arrow-down-flexible"
            />
          </button>
        ))}

        {onClearAllFilterOptions ? (
          <button
            className={classes.clearButton}
            onClick={onClearAllFilterOptions}
            disabled={!isClearAllButtonActive}
          >
            {clearButtonLabel}
          </button>
        ) : null}
      </div>

      <div className={clsx(classes.optionsRow, activeFilter && classes.active)}>
        {activeFilter?.options.map((option, index) => (
          <div
            key={`${option.value}-${index}`}
            className={clsx(classes.option, option?.className)}
          >
            <input
              id={option.value}
              value={option.value}
              type={activeFilter.type}
              checked={option.checked}
              onChange={onValuesChange && onValuesChange(option, activeFilter)}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    </aside>
  );
};
