import { DY_PAGE_CONTEXT } from "@Constants/common";
import { COOKIE_KEYS } from "@Constants/storage";
import { createDyidServerCookie } from "@wff/api/dynamicYieldApi";
import logger from "./Logger";
import { getCookie } from "./cookies";

export const getPageTypeForDY = (path: string) => {
  if (path === "/") {
    return DY_PAGE_CONTEXT.HOMEPAGE;
  }
  if (path?.includes("/collections")) {
    return DY_PAGE_CONTEXT.CATEGORY;
  }
  if (path?.includes("/products")) {
    return DY_PAGE_CONTEXT.PRODUCT;
  }
  if (path?.includes("/cart")) {
    return DY_PAGE_CONTEXT.CART;
  }
  return DY_PAGE_CONTEXT.OTHER;
};

export const dyCreateDyidServerCookie = (dyid?: string) => {
  const dyidCookie = getCookie(COOKIE_KEYS.DYID);
  const dyidServerCookie = getCookie(COOKIE_KEYS.DYID_SERVER);
  if (
    (dyid || dyidCookie) &&
    (!dyidServerCookie ||
      (dyidServerCookie && dyid && dyidServerCookie !== dyid) ||
      (dyidServerCookie && dyidCookie && dyidServerCookie !== dyidCookie))
  ) {
    createDyidServerCookie({ dyid: dyid || dyidCookie }).catch((err: any) => {
      logger.error("There was an error creating the Dyid Server Cookie", err);
    });
  }
};
