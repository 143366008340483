import { ICustomer } from "interfaces";
import { FC, useState } from "react";
import { contextFactory } from "../helpers/contextFactory";

const DUMMY_CUSTOMER: ICustomer = {
  id: 5228154191931,
  email: "customer@test.com",
  first_name: "Dummy",
  last_name: "Customer",
  name: "Dummy Customer",
  accepts_marketing: true,
  has_account: true,
  orders_count: 20,
  total_spent: 200.0,
  addresses: [
    {
      id: 6434072723515,
      customer_id: 5228154191931,
      first_name: "Dummy",
      last_name: "Customer",
      name: "Dummy Customer",
      company: "WFF",
      address1: "10015 NW 41st Street",
      address2: "",
      city: "Doral",
      province: "Florida",
      country: "United States",
      zip: "33178",
      phone: "7862222222",
      province_code: "FL",
      country_code: "US",
      country_name: "United States",
      default: true,
    },
  ],
  addresses_count: 1,
  default_address: {
    id: 6434072723515,
    customer_id: 5228154191931,
    first_name: "Dummy",
    last_name: "Customer",
    company: "WFF",
    address1: "10015 NW 41st Street",
    address2: "",
    city: "Doral",
    province: "Florida",
    country: "United States",
    zip: "33178",
    phone: "7862222222",
    name: "Test Test",
    province_code: "FL",
    country_code: "US",
    country_name: "United States",
    default: true,
  },
  tags: ["freeshipground", "source::ecomm", "store::9012"],
  favorites: ["prime-beef-picanha"],
  previously_ordered: [
    "chocolate-lava-cake",
    "usda-choice-beef-picanha",
    "usda-choice-black-angus-beef-picanha-steaks",
    "prime-beef-picanha",
    "usda-choice-beef-picanha",
  ],
  reference_number:
    "ce265b0027a4a7ab27b1d7eabb5bedd0b604e7dbc2a781bf3020f67c363544fc",
};

interface AuthContextProps {
  currentCustomer: ICustomer | null;
  setCurrentCustomerHandler: () => void;
}

const [useAuth, AuthContext] = contextFactory<AuthContextProps>();

export { useAuth, AuthContext };

interface AuthProviderProps {}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [currentCustomer, setCurrentCustomer] = useState<ICustomer | null>(
    null
  );
  const setCurrentCustomerHandler = () => {
    currentCustomer
      ? setCurrentCustomer(null)
      : setCurrentCustomer(DUMMY_CUSTOMER);
  };

  return (
    <AuthContext.Provider
      value={{
        currentCustomer,
        setCurrentCustomerHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
