import { useState } from "react";
import { isValidZip } from "@Components/forms/helpers/FieldValidator";
import logger from "@Utils/Logger";
import { fetchStoreListData } from "@wff/hooks/useGetStores";
import { IStoreListing } from "@wff/interfaces";
import { useAppDispatch, useAppSelector } from "@wff/store/hooks";
import { localeSelector } from "@wff/store/localeSlice";
import {
  ZipCodeSelector,
  setPickupStoreListHandler,
} from "@wff/store/zipcodeSlice";

export interface IStoreListResponse {
  stores: IStoreListing[];
  isPickupable?: boolean;
}

export const usePickupAvailableForCA = () => {
  const dispatch = useAppDispatch();
  const [storeList, setStoreList] = useState<IStoreListing[]>([]);
  const currentLocale = useAppSelector(localeSelector);
  /**
   * Changes currentLocale?.countryLong, because in the case of
   * geocode(M4V0A5, CA) will gives you wrong Lat, Long
   * CA is treated as California not Canada.
   */
  const countryCode = currentLocale?.countryLong;
  const selectAddress = useAppSelector(ZipCodeSelector);
  const state = selectAddress?.address?.state;

  const getPickUpAvailable = async (
    zipcode: string,
    initGoogleMapsLoadedFromParent?: boolean
  ): Promise<IStoreListResponse> => {
    return new Promise((resolve) => {
      const defaultRes = {
        stores: [],
        isPickupable: false,
      };
      const isBrowser: boolean = (() => typeof window !== "undefined")();
      const initGoogleMapsLoaded =
        initGoogleMapsLoadedFromParent ||
        (typeof window !== "undefined" && window?.google?.maps)
          ? true
          : false;
      if (
        initGoogleMapsLoaded &&
        zipcode &&
        isBrowser &&
        isValidZip()(zipcode) === undefined // only execute when zipcode (multi-locale) is valid
      ) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder
          .geocode({
            address: `${state} ${zipcode}, ${countryCode?.toUpperCase()}`,
          })
          .then(async (res) => {
            if (!res?.results?.length || !res?.results[0]?.geometry?.location) {
              resolve(defaultRes);
              throw new Error(
                "there were no results in google's geocode response"
              );
            } else {
              const latitude = res?.results[0]?.geometry?.location?.lat();
              const longitude = res?.results[0]?.geometry?.location?.lng();
              const response = await fetchStoreListData({
                latitude,
                longitude,
                setStoreList,
                dispatch,
              });
              dispatch(setPickupStoreListHandler([...response.stores]));
              resolve(response);
            }
          })
          .catch((err) => {
            dispatch(setPickupStoreListHandler([]));
            resolve(defaultRes);
            logger.error(
              "useGetCoordinatesAndFetchData() in usePickupAvailableForCA.ts",
              err
            );
          });
      } else {
        resolve(defaultRes);
      }
    });
  };

  return {
    storeList,
    getPickUpAvailable,
  };
};
