import { FieldValidator } from "final-form";
import { store } from "store";
import {
  IStringValidator,
  IValidateBillingAddressFieldOptions,
} from "@wff/interfaces/validators";
import PATTERNS from "utils/patterns";

/* Email Max Length
    email string size limit is a maximum of 64 characters (octets) in the "local part" (before the "@") 
    and a maximum of 255 characters (octets) in the domain part (after the "@") 
    for a total length of 320 characters.
*/
export const EMAIL_MAX_LENGTH = 320;

/** Boolean Returning Validators
 *
 * @param string to validate
 * @returns boolean
 *
 */

/***************** Begin Boolean Returning Validators *****************/

export const composeValidators = (
  value: string,
  validators: IStringValidator[],
  options?: object
): boolean => {
  return validators.every((validator) => {
    return validator(value, options);
  });
};

export const validEmail: IStringValidator = (email) => {
  const regex = new RegExp(PATTERNS.EMAIL);
  return regex.test(email.trim()); // for trimming extra space in email field
};

export const validPassword: IStringValidator = (password) => {
  const regex = new RegExp(PATTERNS.PASSWORD);
  return regex.test(password);
};

export const validStrongPassword: IStringValidator = (password) => {
  const regex = new RegExp(PATTERNS.STRONG_PASSWORD);
  return regex.test(password);
};

export const validStrongPasswordForCA: IStringValidator = (password) => {
  const regex = new RegExp(PATTERNS.STRONG_PASSWORD_CA);
  return regex.test(password);
};

export const validName: IStringValidator = (name) => {
  const regex = new RegExp(PATTERNS.NAME);
  return regex.test(name);
};

export const validAddress: IStringValidator = (address) => {
  const regex = new RegExp(PATTERNS.ADDRESS);
  return regex.test(address);
};

export const validCity: IStringValidator = (city) => {
  const regex = new RegExp(PATTERNS.CITY);
  return regex.test(city);
};

export const validZip_US: IStringValidator = (zip) => {
  const regex = new RegExp(PATTERNS.ZIP_US);
  return regex.test(zip);
};

export const validZip_MX: IStringValidator = (zip) => {
  const regex = new RegExp(PATTERNS.ZIP_MX);
  return regex.test(zip);
};

export const validZip_CA: IStringValidator = (zip) => {
  const regex = new RegExp(PATTERNS.ZIP_CA);
  return regex.test(zip);
};

export const validZip: IStringValidator = (
  zip,
  options?: Partial<IValidateBillingAddressFieldOptions>
) => {
  // TODO from Jared: we should update validZip and its options override to utilize languageCode instead so we can apply the LOCALE constant and track its use
  const {
    locale: { countryShort },
  } = store.getState();

  let zipValidatorByLocale: IStringValidator;
  switch (
    (options as Partial<IValidateBillingAddressFieldOptions>)
      ?.countryShortOverride ||
    countryShort
  ) {
    case "CA":
    case "ca":
      zipValidatorByLocale = validZip_CA;
      break;
    case "MX":
    case "mx":
      zipValidatorByLocale = validZip_MX;
      break;
    case "US":
    case "us":
    default:
      zipValidatorByLocale = validZip_US;
      break;
  }
  return zipValidatorByLocale(zip);
};

/**
 * This is a TypeScript function that checks if a given phone number is valid using a regular
 * expression pattern.
 * @param phone - `phone` is a string parameter representing a phone number that needs to be validated.
 * @returns The function `validPhone` is being returned. It takes a string parameter `phone` and
 * returns a boolean value indicating whether the `phone` string matches the regular expression pattern
 * defined in `PATTERNS.PHONE`. This function is a validator function that can be used to check if a
 * given phone number is valid according to the defined pattern.
 */
export const validPhone: IStringValidator = (phone) => {
  return PATTERNS.PHONE.test(phone);
};

export const validCoupon = (value: string) => {
  const regex = new RegExp(PATTERNS.COUPON);
  return regex.test(value);
};

export const minLengthCurrier = (length: number): IStringValidator => {
  return (value: string): boolean => {
    return value?.length >= length;
  };
};

/** Validation Error Producing Curriers:
 *
 * @param customError
 * @returns validating function that returns an error string
 *
 */

/***************** Begin Validation Error Producing Curriers *****************/

export type TValidationErrorCurrier = (
  customError?: string,
  options?: any
) => FieldValidator<string>;
export type TConfirmPasswordErrorCurrier = (
  password: string,
  customError?: string
) => FieldValidator<string>;
export type TLengthLimitErrorCurrier = (
  minOrMax: number,
  customError?: string
) => (value: string, field?: string) => string | undefined;

/**
 * Function to immediately force validation failure
 * @param {string} customError - Custom error message to displays
 * @returns validating function that returns an error string
 */
export const forceFailure: TValidationErrorCurrier =
  (customError) => (_value, field) =>
    customError ? customError : `Please enter a valid ${field ?? "input"}`;

/**
 * Function to check value is present
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const required: TValidationErrorCurrier =
  (customError) => (value, field) =>
    value
      ? undefined
      : customError ?? `Please enter a valid ${field ?? "input"}`;

/**
 * Function to validate email address
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const email: TValidationErrorCurrier = (customError) => (value, field) =>
  value && PATTERNS.EMAIL.test(value.trim()) // for trimming extra space in email field
    ? undefined
    : customError ?? `Please enter ${field ?? "Input"} in valid format`;

/**
 * Function to validate password
 *   - 8 Characters Long
 *   - Uppercase Required
 *   - Lowercase Required
 *   - Number Required
 *   - Special Character Required
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const password: TValidationErrorCurrier =
  (customError) => (value, field) =>
    value && PATTERNS.PASSWORD.test(value)
      ? undefined
      : customError ?? `Please enter ${field ?? "Input"} in valid format`;

export const strongPassword: TValidationErrorCurrier =
  (customError) => (value, field) =>
    value && PATTERNS.STRONG_PASSWORD.test(value)
      ? undefined
      : customError ?? `Please enter ${field ?? "Input"} in valid format`;

export const strongPasswordForCA: TValidationErrorCurrier =
  (customError) => (value, field) =>
    value && PATTERNS.STRONG_PASSWORD_CA.test(value)
      ? undefined
      : customError ?? `Please enter ${field ?? "Input"} in valid format`;

/**
 * Function to verify password and confirm password
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const confirmPassword: TConfirmPasswordErrorCurrier =
  (password, customError) => (value, field) =>
    !value || value !== password
      ? customError ??
        `Password and ${field ?? "Confirm Password"} should match`
      : undefined;

/**
 * Function to validate name
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const name: TValidationErrorCurrier = (customError) => (value, field) =>
  PATTERNS.NAME.test(value)
    ? undefined
    : customError ?? `Please enter a valid ${field ?? "Input"}`;

/**
 * Function to validate address
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const address: TValidationErrorCurrier =
  (customError) => (value, field) =>
    PATTERNS.ADDRESS.test(value)
      ? undefined
      : customError ?? `Please enter ${field ?? "Input"} in valid format`;

/**
 * Function to validate city
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const isValidCity: TValidationErrorCurrier =
  (customError) => (value, field) =>
    PATTERNS.CITY.test(value)
      ? undefined
      : customError ?? `Please enter ${field ?? "Input"} in valid format`;

/**
 * Function to validate zip
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const isValidZip_US: TValidationErrorCurrier =
  (customError) => (value, field) =>
    PATTERNS.ZIP_US.test(value)
      ? undefined
      : customError ?? `Please enter ${field ?? "Input"} in valid format`;

/**
 * Function to validate zip
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const isValidZip_MX: TValidationErrorCurrier =
  (customError) => (value, field) =>
    PATTERNS.ZIP_MX.test(value)
      ? undefined
      : customError ??
        `Ingrese ${
          field ?? "la entrada"
        } en un formato válido  in valid format`;

/**
 * Function to validate zip
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const isValidZip_frCA: TValidationErrorCurrier =
  (customError) => (value, field) =>
    PATTERNS.ZIP_CA.test(value)
      ? undefined
      : customError ??
        `veuillez entrer ${field ?? "l'entrée"} dans un format valide`;

/**
 * Function to validate zip
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const isValidZip_enCA: TValidationErrorCurrier =
  (customError) => (value, field) =>
    PATTERNS.ZIP_CA.test(value)
      ? undefined
      : customError ?? `Please enter ${field ?? "Input"} in valid format`;

/**
 * Function to validate zip
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const isValidZip: TValidationErrorCurrier = (
  customError?: string,
  options?: IValidateBillingAddressFieldOptions
) => {
  const {
    locale: { languageCode },
  } = store.getState();
  let zipValidatorByLocale;
  switch (
    (options as IValidateBillingAddressFieldOptions)?.langCodeOverride ||
    languageCode
  ) {
    case "fr-CA": // TODO from Jared: update these to use LOCALE constant - and use toLowerCase() as needed
    case "fr-ca":
      zipValidatorByLocale = isValidZip_frCA;
      break;
    case "en-CA":
    case "en-ca":
      zipValidatorByLocale = isValidZip_enCA;
      break;
    case "es-MX":
    case "es-mx":
      zipValidatorByLocale = isValidZip_MX;
      break;
    case "en-US":
    case "en-us":
    default:
      zipValidatorByLocale = isValidZip_US;
      break;
  }
  return zipValidatorByLocale(customError);
};

/**
 * Function to validate phone
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const phoneNumber: TValidationErrorCurrier =
  (customError) => (value, field) =>
    PATTERNS.PHONE.test(value)
      ? undefined
      : customError ?? `Please enter ${field ?? "Input"} in valid format`;

/**
 * Function to validate min length
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const minLength: TLengthLimitErrorCurrier =
  (min: number, customError?: string) => (value: string, field?: string) =>
    value?.toString()?.length >= min
      ? undefined
      : customError ?? `${field ?? "Input"} should be greater than ${min - 1}`;

/**
 * Function to validate max length
 * @param {string} customError - Custom error message to display
 * @returns
 */
export const maxLength: TLengthLimitErrorCurrier =
  (max: number, customError?: string) => (value: string, field?: string) =>
    value?.toString()?.length <= max
      ? undefined
      : customError ?? `${field ?? "Input"} should be less than ${max + 1}`;

/**
 * Function to Compose multiple validation functions
 * @param  {function} validators - validation functions
 * @returns
 */
const CTFieldValidator =
  ([...validators], field?: string) =>
  (value: string) =>
    validators.reduce((error, validator) => {
      return error || validator(value, field);
    }, undefined);

// named CTFieldValidator (CT for Commerce Tools) to avoid collisions with third party libraries using a common name
export default CTFieldValidator;
