import cookies from "js-cookie";
import { COOKIE_KEYS } from "@Constants/storage";
import logger from "./Logger";
import { nextAppEnv } from "./environment";
import { getDomain } from "./url";

export const cookieSettingsForUS = {
  path: "/",
};

/* currently using js-cookie
  getting a cookie is straightforward
  but please review the documentation for specific instructions
  on setting and removing a cookie
  https://www.npmjs.com/package/js-cookie
*/

export const getCookie = (name: string) => {
  const value = cookies.get(name);
  let parsedValue;
  if (value) {
    try {
      // if parsing fails then it must be a regular string
      parsedValue = JSON.parse(value as string);
    } catch (_err) {
      parsedValue = value;
    }
  }
  return parsedValue;
};

export const setCookie = (name: string, value: any, options: object = {}) => {
  let parsedValue;
  if (typeof value === "string") {
    parsedValue = value;
  } else {
    parsedValue = JSON.stringify(value);
  }
  try {
    if (Object.keys(options)?.length) {
      cookies.set(name, parsedValue, options);
    } else {
      cookies.set(name, parsedValue);
    }
  } catch (err) {
    logger.error(
      "cookie.ts setCookie() [custom error]",
      `Error setting the cookie named ${name} with options ${JSON.stringify(
        options
      )}`
    );
    logger.error("cookie.ts setCookie() [standard error]", err);
    return err;
  }
  return parsedValue;
};

export const removeCookie = (name: string, options: object = {}) => {
  try {
    if (Object.keys(options)?.length) {
      cookies.remove(name, options);
    } else {
      cookies.remove(name);
    }
    return undefined;
  } catch (err) {
    logger.error(
      "cookie.ts removeCookie() [custom error]",
      `Error removing the cookie named ${name} with options ${JSON.stringify(
        options
      )}`
    );
    logger.error("cookie.ts removeCookie() [standard error]", err);
    return err;
  }
};

export const getAllCookies = () => {
  return cookies.get();
};

export const removeTemporaryCartIdCookieForUS = () => {
  removeCookie(COOKIE_KEYS.CART_ID, cookieSettingsForUS);
  // just in case a US simulated COOKIE_KEYS.CART_ID cookie was ever created on the Front End with different/varied domain/path attributes, then we remove all those too
  // the below can probably be deleted once US cartId cookie simulation achieves stability
  removeCookie(COOKIE_KEYS.CART_ID);
  removeCookie(COOKIE_KEYS.CART_ID, {
    path: "/",
  });
  removeCookie(COOKIE_KEYS.CART_ID, {
    path: "/",
    domain: getDomain(nextAppEnv.application.publicUrl as string),
  });
};

export const removeCartIdCookie = (locale: string) => {
  if (locale !== "en-US") {
    removeCookie(COOKIE_KEYS.CART_ID);
  } else {
    removeTemporaryCartIdCookieForUS();
  }
};
