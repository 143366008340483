import { createSlice } from "@reduxjs/toolkit";
import { STORAGE_KEYS, STORAGE_TYPES } from "@Constants/storage";
import {
  removeItem as removeItemFromBrowserStorage,
  setItem as setItemInBrowserStorage,
} from "@Utils/browserStorage";
import { IStoreListing } from "@wff/interfaces";
import { IZipCodeObj, IZipCodeState, IPickupState } from "../types/zipcode";
import { AppDispatch, RootState } from ".";

export const initialStateZipcode = {
  zipcode: "",
  availability: null,
};

export const initialState: IZipCodeState = {
  zipcodePopupOpen: false,
  ...initialStateZipcode,
  address: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    phone: "",
  },
  nearestPickupLocation: {
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    phone: "",
  },
  isPickupAvailable: false,
  pickupStoreList: [],
};

export const ZipCodeSlice = createSlice({
  name: "zipcode",
  initialState,
  reducers: {
    toggleZipCodePopUp(state) {
      return { ...state, zipcodePopupOpen: !state.zipcodePopupOpen };
    },
    setZipCode(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    setNearestPickupLocation(state, { payload }) {
      return {
        ...state,
        nearestPickupLocation: { ...payload.nearestPickupLocation },
        isPickupAvailable: payload.isPickupAvailable,
      };
    },
    setPickupStoreList(state, { payload }) {
      return {
        ...state,
        pickupStoreList: payload,
      };
    },
  },
});
export const {
  toggleZipCodePopUp,
  setZipCode,
  setNearestPickupLocation,
  setPickupStoreList,
} = ZipCodeSlice.actions;

export const setZipCodeHandler =
  (
    zipcodeObj: IZipCodeObj,
    setItem = setItemInBrowserStorage,
    removeItem = removeItemFromBrowserStorage
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(setZipCode(zipcodeObj));
    if (zipcodeObj.zipcode) {
      setItem(
        STORAGE_KEYS.CUSTOMER_ZIP,
        zipcodeObj.zipcode,
        STORAGE_TYPES.LOCAL
      );
    } else {
      removeItem(STORAGE_KEYS.CUSTOMER_ZIP, STORAGE_TYPES.LOCAL);
    }
  };

export const setZipCodePickupHandler =
  (payload: IPickupState, setItem = setItemInBrowserStorage) =>
  (dispatch: AppDispatch) => {
    dispatch(setNearestPickupLocation(payload));
    setItem(
      STORAGE_KEYS.CUSTOMER_ZIP_PICKUP_AVAILABILITY_CA,
      `${payload.isPickupAvailable}`,
      STORAGE_TYPES.LOCAL
    );
  };

export const setPickupStoreListHandler =
  (payload: IStoreListing[]) => (dispatch: AppDispatch) => {
    dispatch(setPickupStoreList(payload));
  };

export const ZipCodeSelector = (state: RootState) => state.zipcode;

export default ZipCodeSlice.reducer;
