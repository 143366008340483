import { useCallback } from "react";
import { STORAGE_KEYS, STORAGE_TYPES } from "@Constants/storage";
import { getItem } from "@Utils/browserStorage";
import { nextAppEnv } from "@Utils/environment";
import { isCanadianLocale } from "@Utils/locale";
import { submitZipcode } from "@wff/api/checkoutApi";
import { setStoreIdHandler } from "@wff/store/cartSlice";
import { useAppDispatch, useAppSelector } from "@wff/store/hooks";
import { localeSelector } from "@wff/store/localeSlice";
import { setZipCodeHandler, ZipCodeSelector } from "@wff/store/zipcodeSlice";

export const useZipCode = () => {
  const dispatch = useAppDispatch();
  const {
    zipcode: zipcodeSavedOnRedux,
    availability,
    name = "",
  } = useAppSelector(ZipCodeSelector);
  const zipCodeSavedOnStorage = getItem(
    STORAGE_KEYS.CUSTOMER_ZIP,
    STORAGE_TYPES.LOCAL
  );
  const currentLocale = useAppSelector(localeSelector);
  const isCanadianUser = isCanadianLocale(currentLocale);
  const zipcode = zipcodeSavedOnRedux || zipCodeSavedOnStorage;
  const handleSubmitZipcode = useCallback(
    async (zipCodeProp?: string) => {
      let availability = true;
      const zipcodeToSubmit = zipCodeProp || zipcode;

      try {
        const response = await submitZipcode(zipcodeToSubmit);
        if (response?.data?.stores?.length) {
          dispatch(setStoreIdHandler(response.data.stores[0].id));
          const addressRes = {
            address: { ...response.data.stores[0]?.address }, // setting up only required keys in reducer, please fill free to add more keys as per your need
            name: response.data.stores[0]?.name,
            curbsidePickup: response.data.stores[0]?.curbsidePickup, // adding this key for CE-810
          };
          dispatch(
            setZipCodeHandler({
              zipcode: zipcodeToSubmit,
              availability,
              ...addressRes,
            })
          );
        } else if (isCanadianUser) {
          // adding else condition to handle API response in case of store is blank
          availability = false;
          dispatch(
            setZipCodeHandler({
              zipcode: zipcodeToSubmit,
              availability,
            })
          );
        }
      } catch (error) {
        availability = false;
        dispatch(
          setStoreIdHandler(nextAppEnv.application.defaultPickupStoreId)
        );
        dispatch(
          setZipCodeHandler({
            zipcode: zipcodeToSubmit,
            availability,
          })
        );
      }

      return availability;
    },
    [dispatch, zipcode, isCanadianUser]
  );

  return {
    name,
    zipcode,
    availability,
    handleSubmitZipcode,
  };
};
