import { store } from "store";
import { nextAppEnv } from "./environment";
import { isANumber } from "./validation";

// this optionsOverride object is useful for overriding the currency locate/format
// for example, we legally have to allow Canadians to change the currency from their default
interface ICurrencyFormatOptions extends Intl.NumberFormatOptions {
  languageCode?: string; // en-US || en-CA etc..
}

export const format = (
  value: number,
  optionsOverride?: ICurrencyFormatOptions
) => {
  // create formatter
  const locale = store.getState().locale;
  const formatter = new Intl.NumberFormat(
    optionsOverride?.languageCode ||
      locale.languageCode ||
      nextAppEnv.application.locale ||
      "en-US",
    {
      style: "currency",
      currency:
        optionsOverride?.currency ||
        locale.currency ||
        nextAppEnv.application.locale ||
        "USD",
      minimumFractionDigits:
        optionsOverride?.minimumFractionDigits != null
          ? optionsOverride?.minimumFractionDigits
          : 2, // null validation is to avoid falsy on 0
      maximumFractionDigits:
        optionsOverride?.maximumFractionDigits != null
          ? optionsOverride?.maximumFractionDigits
          : 2, // null validation is to avoid falsy on 0
    }
  );

  const notNaNValue = isNaN(value) ? 0 : value;

  return locale.languageCode.toLocaleLowerCase().includes("fr")
    ? formatter.format(notNaNValue).replace(".", ",")
    : formatter.format(notNaNValue);
};

export const convertToDollar = (value: number | any) => {
  if (isANumber(value)) {
    return parseFloat((value / 100).toFixed(2));
  }

  return 0;
};

export const convertToCents = (value: string | number) => {
  if (isANumber(value)) {
    return Number(value) * 100;
  }

  return 0;
};
