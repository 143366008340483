import { AxiosError } from "axios";
import {
  GENERIC_EVENT_ERR_HEADING,
  GENERIC_ERR_MSG,
} from "@Constants/snackbar";
import { TApiCatchBlock } from "@Types/error";
import { ICodeMessage, ICustomBackendError } from "@wff/interfaces/error";

interface IFindErrorCode<TData, TError> {
  code: string;
  error: TApiCatchBlock<TData, TError> | unknown;
}

export const getErrorHeadingFromApiResponse = <
  TData = unknown,
  TError = unknown
>(
  error: TApiCatchBlock<TData, TError> | unknown
) => {
  // axios api response structure:                response.data.errors
  // legacy fetch APIService response structure:  serviceResponse.payload.errors
  return (
    (error as unknown as AxiosError)?.response?.status ||
    (error as unknown as ICustomBackendError)?.response?.data?.status ||
    (error as any)?.serviceResponse?.payload?.status ||
    GENERIC_EVENT_ERR_HEADING.REQUEST_FAILED()
  );
};

export const getErrorMessageFromApiResponse = <
  TData = unknown,
  TError = unknown
>(
  error: TApiCatchBlock<TData, TError> | unknown
) => {
  // axios api response structure:                response.data.errors
  // legacy fetch APIService response structure:  serviceResponse.payload.errors
  return (
    (error as unknown as AxiosError)?.message ||
    (error as any)?.serviceResponse?.payload?.message ||
    GENERIC_ERR_MSG.ERROR()
  );
};

export const findErrorCode = <TData = unknown, TError = unknown>({
  code,
  error,
}: IFindErrorCode<TData, TError>) => {
  // axios api response structure:                response.data.errors
  // legacy fetch APIService response structure:  serviceResponse.payload.errors
  let errorFound: ICodeMessage | undefined = undefined;
  if (!error) {
    return errorFound;
  } else if ((error as any)?.response?.data?.errors?.length) {
    errorFound = (error as any)?.response?.data?.errors?.find(
      (err: ICodeMessage) => {
        return err?.code === code;
      }
    );
  } else if ((error as any)?.serviceResponse?.payload?.errors?.length) {
    errorFound = (error as any)?.serviceResponse?.payload?.errors?.find(
      (err: ICodeMessage) => {
        return err?.code === code;
      }
    );
  } else if ((error as any)?.customCode) {
    // for legacy server error response structure
    errorFound =
      (error as any)?.customCode === code
        ? {
            code: getErrorHeadingFromApiResponse(error),
            message: getErrorMessageFromApiResponse(error),
          }
        : undefined;
  }
  return errorFound;
};

// Added this function to find axios error and server response errors
export const findErrorCodeForCA = <TData = unknown, TError = unknown>({
  code,
  error,
}: IFindErrorCode<TData, TError>) => {
  // axios api response structure:                response.data.errors
  // legacy fetch APIService response structure:  serviceResponse.payload.errors
  let errorFound: ICodeMessage | undefined = undefined;
  if (!error) {
    return errorFound;
  } else if ((error as any)?.response?.data?.errors?.length) {
    errorFound = (error as any)?.response?.data?.errors?.find(
      (err: ICodeMessage) => {
        return err?.code === code;
      }
    );
  } else if ((error as any)?.serviceResponse?.payload?.errors?.length) {
    errorFound = (error as any)?.serviceResponse?.payload?.errors?.find(
      (err: ICodeMessage) => {
        return err?.code === code;
      }
    );
  } else if ((error as any)?.customCode) {
    // for legacy server error response structure
    errorFound =
      (error as any)?.customCode === code
        ? {
            code: getErrorHeadingFromApiResponse(error),
            message: getErrorMessageFromApiResponse(error),
          }
        : undefined;
  } else if ((error as any)?.code) {
    // for axios error response structure
    errorFound =
      (error as any)?.code === code
        ? {
            code: (error as any)?.code,
            message: (error as any)?.message,
          }
        : undefined;
  }
  return errorFound;
};
