export default function timerWorkerForCA() {
  self.addEventListener("message", (event: MessageEvent) => {
    event.preventDefault();
    if (event.data === "TIMER_EVENT") {
      setTimeout(() => {
        self.postMessage({ message: { twoMintsPassed: true } });
      }, 120000); // 2 mints delay (2 * 60 * 1000)
    }
  });
}
