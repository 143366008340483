import { nextAppEnv } from "@Utils/environment";

const baseURI = nextAppEnv.api.baseUri;

// NOTE: THIS IS LEGACY CODE. DO NOT USE THIS TO ADD NEW ENDPOINTS - ALL NEW ENDPOINTS SHOULD GO IN api/constants/RestEndpoints.ts
const RestEndpoints = {
  ACCOUNT: {
    LOGIN: `${baseURI}/rest-login`, // TODO - update correct endpoint here
    REGISTER: `${baseURI}/register`, // TODO - update correct endpoint here
    FORGOT_PASSWORD: `${baseURI}/forgot-password`, // TODO - update correct endpoint here
  },
  EMAIL_SIGNUP: `${baseURI}/email-signup`, // TODO - update correct endpoint here
  CHECKOUT: {
    DELIVERY_WINDOWS: `${baseURI}store/available/windows`,
    TWILIO_PHONE_LOOKUP: nextAppEnv.twilio.baseUrl,
    GET_CART_ITEMS: `${baseURI}cart`,
    REMOVE_CART_ITEM: `${baseURI}cart/item`,
    SHIPPING_ADDRESS: `${baseURI}cart/address/shipping`,
    BILLING_ADDRESS: `${baseURI}cart/address/billing`,
    ORDER_SUBMIT: `${baseURI}order/submit`,
  },
  PRODUCT: {
    GET_PRODUCT: `${baseURI}/api/products`, // TODO - update with correct endpoint here when developed.
  },
  CART: {
    GET_CART: `${baseURI}cart`,
    SET_BILLING_ADDRESS: `${baseURI}cart/address/billing`,
  },
};
// NOTE: THIS IS LEGACY CODE. DO NOT USE THIS TO ADD NEW ENDPOINTS - ALL NEW ENDPOINTS SHOULD GO IN api/constants/RestEndpoints.ts

export default RestEndpoints;
