import { getCartIdForHeader } from "@Utils/reduxCommon";
import RestEndpoints from "@wff/configs/RestEndpoints";
import ApiService from "@wff/services/ApiService";
import type { ApiConfigTypes } from "@wff/services/ApiService";
import { IShippingAddress } from "@wff/types/checkout";

/**
 * Constructs api endpoint url
 * @returns {string} - Endpoint url
 */
const prepareEndPoint = () => RestEndpoints.CHECKOUT.SHIPPING_ADDRESS;

/**
 * Prepare Request Config
 * @param _data
 * @param config
 * @returns
 */
const prepareRequestConfig = (_data: any, config: ApiConfigTypes) => {
  config.headers = {
    ...config.headers,
    "X-WILDFORK-CART-ID": getCartIdForHeader(),
  };
  return config;
};

/**
 * Constructs request payload
 * @param {object} data
 * @return {object}
 */
const prepareRequestPayload = (data: IShippingAddress) => {
  // TODO from Jared: dialCode may be needed for other countries
  return {
    address: {
      id: data?.id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      address1: data?.address1,
      address2: data?.address2,
      city: data?.city,
      state: data?.state,
      postalCode: data?.postalCode,
      country: data?.country,
      phone: data?.phone,
    },
    saveAddress: data?.saveAddress,
    customerEmail: data?.loggedInUserEmail || data?.email,
  };
};

// NOTE: THIS IS LEGACY CODE. DO NOT USE AS TEMPLATE FOR NEW API SERVICES - ALL NEW ENDPOINTS SHOULD GO IN /API
/**
 * AddShippingAddressService Class
 * @returns {*} AddShippingAddressService class instance
 */
class AddShippingAddressService extends ApiService.Service {
  constructor() {
    super({
      method: ApiService.methods.PUT,
      getEndPoint: prepareEndPoint,
      getRequestPayload: prepareRequestPayload,
      getRequestConfig: prepareRequestConfig,
    });
  }
}

export default new AddShippingAddressService();
