import { red } from "@mui/material/colors";
import {
  createTheme,
  Theme,
  Palette,
  ThemeOptions,
} from "@mui/material/styles";

interface IPalette extends Palette {
  common: {
    black: string;
    white: string;
    offWhite?: string;
    lightGray?: string;
  };
}
export interface ITheme extends Theme {
  palette: IPalette;
}
export interface IThemeOptions extends ThemeOptions {
  palette: IPalette;
}

// A custom theme for this app
const theme: ITheme = createTheme({
  palette: {
    primary: {
      main: "#d14124",
    },
    secondary: {
      main: "#3d3935",
    },
    common: {
      offWhite: "#ddd",
      lightGray: "#9e9e9e",
    },
    error: {
      main: red.A400, // TODO: this value came with the MUI snippet that was copied, feel free to change/update
    },
  },
} as IThemeOptions);

export default theme;
