import { useEffect, useState } from "react";
import { nextAppEnv } from "@Utils/environment";
import { loadAsyncScript } from "@Utils/loadAsyncScript";
import RestEndpoints from "@wff/api/constants/RestEndpoints";

const apiKey = nextAppEnv.googleMaps.apiKey;
const mapApiJs = RestEndpoints.GOOGLE_MAPS;

export const initMapScript = () => {
  if (window.google?.maps) {
    return Promise.resolve();
  }

  const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
  return loadAsyncScript(src);
};

export const useInitGoogleMaps = () => {
  const [initGoogleMapsLoaded, setInitGoogleMapsLoaded] = useState(
    typeof window !== "undefined" && window?.google?.maps ? true : false
  );

  useEffect(() => {
    initMapScript().then(() => {
      setInitGoogleMapsLoaded(true);
    });
  }, []);

  return { initGoogleMapsLoaded };
};
