/* eslint-disable security/detect-object-injection */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { YotpoState } from "@Types/Yotpo";
import { getBottomLineRating } from "../api/yotpoApi";

export const initialState: YotpoState = {
  bottomLineRatingResult: {},
};

export const yotpoSlice = createSlice({
  name: "yotpo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBottomLineRatingAction.pending, (state, action) => {
        const {
          meta: { arg },
        } = action;
        state.bottomLineRatingResult[arg] = {
          data: null,
          status: "pending",
        };
      })
      .addCase(getBottomLineRatingAction.fulfilled, (state, action) => {
        const {
          meta: { arg },
          payload,
        } = action;
        state.bottomLineRatingResult[arg] = {
          data: payload,
          status: "fulfilled",
        };
      })
      .addCase(getBottomLineRatingAction.rejected, (state, action) => {
        const {
          meta: { arg },
        } = action;
        state.bottomLineRatingResult[arg] = {
          data: null,
          status: "rejected",
        };
      });
  },
});

export const getBottomLineRatingAction = createAsyncThunk(
  "yotpo/getBottomLineRating",
  async (productId: string) => {
    return await getBottomLineRating(productId);
  }
);

export default yotpoSlice.reducer;
