import * as defaultConfig from "@wff/configs/LogConfig";
import { amInLocalQaOrDevDomain } from "./url";
import { isArray, isObject } from "./validation";
interface ILogConfigTypes {
  error?: boolean;
  warn?: boolean;
  info?: boolean;
}

interface ILogLevelColors {
  INFO: string;
  WARN: string;
  ERROR: string;
}

interface IStandardLogOptions {
  jsonReplacer: Function | (string | number)[] | null;
  jsonSpacing: number;
}

interface ILoggerOptions extends IStandardLogOptions {
  printAsJson: boolean;
}

interface ILog {
  (val: string | Object, trace?: any, options?: IStandardLogOptions): string;
}

interface IConsoleLevels {
  [key: string]: (
    val: string | Object,
    trace?: any,
    options?: ILoggerOptions
  ) => void; // pass printAsJson = false to suppress auxiliary logging of the 2nd param as standard JSON/Object/Array structure
}

export const LOGGER_LEVEL_COLORS: ILogLevelColors = {
  INFO: "color:deepskyblue",
  WARN: "color:orange",
  ERROR: "color:lightcoral",
};

const defaultOptions: ILoggerOptions = {
  printAsJson: true,
  jsonReplacer: null,
  jsonSpacing: 2,
};

const isDev =
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "test" ||
  amInLocalQaOrDevDomain();
const logConfig = (process.env.LOG_CONFIG ?? defaultConfig) as ILogConfigTypes;

export const formatMessage: ILog = (val, trace, options) => {
  return `${JSON.stringify(
    val,
    (options as any)?.jsonReplacer,
    options?.jsonSpacing
  )}${
    trace
      ? "  =>  " +
        JSON.stringify(
          trace,
          (options as any)?.jsonReplacer,
          options?.jsonSpacing
        )
      : ""
  }`;
};

export const standardLog: ILog = (val, trace, options = defaultOptions) =>
  `[WFF-WEB-APP] ${formatMessage(val, trace, options)}`;

export const consoleLevels: IConsoleLevels = {
  error: (val, trace, options: ILoggerOptions = defaultOptions) => {
    const objOrArray =
      options?.printAsJson && (isObject(trace) || isArray(trace)) ? trace : "";
    console.error(
      `%c${standardLog(val, trace, {
        jsonSpacing: options?.jsonSpacing,
        jsonReplacer: options?.jsonReplacer,
      })}`,
      LOGGER_LEVEL_COLORS.ERROR,
      objOrArray
    );
  },
  warn: (val, trace, options: ILoggerOptions = defaultOptions) => {
    const objOrArray =
      options?.printAsJson && (isObject(trace) || isArray(trace)) ? trace : "";
    console.warn(
      `%c${standardLog(val, trace, {
        jsonSpacing: options?.jsonSpacing,
        jsonReplacer: options?.jsonReplacer,
      })}`,
      LOGGER_LEVEL_COLORS.WARN,
      objOrArray
    );
  },
  info: (val, trace, options: ILoggerOptions = defaultOptions) => {
    const objOrArray =
      options?.printAsJson && (isObject(trace) || isArray(trace)) ? trace : "";
    console.info(
      `%c${standardLog(val, trace, {
        jsonSpacing: options?.jsonSpacing,
        jsonReplacer: options?.jsonReplacer,
      })}`,
      LOGGER_LEVEL_COLORS.INFO,
      objOrArray
    );
  },
};

const logger: IConsoleLevels = {
  error: (val, trace, options: ILoggerOptions = defaultOptions) =>
    (isDev || logConfig?.error) && consoleLevels.error(val, trace, options),
  warn: (val, trace, options: ILoggerOptions = defaultOptions) =>
    (isDev || logConfig?.warn) && consoleLevels.warn(val, trace, options),
  info: (val, trace, options: ILoggerOptions = defaultOptions) =>
    (isDev || logConfig?.info) && consoleLevels.info(val, trace, options),
};

export default logger;
