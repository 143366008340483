import { getValueFromFullJsonPath } from "./dataStructure";
import { getStateList } from "./locale";

// NOTE:    Keep the cacheCategories definition here at the Top
//          ideally you should only need to import the globalCache; see applyCountryStateCache and then getStateName for a template on applying/using a new custom/or existing branch of cacheTree
export const cacheCategories: { [key: string]: string } = {
  INT_PROVINCES: "internationalProvinces",
};

type TCacheTree = any;
interface IGlobalCache {
  [key: string]: (...args: any) => Partial<TCacheTree>;
}
interface ICacheWarehouseProps {
  pathKeys: string[];
  cacheCategory: string;
  getCacheValue: () => any;
}

interface ICacheWarehouse extends ICacheWarehouseProps {
  invoke: () => Partial<TCacheTree>;
}

export const cacheTree: TCacheTree = {};

export const applyCache = (
  cacheCategory: string,
  pathKeys: string[], // the path to your cache value [under your cacheCategory]
  value: any
): TCacheTree => {
  if (!cacheTree[cacheCategory]) {
    cacheTree[cacheCategory] = {};
  }
  const currentContext = cacheTree[cacheCategory];
  let referencePointer = currentContext;
  let currentKey;
  for (let looper = 0; looper < pathKeys.length; looper++) {
    currentKey = pathKeys[looper];
    if (looper === pathKeys.length - 1) {
      if (referencePointer?.[currentKey] === undefined) {
        referencePointer[currentKey] = value;
      }
    } else {
      if (referencePointer?.[currentKey] === undefined) {
        referencePointer[currentKey] = {};
      }
    }
    referencePointer = referencePointer[currentKey];
  }
  return cacheTree;
};

export class CacheWarehouse implements ICacheWarehouse {
  pathKeys: string[];
  cacheCategory: string;
  getCacheValue: () => any;

  constructor(props: ICacheWarehouseProps) {
    this.pathKeys = props.pathKeys;
    this.cacheCategory = props.cacheCategory;
    this.getCacheValue = props.getCacheValue;
  }

  invoke(): Partial<TCacheTree> {
    if (cacheTree[this.cacheCategory] === undefined) {
      cacheTree[this.cacheCategory] = {};
    }
    const currentCacheValue = getValueFromFullJsonPath(
      cacheTree[this.cacheCategory],
      this.pathKeys
    );
    // check If the data-model association has already been assigned in cache, and if so then just return it [only the specified branch of the cacheTree defined by cacheCategory and]
    if (currentCacheValue !== undefined) {
      return cacheTree[this.cacheCategory];
    } else {
      const newCacheValue = this.getCacheValue();
      const totalCache = applyCache(
        this.cacheCategory,
        [...this.pathKeys],
        newCacheValue
      );
      return totalCache[this.cacheCategory];
    }
  }
}

// define your custom cache function as a branch of the globalCache object
export const globalCache: IGlobalCache = {
  // template for applying/using a new/existing branch of cache
  // example: this particular cache [applyCountryStateCache] will be defined under cacheTree as something like cacheTree: { [cacheCategories.INT_PROVINCES]: { "US": { "FL": "Florida" } } }; // value "Florida" is now cached
  applyCountryStateCache: (
    countryShort: string,
    stateShort: string
  ): Partial<TCacheTree> => {
    const cacheWarehouse = new CacheWarehouse({
      pathKeys: [countryShort, stateShort], // [array of keys defining the path of your data model]
      cacheCategory: cacheCategories.INT_PROVINCES, // define your cacheCategory [exclusively through the cacheCategories constant object]
      getCacheValue: () => {
        const CountryList = getStateList(countryShort);
        return CountryList.filter((items) => items.value === stateShort)[0]
          ?.text;
      },
    });
    return cacheWarehouse.invoke();
  },
};
