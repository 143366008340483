import { configureStore } from "@reduxjs/toolkit";
import menuBarSlice from "./MenuBarSlice";
import snackBarReducer from "./SnackBarSlice";
import taxDelayedCardReducer from "./TaxDelayedCardSlice";
import breadcrumbsSlice from "./breadcrumbsSlice";
import cartDrawerSlice from "./cartDrawerSlice";
import cartSlice from "./cartSlice";
import cartToastSlice from "./cartToastSlice";
import customerInfoSlice from "./customerInfoSlice";
import environmentSlice from "./environmentSlice";
import favoritesDataSlice from "./favoritesDataSlice";
import localeSlice from "./localeSlice";
import { thunkTimingMiddleware } from "./middlewares/thunkTimingMiddleware/thunkTimingMiddleware";
import orderHistorySlice from "./orderHistorySlice";
import orderSubmitSlice from "./orderSubmit.slice";
import paymentReducer from "./paymentSlice";
import previouslyOrderedSlice from "./previouslyOrderedSlice";
import productDataSlice from "./productDataSlice";
import removeItemsModalSlice from "./removeItemsModalSlice";
import searchSlice from "./searchSlice";
import yotpoSlice from "./yotpoSlice";
import zipcodeSlice from "./zipcodeSlice";

export const store = configureStore({
  reducer: {
    breadcrumbs: breadcrumbsSlice,
    cart: cartSlice,
    cartDrawer: cartDrawerSlice,
    cartToast: cartToastSlice,
    customerInfo: customerInfoSlice,
    environment: environmentSlice,
    locale: localeSlice,
    menuBar: menuBarSlice,
    orderHistory: orderHistorySlice,
    orderSubmit: orderSubmitSlice,
    payment: paymentReducer,
    productData: productDataSlice,
    removeItemsModal: removeItemsModalSlice,
    search: searchSlice,
    snackBar: snackBarReducer,
    taxDelayedCard: taxDelayedCardReducer,
    yotpo: yotpoSlice,
    zipcode: zipcodeSlice,
    favoritesData: favoritesDataSlice,
    previouslyOrdered: previouslyOrderedSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(thunkTimingMiddleware.middleware),
});

if (typeof window !== "undefined") {
  (window as any).store = store;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
