import { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { SCROLL_TIME_CA } from "@Constants/common";
import { STORAGE_KEYS, STORAGE_TYPES } from "@Constants/storage";
import { getItem } from "@Utils/browserStorage";

/* This custom hook will hold the previous 
page scroll position from top and when going back page , 
it will scroll back to the same position.
*/

// eslint-disable-next-line sonarjs/cognitive-complexity
export const usePreserveScrollCA = (isCanadaUser: boolean) => {
  const router = useRouter();
  const isSearchPLP = router?.pathname?.includes("search");
  const isPDP = router?.pathname?.includes("products");
  const isPaymentPage = router?.query?.step === "2";
  const scrollPositions = useRef<{ [url: string]: number }>({});
  const isBack = useRef(false);
  const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();

  if (isPaymentPage && isBrowser) {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 1000);
  }

  useEffect(() => {
    if (isCanadaUser && isBrowser) {
      router.beforePopState(() => {
        isBack.current = true;
        return true;
      });

      const onRouteChangeStart = () => {
        const url = window?.location?.pathname + window?.location?.search;
        if (!isBack?.current) {
          scrollPositions.current[url] = window.pageYOffset;
        }
        if ("scrollRestoration" in window.history) {
          window.history.scrollRestoration = "manual";
        } else {
          window.addEventListener("beforeunload", function () {
            window.scrollTo(0, 0);
          });
        }
      };

      const onRouteChangeComplete = (url: any) => {
        if (isPDP) {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        }
        const scrollPositionPLP = getItem(
          STORAGE_KEYS.SCROLL_POSITION_PLP_CA,
          STORAGE_TYPES.SESSION
        );

        const srcollFromTop = isSearchPLP
          ? parseInt(scrollPositionPLP)
          : scrollPositions.current[url];

        if (isBack?.current && scrollPositions?.current[url]) {
          setTimeout(() => {
            window.scroll({
              top: srcollFromTop,
              behavior: "auto",
            });
          }, SCROLL_TIME_CA);
        }

        isBack.current = false;
      };

      router.events.on("routeChangeStart", onRouteChangeStart);
      router.events.on("routeChangeComplete", onRouteChangeComplete);

      return () => {
        router.events.off("routeChangeStart", onRouteChangeStart);
        router.events.off("routeChangeComplete", onRouteChangeComplete);
      };
    }
  }, [router, isCanadaUser, isBrowser, isSearchPLP, isPDP]);
};
