import { debounce } from "lodash";
import React, { FC, useState, ChangeEvent, Dispatch, useCallback } from "react";
import { useRouter } from "next/router";
import { validateBillingAddressFields } from "@Components/forms/helpers/FormValidator";
import { USER_CONTACT_FORM_TYPE } from "@Constants/common";
import { INPUT_DEBOUNCE_DELAY } from "@Constants/debounceDelay";
import PhoneLookupService from "@Services/phoneLookupService/PhoneLookupService";
import { CMSImageType } from "@Types/Contentful";
import { convertToDollar } from "@Utils/currency";
import {
  getAlternativeCountryShort,
  forceCountryAbbreviation,
} from "@Utils/locale";
import { useCountryFromLocale } from "@wff/hooks/useCountry";
import {
  IOrderData,
  IUserContact,
  IDefaultUserContact,
  ISetUserContactHandler,
  IOrderSubmitData,
} from "@wff/interfaces";
import { useAppSelector } from "@wff/store/hooks";
import { contextFactory } from "../helpers/contextFactory";
import { userContactSourceSwitcher } from "./delivery/DeliveryProvider";

export const ADDRESS_SELECTED = {
  NO_ADDRESS_SELECTED: "NO_ADDRESS_SELECTED",
  ADDRESS_SELECTED_FOR_DELIVERY: "ADDRESS_SELECTED_FOR_DELIVERY",
  ADDRESS_SELECTED_FOR_PICKUP: "ADDRESS_SELECTED_FOR_PICKUP",
} as const;

export enum CheckoutSteps {
  auth,
  delivery,
  payment,
  orderConfirmation,
}

export interface IFieldSetter {
  (e: ChangeEvent<HTMLInputElement>): void;
}

export interface TAddressFieldHandler {
  (value: string | number, fieldKey: string, formType: string): void;
}

export interface TAddressFieldHandlerDefaultFieldKey {
  (value: string | number, formType: string): void;
}

export interface TIsPhoneAuthentic {
  [key: string]: boolean;
}

export type TAddressSelected = keyof typeof ADDRESS_SELECTED;

export interface ICheckoutForm {
  billingAddressFormIsValid: boolean;
  shippingAddressFormIsValid: boolean;
  pickUpAddressFormIsValid: boolean;
  isPhoneAuthentic: TIsPhoneAuthentic;
}
export interface IUserContactProps {
  setAddressFieldHandler: TAddressFieldHandler;
  setPhoneHandler: TAddressFieldHandlerDefaultFieldKey;
  billingAddressForm: IUserContact;
  shippingAddressForm: IUserContact;
  setUserContactHandler: ISetUserContactHandler;
  setUserContact?: never; // use setUserContactHandler instead
  setBillingAddressForm?: never; // use setUserContactHandler instead
  setShippingAddressForm?: never; // use setUserContactHandler instead
  debouncePhoneAuthentication: (
    phone: string,
    locale: string,
    formType: string
  ) => void;
  setIsPhoneAuthentic: (val: TIsPhoneAuthentic) => void;
  setCurrentStep?: never; // we should be using setCurrentStepHandler exclusively and only once in the codebase - where a useEffect will control the state directly based on the checkout?step query param in the route
}

export interface ICheckoutSteps {
  currentStep: CheckoutSteps;
  setCurrentStepHandler: (arg: CheckoutSteps, crashOverride: string) => void;
  showFooterActionButton: boolean;
  setShowFooterActionButton: (value: boolean) => void;
  setShowFooterActionButtonToggler: () => void;
}

export interface IDeliveryFormFieldState {
  deliveryInstructions: string;
  isAGift: boolean;
  giftMessage: string;
  showBillingAddressForms: boolean;
  setDeliveryInstructions: Dispatch<React.SetStateAction<string>>;
  setIsAGift: Dispatch<React.SetStateAction<boolean>>;
  setGiftMessage: Dispatch<React.SetStateAction<string>>;
  setShowBillingAddressForms: Dispatch<React.SetStateAction<boolean>>;
}

export interface CheckoutContextProps
  extends IUserContactProps,
    ICheckoutSteps,
    ICheckoutForm,
    IDeliveryFormFieldState {
  siteLogo?: CMSImageType;
  addressSelected: string;
  setAddressSelected: Dispatch<React.SetStateAction<TAddressSelected>>;
  setAddressSelectedHandler: (value: boolean, type: string) => void;
  resetAddressSelected: () => void;
  resetCheckoutContext: () => void;
  resetLoggedUserAddress: () => void;
  GTMorderCompleted: (response: any, userContact: any) => void;
  GTMinitiateCheckout: (data: any) => void;
}

export interface CheckoutProviderProps {
  siteLogo?: CMSImageType;
  initialStep?: CheckoutSteps;
  initialUserContact?: IUserContact;
  initialIsPhoneAuthentic?: TIsPhoneAuthentic;
  initialAddressSelected?: TAddressSelected;
}

const [useCheckout, CheckoutContext] = contextFactory<CheckoutContextProps>();

export { CheckoutContext, useCheckout };

export const defaultIsPhoneAuthentic = {
  [USER_CONTACT_FORM_TYPE.BILLING]: true,
  [USER_CONTACT_FORM_TYPE.SHIPPING]: true,
};

export const defaultUserContact: IDefaultUserContact = (countryShort) => {
  const countryAbbrev = countryShort
    ? countryShort
    : getAlternativeCountryShort();
  const selectedCountry = forceCountryAbbreviation(countryAbbrev); // in case backend sends us the full country name

  return {
    email: "",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: undefined,
    postalCode: "",
    country: selectedCountry?.value ?? "",
    dialCode: selectedCountry?.value ?? "",
    phone: "",
  };
};

export const CheckoutProvider: FC<CheckoutProviderProps> = ({
  children,
  initialUserContact,
  initialStep,
  siteLogo,
  initialAddressSelected = ADDRESS_SELECTED.NO_ADDRESS_SELECTED,
}) => {
  const { locale, customerInfo } = useAppSelector((state) => state);
  const isLoggedInUser = !!customerInfo?.email;

  const [currentStep, setCurrentStep] = useState(
    initialStep || CheckoutSteps.auth
  );
  const [showFooterActionButton, setShowFooterActionButton] = useState(false);

  const setCurrentStepHandler = useCallback(
    (value: CheckoutSteps, crashOverride: string) => {
      if (
        crashOverride ===
        "DO NOT CALL THIS FUNCTION ANYWHERE ELSE. Use the checkout?step query param process"
      ) {
        setCurrentStep(value);
      }
    },
    []
  );

  const [billingAddressForm, setBillingAddressForm] = useState(
    initialUserContact || {
      ...defaultUserContact(locale.countryShort),
    }
  );
  const [shippingAddressForm, setShippingAddressForm] = useState(
    initialUserContact || {
      ...defaultUserContact(locale.countryShort),
    }
  );

  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [isAGift, setIsAGift] = useState(false);
  const [giftMessage, setGiftMessage] = useState("");

  const [isPhoneAuthentic, setIsPhoneAuthentic] = useState(
    defaultIsPhoneAuthentic
  );
  const [addressSelected, setAddressSelected] = useState<TAddressSelected>(
    initialAddressSelected
  );
  const [showBillingAddressForms, setShowBillingAddressForms] = useState(true);

  const router = useRouter();

  const setUserContactHandler: ISetUserContactHandler = useCallback(
    (values, formType) => {
      switch (formType) {
        case USER_CONTACT_FORM_TYPE.BILLING:
          setBillingAddressForm((currentBillingAddressState) => {
            return {
              ...currentBillingAddressState,
              ...(values as IUserContact),
            };
          });
          break;
        case USER_CONTACT_FORM_TYPE.SHIPPING:
        default:
          setShippingAddressForm((currentShippingAddressState) => {
            return {
              ...currentShippingAddressState,
              ...(values as IUserContact),
            };
          });
          break;
      }
    },
    []
  );

  const setAddressFieldHandler: TAddressFieldHandler = useCallback(
    (value, fieldKey, formType) => {
      setUserContactHandler(
        {
          [fieldKey]: value ?? "",
        } as Partial<IUserContact>,
        formType
      );
    },
    [setUserContactHandler]
  );

  const setAddressSelectedHandler = (value: boolean, type = "delivery") => {
    const truthyValue =
      type === "delivery"
        ? ADDRESS_SELECTED.ADDRESS_SELECTED_FOR_DELIVERY
        : ADDRESS_SELECTED.ADDRESS_SELECTED_FOR_PICKUP;
    setAddressSelected(
      value ? truthyValue : ADDRESS_SELECTED.NO_ADDRESS_SELECTED
    );
  };

  const resetAddressSelected = useCallback(() => {
    setAddressSelected(ADDRESS_SELECTED.NO_ADDRESS_SELECTED);
  }, [setAddressSelected]);

  const resetLoggedUserAddress = useCallback(() => {
    setCurrentStep(initialStep || CheckoutSteps.auth);
    setShowFooterActionButton(false);
    setDeliveryInstructions("");
    setIsAGift(false);
    setGiftMessage("");
    setIsPhoneAuthentic(defaultIsPhoneAuthentic);
    resetAddressSelected();
    setUserContactHandler(
      {
        ...defaultUserContact(locale.countryShort),
      },
      USER_CONTACT_FORM_TYPE.BILLING
    );
    setUserContactHandler(
      {
        ...defaultUserContact(locale.countryShort),
      },
      USER_CONTACT_FORM_TYPE.SHIPPING
    );
    setShowBillingAddressForms(true);
  }, [
    initialStep,
    locale.countryShort,
    resetAddressSelected,
    setUserContactHandler,
  ]);

  const resetCheckoutContext = useCallback(() => {
    // Note: we are currently Not resetting billingAddress or shippingAddress
    setCurrentStep(initialStep || CheckoutSteps.auth);
    setShowFooterActionButton(false);
    setDeliveryInstructions("");
    setIsAGift(false);
    setGiftMessage("");
    setIsPhoneAuthentic(defaultIsPhoneAuthentic);
    resetAddressSelected();
    setShowBillingAddressForms(true);
  }, [
    initialStep,
    setCurrentStep,
    setShowFooterActionButton,
    setDeliveryInstructions,
    setIsAGift,
    setGiftMessage,
    setIsPhoneAuthentic,
    resetAddressSelected,
    setShowBillingAddressForms,
  ]);

  useCountryFromLocale({
    locale,
    setUserContactHandler,
  });

  const debouncePhoneAuthentication = debounce(
    async (phone, locale, formType) => {
      if (phone?.length === 10) {
        // setting numbers in phone to validate to 10 since phone's country code is being added in the PhoneLookupService.
        await PhoneLookupService.invoke({
          phoneNumber: phone,
          countryCode: locale,
        })
          .then(() => setIsPhoneAuthentic({ [formType]: true }))
          .catch(() => setIsPhoneAuthentic({ [formType]: false }));
      } else {
        setIsPhoneAuthentic({ [formType]: false });
      }
    },
    INPUT_DEBOUNCE_DELAY
  );

  const setPhoneHandler: TAddressFieldHandlerDefaultFieldKey = (
    value,
    formType
  ) => {
    const allowOnlyNumber = (value: string) => {
      // TODO from Jared: this could be abstracted into the utils/sanitization.ts file
      return value.replace(/\D/g, "");
    };
    const phone = allowOnlyNumber(value as string) || "";
    const defaultLocale = new Intl.Locale(router?.locale || "en-US");
    const userContact: IUserContact = userContactSourceSwitcher({
      billingAddressForm,
      shippingAddressForm,
      formType,
    });

    const countryCode = userContact.dialCode
      ? userContact.dialCode
      : defaultLocale.region;
    setUserContactHandler({ phone }, formType);
    debouncePhoneAuthentication(phone, countryCode, formType);
  };

  const setShowFooterActionButtonToggler = () => {
    // TODO write unit test
    setShowFooterActionButton(!showFooterActionButton);
  };

  const manipulateLineItemsArray = ({
    lineItems,
    discountCode,
  }: IOrderSubmitData) => {
    return lineItems?.map((item) => {
      return {
        item_id: item.sku ?? "",
        item_name: item.name ?? "",
        coupon: discountCode ?? "",
        price: item.perUnitPriceText ?? "",
        quantity: item.quantity ?? "",
      };
    });
  };

  const GTMorderCompleted = (
    {
      lineItems,
      taxTotal,
      totalPrice,
      orderNumber,
      currencyType,
      shippingTotal,
      paymentMethod,
      discountCode,
    }: IOrderData,
    userContact: IUserContact
  ) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: orderNumber,
            revenue: totalPrice,
            tax: taxTotal,
            shipping: shippingTotal,
            currency: currencyType,
            type: "Product",
          },
          products: lineItems,
          user_data: userContact,
          content_type: "product",
          contents: lineItems,
          currency: currencyType,
          value: totalPrice,
          payment_type: paymentMethod,
        },
      },
    });

    // Updated dataLayer as per CE-1452
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: orderNumber,
        value: convertToDollar(totalPrice),
        tax: convertToDollar(taxTotal),
        shipping: convertToDollar(shippingTotal),
        currency: currencyType,
        coupon: discountCode ?? "",
        items: manipulateLineItemsArray({ lineItems, discountCode }),
      },
    });
  };
  const GTMinitiateCheckout = (data: any) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "InitiateCheckout",
      initiateCheckout: {
        content_type: "product",
        contents: data.lineItems,
        currency: data.currencyType,
        value: data.totalPrice,
      },
    });

    // Updated dataLayer as per CE-1452
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: data.currencyType,
        value: convertToDollar(data.totalPrice),
        coupon: "",
        items: manipulateLineItemsArray({
          lineItems: data.lineItems,
          discountCode: "",
        }),
      },
    });
  };

  // TODO: due to temporary CORS issue, I removed && isPhoneAuthentic[USER_CONTACT_FORM_TYPE.SHIPPING] from the tail end of the shippingAddressFormIsValid block below
  return (
    <CheckoutContext.Provider
      value={{
        siteLogo,
        setPhoneHandler,
        billingAddressForm,
        shippingAddressForm,
        setAddressFieldHandler,
        billingAddressFormIsValid: validateBillingAddressFields(
          billingAddressForm,
          "billing",
          {
            ignoreKeys: ["email"],
            countryShortOverride:
              billingAddressForm.country || getAlternativeCountryShort(),
          }
        ),
        shippingAddressFormIsValid: validateBillingAddressFields(
          shippingAddressForm,
          "billing",
          {
            ignoreKeys: !isLoggedInUser ? [] : ["email"],
            countryShortOverride:
              shippingAddressForm.country || getAlternativeCountryShort(),
          }
        ),
        pickUpAddressFormIsValid: validateBillingAddressFields(
          shippingAddressForm,
          "pickup",
          {
            ignoreKeys: !isLoggedInUser ? [] : ["email"],
          }
        ),
        currentStep,
        setUserContactHandler,
        setCurrentStepHandler,
        showFooterActionButton,
        setShowFooterActionButton,
        setShowFooterActionButtonToggler,
        isPhoneAuthentic,
        addressSelected,
        setAddressSelected,
        setAddressSelectedHandler,
        resetAddressSelected,
        debouncePhoneAuthentication,
        setIsPhoneAuthentic,
        deliveryInstructions,
        isAGift,
        giftMessage,
        setDeliveryInstructions,
        setIsAGift,
        setGiftMessage,
        resetCheckoutContext,
        resetLoggedUserAddress,
        GTMorderCompleted,
        GTMinitiateCheckout,
        showBillingAddressForms,
        setShowBillingAddressForms,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
