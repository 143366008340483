import moment from "moment";
import { createSlice } from "@reduxjs/toolkit";
import { MAX_REQUEST_RETRIES, REQUEST_RETRY_DELAY } from "@Constants/common";
import {
  GENERIC_ERR_MSG,
  GENERIC_EVENT_ERR_HEADING,
} from "@Constants/snackbar";
import logger from "@Utils/Logger";
import { nextAppEnv } from "@Utils/environment";
import { axiosErrorHandler } from "@Utils/snackbarHandlers";
import { getPaymentMethodsAPI } from "@wff/api/checkoutApi";

// Type for storedPaymentMethods in /paymentMethods response
type TSTORED_PAYMENT_METHOD = {
  brand?: string;
  expiryMonth: string;
  expiryYear: string;
  holderName?: string;
  id?: string;
  lastFour: string;
  name?: string;
  networkTxReference?: string;
  supportedShopperInteractions?: string[];
  type?: string;
};

export const initialState = {
  session: { sessionData: "", id: "" },
  adyenFormInstance: null, // The instance of Adyen's form component
  adyenFormData: { paymentMethod: {} },
  paymentResult: "", // checks if CC payment was authorized
  isValidPaymentMethod: false, // checks if CC info is valid
  config: {
    paymentMethodsResponse: {
      paymentMethods: [],
      storedPaymentMethods: [] as TSTORED_PAYMENT_METHOD[],
    },
    paymentMethodsResponsePaypal: { paymentMethods: [] },
    clientKey: nextAppEnv.adyen.clientKey,
    locale: "",
    environment: nextAppEnv.adyen.environment,
    analytics: {
      enabled: false, // Set to false to not send analytics data to Adyen.
    },
    paymentMethodsConfiguration: {
      storedCard: {
        hideCVC: true, // hide CVC if it's a stored card
      },
      card: {
        hasHolderName: true, // show holder name input
        holderNameRequired: true, // forces holder name input
        hideCVC: false, // Change this to true to hide the CVC field for stored cards
        name: "",
        positionHolderNameOnTop: false,
      },
      paypal: {
        environment: nextAppEnv.paypal.environment,
        showPayButton: true,
        blockPayPalPayLaterButton: true,
      },
      applepay: {
        environment: nextAppEnv.applepay.environment,
        merchantName: "WildFork",
        merchantIdentifier: "merchant.wildfork.web",
        countryCode: "CA",
        showPayButton: true,
      },
    },
    showPayButton: false,
  },
};

// validates CC expiration dates and removes expired
export const filterExpiredCards = (
  paymentMethodsArr: TSTORED_PAYMENT_METHOD[]
) => {
  const currentMonth = moment(new Date()).get("month") + 1;
  const currentYear = moment(new Date()).get("year") - 2000;
  const validPaymentsArr: TSTORED_PAYMENT_METHOD[] = [];

  paymentMethodsArr.forEach((el) => {
    // checks each CC expiration date and adds valid cards only.
    const numExpiryMonth = parseInt(el.expiryMonth);
    const numExpiryYear = parseInt(el.expiryYear);
    if (
      numExpiryYear > currentYear ||
      (numExpiryYear == currentYear && numExpiryMonth >= currentMonth)
    ) {
      validPaymentsArr.push(el);
    }
  });
  return validPaymentsArr;
};

export const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    paymentMethods: (state, action) => {
      const { paymentMethods, storedPaymentMethods } =
        action.payload?.data ?? initialState.config.paymentMethodsResponse; // fallBack to initial state
      const filteredStoredPaymentMethods =
        filterExpiredCards(storedPaymentMethods); // filters expired CCs
      state.config.paymentMethodsResponse = {
        paymentMethods,
        storedPaymentMethods: filteredStoredPaymentMethods,
      };
    },
    paypalPaymentMethods: (state, action) => {
      const paypalPaymentMethods = action.payload?.data?.paymentMethods;
      state.config.paymentMethodsResponsePaypal = {
        paymentMethods: paypalPaymentMethods,
      };
    },
    paymentSession: (state, action) => {
      const { sessionData, sessionId } = action.payload;
      state.session = { sessionData, id: sessionId };
    },
    clearPaymentSession: (state) => {
      state.session = { sessionData: "", id: "" };
      state.adyenFormInstance = null;
    },
    setAdyenFormInstance: (state, action) => {
      state.adyenFormInstance = action.payload;
    },
    setIsValidPaymentMethod: (state, action) => {
      state.isValidPaymentMethod = action.payload;
    },
    setadyenFormData: (state, action) => {
      state.adyenFormData = action.payload;
    },
    setPaymentResult: (state, action) => {
      state.paymentResult = action.payload;
    },
    setCurrentLocale: (state, action) => {
      state.config.locale = action.payload;
    },
  },
});

export const {
  paymentMethods,
  paypalPaymentMethods,
  paymentSession,
  clearPaymentSession,
  setAdyenFormInstance,
  setIsValidPaymentMethod,
  setadyenFormData,
  setPaymentResult,
  setCurrentLocale,
} = slice.actions;

export const getPaymentMethods =
  (
    currency: string,
    country: string,
    amount: number,
    language: string,
    requestRetryCount = 0
  ) =>
  async (dispatch: any) => {
    try {
      const response = await getPaymentMethodsAPI({
        currency,
        country,
        amount,
        language,
      });
      dispatch(paymentMethods(response));
    } catch (error: { response: { status: number } } | any) {
      if (
        error?.response &&
        error.response?.status === 500 &&
        requestRetryCount < MAX_REQUEST_RETRIES
      ) {
        setTimeout(() => {
          logger.info("paymentSlice.ts getPaymentMethods retry request", {
            currency,
            country,
            amount,
            language,
            requestRetryCount,
          });
          dispatch(
            getPaymentMethods(
              currency,
              country,
              amount,
              language,
              requestRetryCount + 1
            )
          );
        }, REQUEST_RETRY_DELAY);
      } else {
        axiosErrorHandler({
          error,
          dispatch,
          fallbackSnackbar: {
            heading: GENERIC_EVENT_ERR_HEADING.REQUEST_FAILED(),
            message: GENERIC_ERR_MSG.ERROR(),
          },
        });
      }
    }
  };

export const getClearPaymentSession = () => async (dispatch: any) => {
  dispatch(clearPaymentSession());
  dispatch(setPaymentResult(""));
};
export const saveAdyenFormInstance =
  (instance: any) => async (dispatch: any) => {
    dispatch(setAdyenFormInstance(instance));
  };
export const saveIsValidPaymentMethod =
  (valid: boolean) => async (dispatch: any) => {
    dispatch(setIsValidPaymentMethod(valid));
  };
export const saveAdyenFormData = (data: {}) => async (dispatch: any) => {
  dispatch(setadyenFormData(data));
};
export const savePaymentResult =
  (paymentResult: string) => async (dispatch: any) => {
    dispatch(setPaymentResult(paymentResult));
  };

export const saveCurrentLocale =
  (currentLocale: string) => async (dispatch: any) => {
    dispatch(setCurrentLocale(currentLocale));
  };

export default slice.reducer;
