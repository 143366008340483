import { createSlice } from "@reduxjs/toolkit";
import { initialStateAvailableFilters } from "@Components/search/utils/algoliaData";
import { ISearchSliceInitialState } from "@Types/search-page";

export const initialState: ISearchSliceInitialState = {
  hits: [],
  productHits: [],
  articleHits: [],
  recipesHits: [],
  availableFilters: initialStateAvailableFilters,
};

export const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setAvailableFilters: (state, action) => {
      state.availableFilters = action.payload;
    },
    setSearchArticlesHits: (state, action) => {
      state.articleHits = action.payload;
    },
    setSearchHits: (state, action) => {
      state.hits = action.payload;
    },
    setSearchProductHits: (state, action) => {
      state.productHits = action.payload;
    },
  },
});

export const {
  setAvailableFilters,
  setSearchArticlesHits,
  setSearchHits,
  setSearchProductHits,
} = slice.actions;

export default slice.reducer;
