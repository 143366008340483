import { createSlice } from "@reduxjs/toolkit";
import { ICartDrawerState } from "../types/cart-drawer";
import { RootState } from ".";

export const initialState: ICartDrawerState = {
  open: false,
  isVisible: true,
};

export const cartDrawerSlice = createSlice({
  name: "cartDrawer",
  initialState,
  reducers: {
    toggleCartDrawer(state) {
      return { ...state, open: !state.open };
    },
    setHeaderPosition(state, action) {
      return { ...state, isVisible: action.payload };
    },
  },
});
export const cartDrawerActions = cartDrawerSlice.actions;

export const cartDrawerSelector = (state: RootState) => state.cartDrawer;

export default cartDrawerSlice.reducer;
