import { createSlice } from "@reduxjs/toolkit";
import { SnackBarType, SnackBarVariant, SnackBarState } from "@Types/SnackBar";

export const initialState: SnackBarState = {
  open: false,
  snackPack: [],
  messageInfo: undefined,
};

export const slice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    // changes the status of the SnackBar
    setSnackBarOpen: (state, action) => {
      state.open = action.payload;
    },
    // Adds a message to the queue
    setSnackBarMessage: (state, action) => {
      const { message, title, type, variant, timeout } = action.payload;
      state.snackPack = [
        ...state.snackPack,
        {
          message: message,
          key: new Date().getTime(),
          title: title,
          type: type,
          variant: variant,
          timeout: timeout,
        },
      ];
    },
    // Removes a message from the queue
    removeSnackBarMessage: (state) => {
      const newSnackPack = [...state.snackPack];
      newSnackPack.shift();
      state.snackPack = newSnackPack;
    },
  },
});

export const { setSnackBarOpen, setSnackBarMessage, removeSnackBarMessage } =
  slice.actions;

export const triggerSnackBar =
  ({
    message,
    title,
    type,
    variant,
    timeout,
  }: {
    message: string;
    title?: string;
    type?: SnackBarType;
    variant?: SnackBarVariant;
    timeout?: number;
  }) =>
  async (dispatch: any) => {
    await dispatch(
      setSnackBarMessage({
        message,
        title: title ? title : undefined,
        type: type ? type : "error",
        variant: variant ? variant : "filled",
        timeout: timeout ? timeout : 4000,
      })
    );
  };

export default slice.reducer;
