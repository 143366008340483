import algoliasearch from "algoliasearch";
import algoliasearchHelper from "algoliasearch-helper";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  reduceTooltipData,
  algoliaToCTTransformer,
} from "@Components/search/utils/searchUtils";
import { ICTProduct } from "@Types/product";
import { getPreviouslyOrders } from "@wff/api/previouslyOrderedApi";
import {
  algoliaAppId,
  algoliaAPIKey,
  algoliaIndex,
} from "@wff/hooks/algoliaData";
import { IPreviouslyOrderedData } from "@wff/interfaces";
import { SKUItem } from "../interfaces/previously-ordered";
import { RootState } from ".";

export const initialState: IPreviouslyOrderedData = {
  previouslyOrderedList: undefined,
};

const filterSkuItems = (skuItems: SKUItem[]) => {
  return skuItems.filter(
    (singleSkuItem) => singleSkuItem.sku !== "MEMBERSHIP_YEARLY"
  );
};

const getProductList = async (skuItems: SKUItem[]): Promise<ICTProduct[]> => {
  const algolia = algoliasearch(algoliaAppId, algoliaAPIKey);
  const filteredSkuItems = filterSkuItems(skuItems);
  const allSKUs = filteredSkuItems.map((singleSkuItem: SKUItem) => {
    return "sku:" + singleSkuItem.sku;
  });
  const helper = algoliasearchHelper(algolia, algoliaIndex, {
    facets: ["*"],
    facetFilters: [allSKUs.reverse()],
    disjunctiveFacets: ["name", "category", "description", "sku", "attibutes"],
    hitsPerPage: 500,
    maxValuesPerFacet: 50,
    getRankingInfo: true,
    typoTolerance: true,
  });
  const tooltipData = reduceTooltipData("Title", "Description", "Example");
  const algoliaDataPromise: Promise<ICTProduct[]> = new Promise((resolve) => {
    helper.on("result", (algoliaResults) => {
      const productHits = algoliaResults?.results?.hits as any;
      const productList = algoliaToCTTransformer(
        productHits,
        "en-CA",
        tooltipData
      );
      resolve(productList);
    });
  });
  helper.search();
  return algoliaDataPromise;
};

const getSortedProductList = (
  sortedSkuItems: SKUItem[],
  productList: ICTProduct[]
): ICTProduct[] => {
  const productListWithSKUItems: Array<[string, ICTProduct]> = [];
  productList.forEach((product) => {
    if (!product.ctData.masterData?.current.masterVariant?.sku) {
      return;
    }
    productListWithSKUItems.push([
      product.ctData.masterData.current.masterVariant.sku,
      product,
    ]);
  });
  const productListMap = new Map(productListWithSKUItems);
  const sortedProductList: ICTProduct[] = [];
  sortedSkuItems.forEach((skuItem: SKUItem) => {
    const product = productListMap.get(skuItem.sku);
    product && sortedProductList.push(product);
  });
  return sortedProductList;
};

export const fetchPreviouslyOrdered = createAsyncThunk(
  "order/previously-ordered",
  async (isLoggedIn: boolean) => {
    if (!isLoggedIn) {
      throw "User is Not Logged in";
    }
    const { data } = await getPreviouslyOrders();
    const productList = await getProductList(data);
    return getSortedProductList(data, productList);
  }
);

export const PreviouslyOrderedSlice = createSlice({
  name: "previouslyOrdered",
  initialState,
  reducers: {
    setPreviouslyOrderedData(state, action) {
      state.previouslyOrderedList = action?.payload;
    },
    resetPreviouslyOrderedData(state) {
      state.previouslyOrderedList = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPreviouslyOrdered.fulfilled, (state, action) => {
      state.previouslyOrderedList = action.payload;
    });
    builder.addCase(fetchPreviouslyOrdered.rejected, (state) => {
      state.previouslyOrderedList = undefined;
    });
  },
});
export const { setPreviouslyOrderedData, resetPreviouslyOrderedData } =
  PreviouslyOrderedSlice.actions;

export const PreviouslyOrderedSelector = (state: RootState) =>
  state.previouslyOrdered;

export default PreviouslyOrderedSlice.reducer;
