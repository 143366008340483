import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuBarState } from "@Types/menu-bar";
import { RootState } from ".";

export const initialState: IMenuBarState = {
  currentSlide: 0,
};

export const menuBarSlice = createSlice({
  name: "menuBar",
  initialState,
  reducers: {
    setCurrentSlide(state, action: PayloadAction<number>) {
      return { ...state, currentSlide: action.payload };
    },
  },
});
export const menuBarActions = menuBarSlice.actions;

export const menuBarSelector = (state: RootState) => state.menuBar;

export default menuBarSlice.reducer;
