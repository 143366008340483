import { nextAppEnv } from "@Utils/environment";
import api from "./api";
import RestEndpoints from "./constants/RestEndpoints";

export interface TDyidServerCookiePayload {
  dyid: string;
}

export const createDyidServerCookie = (payload: TDyidServerCookiePayload) => {
  const APIKey = nextAppEnv.dynamicYield.apiSubscriptionKey;
  const headers = {
    "ocp-apim-subscription-key": APIKey,
    "Content-Type": "application/json",
  };

  return api.post<TDyidServerCookiePayload>(
    RestEndpoints.DYNAMICYIELD.DYID_SERVER_COOKIE,
    payload,
    { headers }
  );
};
