import { useEffect, useRef, useState } from "react";
import styles from "./Icon.module.scss";

interface IconProps {
  iconName: string;
  className: string;
  id: string;
  onClick?: () => void;
  disabled: boolean;
}

/**
 * Icon Component
 * @param {string} className - Classname to overrode styles
 * @param {string} id - id to place on icon
 * @param {function} onClick - Callback function on icon click
 * @param {string} iconName - Name to identify and load icon from svg folder (required)
 * @param {boolean} disabled - Flag to disable icon
 * @returns {function}
 */
const Icon = (props: IconProps) => {
  const { onClick, iconName, id, className, disabled, ...restProps } = props;
  const mountedRef = useRef(false);
  const [iconModule, setIconModule] = useState<{ default: any }>();

  useEffect(() => {
    if (!mountedRef.current) {
      import(
        /* webpackMode: "lazy-once" */
        /* webpackPrefetch: true */
        `!!@svgr/webpack!@wff/assets/svg/icons/${iconName}.svg`
      )
        .then((module) => {
          mountedRef.current = true;
          return module && module.default && setIconModule(module);
        })
        .catch(
          /* istanbul ignore next */ () => {
            /* istanbul ignore next */
            throw `Icon "${iconName}" not found`;
          }
        );
    }

    return () => {
      mountedRef.current = false;
    };
  }, [iconName]);

  if (!iconModule) return null;

  const IconComponent = iconModule.default;
  const classes = [
    styles.icon,
    onClick ? styles["icon-link"] : "",
    disabled ? styles["icon-disabled"] : "",
    className,
  ].join(" ");

  return (
    <IconComponent
      id={id}
      className={classes}
      {...(!disabled && onClick && { onClick: onClick })}
      {...restProps}
    />
  );
};

Icon.defaultProps = {
  className: "",
  id: null,
  onClick: null,
  disabled: false,
};

export default Icon;
