import { createSlice } from "@reduxjs/toolkit";
import { IProductState } from "@Types/product";
import { RootState } from ".";

export const initialState: IProductState = {
  slug: "",
  title: "",
  description: "",
  images: [],
  weight: null,
  price: null,
};

export const productDataSlice = createSlice({
  name: "productData",
  initialState,
  reducers: {
    // Setters:
    setProductData(state, action) {
      const { slug, title, description, images, weight, price } =
        action.payload;
      return {
        ...state,
        slug: slug,
        title: title,
        description: description,
        images: images,
        weight: weight,
        price: price,
      };
    },
    // Getters:
    getProductData(state) {
      return state;
    },
  },
});
export const productDataActions = productDataSlice.actions;

export const productDataSelector = (state: RootState) => state.productData;

export default productDataSlice.reducer;
