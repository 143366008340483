import { createSlice, current } from "@reduxjs/toolkit";
import { IFavoritesData } from "@wff/interfaces";
import { RootState } from ".";

export const initialState: IFavoritesData = {
  favoritesList: [],
  slugForLastVisitedProduct: "", // required to return customer to product's page after login
};

export const FavoritesDataSlice = createSlice({
  name: "favoritesData",
  initialState,
  reducers: {
    setFavoritesData(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    resetFavoritesData() {
      return { ...initialState };
    },
    addFavorite(state, action: { payload: any }) {
      // accepts the product's SKU as String and stores it
      const newFavoritesList = [...state.favoritesList];
      newFavoritesList.push(action.payload);
      return {
        ...state,
        favoritesList: newFavoritesList,
      };
    },
    deleteFavorite(state, action: { payload: string }) {
      // accepts the product's SKU as String and removes it from the list
      const SKU = action.payload;
      const prevFavoritesList: any[] = [...current(state.favoritesList)];
      const newFavoritesList = prevFavoritesList.filter(
        (item) => item?.ctData?.masterData?.current?.masterVariant?.sku !== SKU
      );
      return {
        ...state,
        favoritesList: newFavoritesList,
      };
    },
    saveSlugForLastVisitedProduct(state, action: { payload: string }) {
      // accepts the slug's string and stores it
      return {
        ...state,
        slugForLastVisitedProduct: action.payload,
      };
    },
  },
});

export const {
  setFavoritesData,
  resetFavoritesData,
  addFavorite,
  deleteFavorite,
  saveSlugForLastVisitedProduct,
} = FavoritesDataSlice.actions;

export const FavoritesDataSelector = (state: RootState) => state.favoritesData;

export default FavoritesDataSlice.reducer;
