import {
  forwardRef,
  useState,
  useEffect,
  Fragment,
  SyntheticEvent,
  useCallback,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { SnackbarMessage } from "@Types/SnackBar";
import {
  setSnackBarOpen,
  removeSnackBarMessage,
} from "@wff/store/SnackBarSlice";
import { useAppSelector, useAppDispatch } from "@wff/store/hooks";
import classes from "./SnackBar.module.scss";

export const SNACK_BACK_ID = "snackbar-test-id";

/* 
  To open Snackbar the following code is required:

  //Begin code:
    import {SnackBarType, SnackBarVariant, TriggerSnackBar} from "@Types/SnackBar"
    import {triggerSnackBar} from '@wff/store/SnackBarSlice';
    import { useAppDispatch } from "@wff/store/hooks";
    
    // then inside component: 
    const dispatch = useAppDispatch();
    const openSnackbar = ({message, title, type, variant, timeout}: TriggerSnackBar) => {
      dispatch(triggerSnackBar({
        message,
        title,
        type: type as SnackBarType,
        variant: variant as SnackBarVariant,
        timeout,
      }))
    }
  // End code;
*/

export default function SnackBar() {
  const dispatch = useAppDispatch();
  const { snackPack, open } = useAppSelector((state) => state.snackBar);

  const setOpen = useCallback(
    (open: boolean) => dispatch(setSnackBarOpen(open)),
    [dispatch]
  );

  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(
    undefined
  );

  const duration = messageInfo?.timeout ? messageInfo.timeout : undefined;

  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return (
      <MuiAlert
        elevation={1}
        ref={ref}
        variant={messageInfo?.variant}
        {...props}
      />
    );
  });

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });

      // Then remove it from queue
      dispatch(removeSnackBarMessage());
      setOpen(true);
    } else if (snackPack.length && open) {
      // await an active snack to close when a new one is added
      duration &&
        setTimeout(() => {
          return;
        }, snackPack.length * duration);
    }
  }, [dispatch, duration, setOpen, snackPack, messageInfo, open]);

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (event && reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <div>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        data-testid={SNACK_BACK_ID}
      >
        <Alert
          severity={messageInfo?.type}
          className={`${classes["container"] + " "}`}
          action={
            <Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Fragment>
          }
        >
          {messageInfo?.title && <AlertTitle>{messageInfo.title}</AlertTitle>}
          {messageInfo ? messageInfo.message : ""}
        </Alert>
      </Snackbar>
    </div>
  );
}
