import { useEffect } from "react";
import { useRouter } from "next/router";
import localeDetails from "@Constants/localeDetails.json";
import { findIndexOfLocaleInJsonFile } from "@Utils/locale";
import { useAppDispatch, useAppSelector } from "@wff/store/hooks";
import { localeActions } from "@wff/store/localeSlice";

export const useLocale = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const localeSlice = useAppSelector((state) => state.locale);
  const languageCode = localeSlice?.languageCode;

  useEffect(() => {
    const resetLocaleStateOnPathParamDetection = (toRoute: string) => {
      // removing the leading slash /
      const toRouteLocale = toRoute.split("/")[1];
      const indexOfNewLocaleInJsonFile =
        findIndexOfLocaleInJsonFile(toRouteLocale);
      // before doing anything, confirm locale has been initialized in redux, and confirm that the new locale is supported in the json file
      if (languageCode && indexOfNewLocaleInJsonFile >= 0) {
        const newLanguageCode =
          localeDetails[indexOfNewLocaleInJsonFile].languageCode; // assuring that newLanguageCode has proper case against the json file
        // if the new route path and html lang do not align then synchronize them now
        if (document.documentElement.lang !== newLanguageCode) {
          document.documentElement.lang = newLanguageCode;
        }
        // ignore home page [when locale path is not present and thus an empty string] since another redirect takes care of that, and both the path and redux should Hopefully already be in sync in that case
        // in addition, only update state if the locale path param has actually changed
        if (newLanguageCode !== "" && newLanguageCode !== languageCode) {
          dispatch(localeActions.resetLocale(newLanguageCode)); // this should only be called one time in the app, if you need to change the locale do it by pushing to the locale path such as /fr-CA/
        }
      }
    };
    router.events.on("routeChangeStart", resetLocaleStateOnPathParamDetection);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off(
        "routeChangeStart",
        resetLocaleStateOnPathParamDetection
      );
    };
  }, [languageCode, dispatch, router.events]);
};
