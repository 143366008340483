import moment from "moment";
import { Moment } from "moment";

// mapping for moment().day()
export interface IWeekdayMomentMap {
  [key: number]: string; // 0 => 'Sunday'
}

// mapping for moment().month()
export interface IMonthMomentMap {
  [key: number]: string; // 0 => 'January'
}

// mapping for moment().day()
export const weekdayMomentMap: IWeekdayMomentMap = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

// mapping for moment().month()
export const monthMomentMap: IMonthMomentMap = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

// mapping french locale for moment().day()
export const weekdayMomentLocaleFrenchMap: IWeekdayMomentMap = {
  0: "Dimanche",
  1: "Lundi",
  2: "Mardi",
  3: "Mercredi",
  4: "Jeudi",
  5: "Vendredi",
  6: "Samedi",
};

// mapping french locale for moment().month()
export const monthMomentLocaleFrenchMap: IMonthMomentMap = {
  0: "Janvier",
  1: "Février",
  2: "Mars",
  3: "Avril",
  4: "Mai",
  5: "Juin",
  6: "Juillet",
  7: "Août",
  8: "Septembre",
  9: "Octobre",
  10: "Novembre",
  11: "Décembre",
};

export const getWeekOrMonthByLocale = (
  languageCode: string,
  key: number,
  type: string
) => {
  if (languageCode === "fr-CA") {
    return type === "day"
      ? weekdayMomentLocaleFrenchMap[key as number]
      : monthMomentLocaleFrenchMap[key as number];
  }

  return type === "day"
    ? weekdayMomentMap[key as number]
    : monthMomentMap[key as number];
};

export const isDayInTheFuture = (today: Moment, date: Moment) => {
  return moment(today).isBefore(date, "day");
};

export const getTimeFormatByLocale = (languageCode: string) => {
  return languageCode === "fr-CA" ? "H A" : "h A";
};

export const formatDate = (
  date: string,
  options: Intl.DateTimeFormatOptions | undefined,
  languageCode = "en-US"
) => {
  const d = new Date(date);
  return d.toLocaleDateString(languageCode, options);
};
