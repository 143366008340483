import { createSlice } from "@reduxjs/toolkit";
import { IOrderHistoryData } from "@wff/interfaces";
import { RootState } from ".";

export const initialStateAddress = {
  id: "",
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
  phone: "",
};

export const initialStateOrder: IOrderHistoryData = {
  orderNumber: "",
  lineItems: [],
  shippingAddress: initialStateAddress,
  billingAddress: initialStateAddress,
  tipReceived: 0,
  shippingTotal: 0,
  taxTotal: 0,
  subTotal: 0,
  currencyType: "",
  totalPrice: 0,
  createdAt: "",
  orderstate: "",
  deliveryWindow: {
    startTime: 0,
    endTime: 0,
    fulfillmentType: "",
    carrierName: "",
  },
};

export const initialState: { data?: [IOrderHistoryData] } = {
  data: undefined,
};

export const OrderHistorySlice = createSlice({
  name: "orderHistory",
  initialState,
  reducers: {
    setOrderHistoryResponse(state, action) {
      state.data = action?.payload;
    },
    reset(state) {
      state.data = undefined;
    },
  },
});
export const { setOrderHistoryResponse, reset } = OrderHistorySlice.actions;

export const OrderHistorySelector = (state: RootState) => state.orderHistory;

export default OrderHistorySlice.reducer;
