import { IContactUsFormPayload } from "@Components/contact-us/ContactUsForm";
import {
  ILoginFormPayload,
  IRegisterFormPayload,
  TAccountResetPayload,
  TForgotPasswordPayload,
} from "@Types/Account";
import { cartIdHeader, buildHeaders } from "@wff/configs/HeadersConfig";
import { ICustomerInfo } from "@wff/interfaces";
import api from "./api";
import RestEndpoints from "./constants/RestEndpoints";

export const submitForgotPasswordForm = (payload: TForgotPasswordPayload) => {
  const headers = buildHeaders(cartIdHeader);

  return api.post<any>(RestEndpoints.CUSTOMER.FORGOT_PASSWORD, payload, {
    headers,
  });
};

export const submitAccountResetForm = (payload: TAccountResetPayload) => {
  return api.post<any>(RestEndpoints.CUSTOMER.ACCOUNT_RESET, payload);
};

export const submitLoginForm = (payload: ILoginFormPayload) => {
  const headers = buildHeaders(cartIdHeader);

  return api.post<any>(RestEndpoints.CUSTOMER.LOGIN, payload, { headers });
};

export const getCustomerInfo = () => {
  return api.get<ICustomerInfo>(RestEndpoints.CUSTOMER.GET_DETAILS);
};

export const submitSignupForm = (payload: IRegisterFormPayload) => {
  const headers = buildHeaders(cartIdHeader);

  return api.post<any>(RestEndpoints.CUSTOMER.SIGNUP, payload, { headers });
};

export const submitContactUsForm = (payload: IContactUsFormPayload) => {
  return api.post<any>(RestEndpoints.CUSTOMER.CONTACT, payload);
};

export const updateCustomerInfo = (payload: any) => {
  return api.put<ICustomerInfo>(RestEndpoints.CUSTOMER.UPDATE_DETAILS, payload);
};

export const submitLogoutCustomer = () => {
  return api.post<void>(RestEndpoints.CUSTOMER.LOGOUT, {});
};

export const subscribeToKlaviyoList = (listId: string, payload: any) => {
  return api.post<any>(
    `${RestEndpoints.CUSTOMER.SUBSCRIBE_TO_KLAVIYO_LIST}/${listId}/subscribe`,
    payload
  );
};

export const removeCustomerShippingAddress = (addressId: string) => {
  return api.delete<any>(
    `${RestEndpoints.CUSTOMER.SHIPPING_ADDRESS}/${addressId}`
  );
};
