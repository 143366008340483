import { store } from "store";
import logger from "./Logger";
import { nextAppEnv } from "./environment";

export const getReduxStore = () => {
  return store.getState();
};

export const getLocale = () => {
  return getReduxStore()?.locale;
};

export const getLocaleLanguageCode = () => {
  return getLocale()?.languageCode || nextAppEnv.application.locale;
};

// Note: Only call getCartIdForHeader() when applying it directly to an api request header
export const getCartIdForHeader = () => {
  const { cartId } = store.getState().cart;
  if (!cartId) {
    logger.warn(
      "Warning: The cartId did not exist when trying to apply it to the X-WILDFORK-CART-ID header. If this was the initial Put call to /cart/item then you can ignore this warning."
    );
  }

  return cartId;
};

export const getStoreIdForHeader = () => {
  const { storeId } = store.getState().cart;
  if (!storeId) {
    logger.warn(
      "Warning: The storeId did not exist when trying to apply it to the X-WILDFORK-STORE-ID header."
    );
  }

  return storeId;
};
