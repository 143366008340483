import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICartToastState } from "@Types/cart-toast";
import { ICartLineItem } from "@wff/interfaces";
import { RootState } from ".";

/* const cartLineItemMock =  { //TODO remove once fully developed
  id: "12",
  name: "Ground Wagyu Beef 80% Lean - 1 LB - 1.0",
  weightText: "1.0",
  perUnitPriceText: "898",
  sku: "12345",
  itemPiecesText: "1",
  totalPrice: 898,
  quantity: 1,
  productImageUrl:
    "https://cdn.shopify.com/s/files/1/2530/8116/products/93-wagyu-ground-beef-0007.jpg?v=1579280160",
  slug: "Some-descriptive-slug",
} */

export const initialState: ICartToastState = {
  open: false,
  items: [
    {
      id: "",
      name: "",
      weightText: "",
      perUnitPriceText: "",
      sku: "",
      itemPiecesText: "",
      totalPrice: 0,
      quantity: 0,
      productImageUrl: "",
      slug: "",
      isFixedPrice: false,
    },
  ],
};

export const cartToastSlice = createSlice({
  name: "cartToast",
  initialState,
  reducers: {
    setOpenToast(state, action: PayloadAction<boolean>) {
      return { ...state, open: action.payload };
    },
    setToastItem(state, action: PayloadAction<ICartLineItem>) {
      return { ...state, items: [action.payload] };
    },
  },
});
export const cartToastActions = cartToastSlice.actions;

export const cartToastSelector = (state: RootState) => state.cartToast;

export default cartToastSlice.reducer;
