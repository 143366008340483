import { nextAppEnv } from "@Utils/environment";
import RestEndpoints from "@wff/api/constants/RestEndpoints";
import ApiService from "@wff/services/ApiService";
import type { ApiConfigTypes } from "@wff/services/ApiService";
import { contentRequestPayload } from "@wff/types/Contentful";

/**
 * Constructs api endpoint url
 * @returns {string} - Endpoint url
 */
const prepareEndPoint = () => RestEndpoints.CONTENTFUL;

/**
 * Prepare Request Config
 * @param _data
 * @param config
 * @returns
 */
const prepareRequestConfig = (_data: any, config: ApiConfigTypes) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${nextAppEnv.contentful.deliveryToken}`,
  };

  return config;
};

/**
 * Constructs request payload
 * @param {object} data
 * @return {object}
 */
const prepareRequestPayload = (data: contentRequestPayload) => {
  return {
    query: data?.query,
    variables: data?.variables,
  };
};

// NOTE: THIS IS LEGACY CODE. DO NOT USE AS TEMPLATE FOR NEW API SERVICES - ALL NEW ENDPOINTS SHOULD GO IN /API
/**
 * LoginService Class
 * @returns {*} LoginService class instance
 */
class LoginService extends ApiService.Service {
  constructor() {
    super({
      method: ApiService.methods.POST,
      getEndPoint: prepareEndPoint,
      getRequestPayload: prepareRequestPayload,
      getRequestConfig: prepareRequestConfig,
    });
  }
}

export default new LoginService();
