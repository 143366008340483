// const regexAllSpecialChars =

const PATTERNS = {
  NAME: /^[\p{Letter}\p{Mark}\p{Number}\s"'`'.\-]{2,32}$/u, // allow for unique names (In all Languages) with unordinary characters
  EMAIL:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)?[\.][a-zA-Z0-9]{2,5}$/,
  PASSWORD:
    /^[\p{Letter}\p{Mark}\p{Number}\s"'`'./,()&_\-!@#$%^&*()_+=\[\]\{\};':"<>?,./\\\|~]+$/u,
  STRONG_PASSWORD:
    /^(?=.*[\p{Ll}])(?=.*[\p{Lu}])(?=.*[\p{Number}])(?=.*[\u00C0-\u017F!@#$%^&*()~`_\-\{[\}\]|\\:;"'<,>.?/])[\p{Letter}\p{Mark}\p{Number}\u00C0-\u017F!@#$%^&*()~`_\-+=\{[\}\]|\\:;"'<,>.?/]{8,25}$/u,
  STRONG_PASSWORD_CA:
    /^(?=.*[\p{Ll}])(?=.*[\p{Lu}])(?=.*[\p{Number}])(?=.*[\u00C0-\u017F+=!@#$%^&*()~`_\-\{[\}\]|\\:;"'<,>.?/])[\p{Letter}\p{Mark}\p{Number}\u00C0-\u017F!@#$%^&*()~`_\-+=\{[\}\]|\\:;"'<,>.?/]{8,25}$/u,
  EMAIL_ENDS_WITH_AT: /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@]$/,
  // ADDRESS: /^[\p{Letter}\p{Mark}\p{Number}\s"'`'./,()&#_\-]+$/u,
  ADDRESS:
    /^[\p{Letter}\p{Mark}\p{Number}\s"'`'./,()&_\-!@#$%^&*()_+=\[\]\{\};':"<>?,./\\\|~]+$/u, //allow All Numbers, Letters and Special characters in address and unit/delivery instruction field, JIRA Ticket: CE-1290
  CITY: /^[\p{Letter}\p{Mark}\p{Number}\s"'`'./,()&_\-]+$/u,
  ZIP_US: /^\p{Number}{5}(?:[-\s]\p{Number}{4})?$/u,
  ZIP_MX: /^\p{Number}{5}?$/u,
  ZIP_CA: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
  //REFERENCE: OLD code PHONE: /^[ 0-9\(\)\+\-]+$/i, // allow for +1 (904) 555-5555
  PHONE:
    /^(?:\+?1[-.\s]?)?\(?([2-9][0-8][0-9])\)?[-.\s]?([2-9][0-9]{2})[-.\s]?([0-9]{4})$/i, //valid for US and Canada

  COUPON: /^[\p{Letter}\p{Mark}\p{Number}\s\-]+$/u,
};

export default PATTERNS;
