import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { isValidZip } from "@Components/forms/helpers/FieldValidator";
// import {
//   GENERIC_EVENT_ERR_HEADING,
//   GENERIC_EVENT_ERR_MSG,
// } from "@Constants/snackbar";
import { STORAGE_KEYS, STORAGE_TYPES } from "@Constants/storage";
import { withAsync } from "@Libs/api/withAsync";
import { TDispatch } from "@Types/common";
import logger from "@Utils/Logger";
import { getItem } from "@Utils/browserStorage";
import { nextAppEnv } from "@Utils/environment";
import { isCanadianLocale } from "@Utils/locale";
// import { axiosErrorHandler } from "@Utils/snackbarHandlers";
// import { ERROR_CODES } from "@wff/api/constants/error";
import { getStores } from "@wff/api/storeApi";
import { IStoreListResponse } from "@wff/hooks/usePickupAvailableForCA";
import { ICoordinates, IStoreListing } from "@wff/interfaces";
import { useAppDispatch, useAppSelector } from "@wff/store/hooks";
import { localeSelector } from "@wff/store/localeSlice";
import {
  ZipCodeSelector,
  setZipCodePickupHandler,
} from "@wff/store/zipcodeSlice";

interface IStoreListUpdater {
  dispatch: TDispatch;
  setStoreList: Dispatch<SetStateAction<IStoreListing[]>>;
}

interface IFetchStoreListData extends ICoordinates, IStoreListUpdater {}
interface IUseGetCoordinatesAndFetchData extends IStoreListUpdater {
  zipcode: string;
  isLoaded: boolean;
  countryCode: string;
  languageCode: string;
  initGoogleMapsLoaded: boolean;
  state: string;
}

export const fetchStoreListData = async ({
  latitude,
  longitude,
  dispatch,
  setStoreList,
}: IFetchStoreListData) => {
  const { error, response } = await withAsync(() => {
    return getStores(latitude as number, longitude as number, 100);
  });

  let result = { stores: [], isPickupable: false } as IStoreListResponse;
  let nearestPickupLocationForCA = null;
  if (!error) {
    setStoreList(response?.data?.stores as IStoreListing[]);
    /**
     * Store neartest pickup location in zipcode slice
     */
    /*
      ? INFO By Shubham : Commented this for CE-1725 ( no need to display nearest store, might be needed in the future ) 
      const pickupLocation = response?.data?.stores?.filter((store) => store?.curbsidePickup)
      .sort((a, b) => a.distance - b.distance)[0];
    */
    const pickupLocation = response?.data?.stores
      ?.filter((store) => store?.curbsidePickup)
      .find(
        (store) => store?.id === nextAppEnv.application.defaultPickupStoreId
      );
    if (pickupLocation) {
      nearestPickupLocationForCA = {
        name: pickupLocation.name,
        ...pickupLocation.address,
      };
    }
    const isPickupAvailable = nearestPickupLocationForCA ? true : false;
    dispatch(
      setZipCodePickupHandler({
        nearestPickupLocation: nearestPickupLocationForCA,
        isPickupAvailable: isPickupAvailable,
      })
    );
    result = {
      stores: response?.data?.stores || [],
      isPickupable: isPickupAvailable,
    };
  } else {
    dispatch(
      setZipCodePickupHandler({
        nearestPickupLocation: nearestPickupLocationForCA,
        isPickupAvailable: false,
      })
    );
    /* Hide this error message as per TPR-175 */
    // axiosErrorHandler({
    //   error,
    //   dispatch,
    //   leadingSnackbar: {
    //     heading: GENERIC_EVENT_ERR_HEADING.REQUEST_FAILED(),
    //     message: GENERIC_EVENT_ERR_MSG.GET_STORES(),
    //   },
    //   errorCodeIgnoreList: [ERROR_CODES.WF_CART_006],
    // });
  }
  return result;
};

export const useGetCoordinatesAndFetchData = ({
  zipcode,
  isLoaded,
  dispatch,
  setStoreList,
  countryCode,
  languageCode,
  state,
  initGoogleMapsLoaded,
}: IUseGetCoordinatesAndFetchData) => {
  useEffect(() => {
    if (
      isLoaded &&
      zipcode &&
      isValidZip()(zipcode) === undefined // only execute when zipcode (multi-locale) is valid
    ) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder
        .geocode({
          address: `${state} ${zipcode}, ${countryCode?.toUpperCase()}`,
        })
        .then((res) => {
          if (!res?.results?.length || !res?.results[0]?.geometry?.location) {
            throw new Error(
              "there were no results in google's geocode response"
            );
          } else {
            const latitude = res.results[0].geometry.location.lat();
            const longitude = res.results[0].geometry.location.lng();
            fetchStoreListData({ latitude, longitude, setStoreList, dispatch });
          }
        })
        .catch((err) => {
          logger.error(
            "useGetCoordinatesAndFetchData() in useGetStores.ts",
            err
          );
        });

      return () => {
        setStoreList([]);
      };
    }
  }, [
    zipcode,
    isLoaded,
    dispatch,
    setStoreList,
    countryCode,
    languageCode,
    state,
    initGoogleMapsLoaded,
  ]);
};

export const useGetStores = (zipcode: string) => {
  const dispatch = useAppDispatch();
  const [storeList, setStoreList] = useState<IStoreListing[]>([]);
  const currentLocale = useAppSelector(localeSelector);
  const languageCode = currentLocale?.languageCode;
  /**
   * Changes currentLocale?.countryLong, because in the case of
   * geocode(M4V0A5, CA) will gives you wrong Lat, Long
   * CA is treated as California not Canada.
   */
  const countryCode = currentLocale?.countryLong;
  const isCanadianUser = isCanadianLocale(currentLocale);
  const selectAddress = useAppSelector(ZipCodeSelector);
  const state = selectAddress?.address?.state;

  const initGoogleMapsLoaded =
    typeof window !== "undefined" && window?.google?.maps ? true : false;

  /**
   * CE:Fix:406
   * Upon page refresh in the DeliveryContainer, zipcode will be un-available and due to that StoreList will not populating
   * in the PickUp Content.
   *
   * This fix will available the zipcode, when it is not available
   */
  if (isCanadianUser) {
    zipcode =
      zipcode || getItem(STORAGE_KEYS.CUSTOMER_ZIP, STORAGE_TYPES.LOCAL);
  }

  useGetCoordinatesAndFetchData({
    zipcode,
    isLoaded: initGoogleMapsLoaded,
    dispatch,
    setStoreList,
    countryCode,
    languageCode,
    state,
    initGoogleMapsLoaded,
  });

  return {
    storeList,
  };
};
