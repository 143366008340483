import path from "path";
import { COMMERCE_TOOLS_DOMAINS } from "@wff/constants/url";
import { whereAmI } from "./whereAmI";

export const pathJoinHttp = (...args: string[]) => {
  let route = path.posix.join(...args);
  route = route.split(path.sep).join(path.posix.sep);
  if (
    route.includes("http") &&
    (!route.includes("http://") || !route.includes("https://"))
  ) {
    route = route.replace(":/", "://");
  }
  return route;
};

// a string with a length of 1 that only contains a slash and nothing else will be excluded
// and thus return false
export const isThereATrailingSlash = (string: string) => {
  const regex = /\/+$/;
  return string?.length > 1 && regex.test(string);
};

export const removeTrailingSlash = (string: string) => {
  let newString = string;
  // do not remove anything if its a single character string such as "/"
  if (string?.length > 1) {
    newString = string.replace(/\/+$/, "");
  }
  return newString;
};

export const appendTrailingSlash = (string: string) => {
  let newString = string;
  // do not append anything if its a single character string such as "/"
  if (string?.length > 1 && !isThereATrailingSlash(string)) {
    newString = `${string}/`;
  }
  return newString;
};

export const removeTrailingPortFromDomain = (string: string) => {
  let newString = string;
  const lastIndexOfColon = string.lastIndexOf(":");
  if (lastIndexOfColon > 0) {
    newString = string.slice(0, lastIndexOfColon);
  }
  return newString;
};

export const domainSlasher = (
  url = "",
  preserveTrailingSlash = false
): string => {
  const slashCount = url?.split("/").length - 1;
  if (url?.length <= 1) {
    return url;
  }
  if (slashCount > 0) {
    let slashedDomain = url;
    if (url?.includes("http")) {
      slashedDomain = url?.split("/")[2];
    } else {
      slashedDomain = url?.split("/")[0];
    }
    if (preserveTrailingSlash) {
      // if there's a trailing slash in original url and we removed it, well then put it back since developer actually wants to keep it
      if (isThereATrailingSlash(url)) {
        slashedDomain = `${slashedDomain}/`;
        slashedDomain = appendTrailingSlash(slashedDomain);
      }
    } else {
      slashedDomain = removeTrailingSlash(slashedDomain);
    }
    return slashedDomain;
  }
  return url;
};

export const wwwDestroyer = (url: string) => {
  const weblessUrl = url;
  if (url?.includes("www.")) {
    return url?.replace("www.", "");
  }
  return weblessUrl;
};

interface IGetDomainOptions {
  preserveTrailingSlash?: boolean;
  removePort?: boolean;
  preserveWww?: boolean;
}

export const getDomain = (url: string, options: IGetDomainOptions = {}) => {
  const preserveTrailingSlash = options.preserveTrailingSlash ?? false;
  let domain = domainSlasher(url, preserveTrailingSlash);
  if (!options?.preserveWww) {
    domain = wwwDestroyer(domain);
  }
  if (options.removePort) {
    domain = removeTrailingPortFromDomain(domain);
  }
  return domain;
};

export const amIFromOneOfTheseDomains = (
  urls: string[],
  options: IGetDomainOptions = {}
): boolean => {
  const thisDomain = getDomain(whereAmI(), options);
  return urls.some((thatUrl) => {
    return thisDomain === getDomain(thatUrl, options);
  });
};

export const amInStagingDomain = () => {
  return amIFromOneOfTheseDomains([COMMERCE_TOOLS_DOMAINS.STAGING.US], {
    removePort: true,
  });
};

export const amInLocalQaOrDevDomain = () => {
  return amIFromOneOfTheseDomains(
    [
      COMMERCE_TOOLS_DOMAINS.LOCAL,
      COMMERCE_TOOLS_DOMAINS.DEV.US,
      COMMERCE_TOOLS_DOMAINS.QA.US,
      COMMERCE_TOOLS_DOMAINS.DEV.CA,
      COMMERCE_TOOLS_DOMAINS.QA.CA,
    ],
    {
      removePort: true,
    }
  );
};

export const getFullPath = (asPath?: string) => {
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  return `${origin}${asPath}`;
};
