import { IAvailableDate, IStoreCard, IUserContact } from "interfaces";
import { FC, useCallback, useState } from "react";
import { USER_CONTACT_FORM_TYPE } from "@Constants/common";
import { numberOrZero } from "@Utils/sanitization";
import { contextFactory } from "../../helpers/contextFactory";

interface DeliveryContextProps {
  selectedStore: IStoreCard;
  setSelectedStore: (value: IStoreCard) => void;
  availableWindows: IAvailableDate[];
  setAvailableWindows: (value: IAvailableDate[]) => void;
  selectedAvailableDate: number;
  setSelectedAvailableDateHandler: (value: number) => void;
  selectedAvailableTime: number;
  setSelectedAvailableTime?: never; // call the Handler instead
  selectedAvailableWindow: IAvailableDate[];
  setSelectedAvailableWindow: (value: IAvailableDate[]) => void;
  setSelectedAvailableTimeHandler: (
    val1: number,
    val2: number,
    val3: IAvailableDate[]
  ) => void;
  resetSelectedAvailableDateAndTime: (list: IAvailableDate[]) => void;
  initialSelectedAvailableDate: number;
  initialSelectedAvailableTime: number;
  resetDeliveryContext: () => void;
}

const [useDelivery, DeliveryContext] = contextFactory<DeliveryContextProps>();

export { useDelivery, DeliveryContext };

interface DeliveryProviderProps {
  initialSelectedAvailableDate?: number;
  initialSelectedAvailableTime?: number;
  initialAvailableWindows?: IAvailableDate[];
  initialSelectedAvailableWindow?: IAvailableDate[];
}

export const userContactSourceSwitcher = ({
  billingAddressForm,
  shippingAddressForm,
  formType,
}: {
  billingAddressForm: IUserContact;
  shippingAddressForm: IUserContact;
  formType: string;
}) => {
  let userContact: IUserContact;
  switch (formType) {
    case USER_CONTACT_FORM_TYPE.BILLING:
      userContact = { ...billingAddressForm };
      break;
    case USER_CONTACT_FORM_TYPE.SHIPPING:
    default:
      userContact = { ...shippingAddressForm };
      break;
  }
  return userContact;
};

export const DeliveryProvider: FC<DeliveryProviderProps> = ({
  children,
  initialSelectedAvailableDate,
  initialSelectedAvailableTime,
  initialAvailableWindows = [],
  initialSelectedAvailableWindow = [],
}) => {
  const [availableWindows, setAvailableWindows] = useState<IAvailableDate[]>(
    initialAvailableWindows
  );

  const [selectedAvailableDate, setSelectedAvailableDate] = useState(
    numberOrZero(initialSelectedAvailableDate)
  );
  const [selectedAvailableTime, setSelectedAvailableTime] = useState(
    numberOrZero(initialSelectedAvailableTime)
  );
  const [selectedStore, setSelectedStore] = useState<IStoreCard>(
    {} as IStoreCard
  );
  const [selectedAvailableWindow, setSelectedAvailableWindow] = useState<
    IAvailableDate[]
  >(initialSelectedAvailableWindow);

  const setSelectedAvailableDateHandler = useCallback((value: number) => {
    // note: setSelectedAvailableTimeHandler is called independently in DeliveryTimeSlider
    // keeping this as a Handler to be consistent with setSelectedAvailableTimeHandler
    // and also in case any additional logic needs to be added
    setSelectedAvailableDate(value);
  }, []);

  const setSelectedAvailableTimeHandler = useCallback(
    (
      dateIndex: number,
      timeIndex: number,
      availableWindows: IAvailableDate[]
    ) => {
      let selectionIndex = null;
      if (availableWindows?.length) {
        // get available window object based on selected date window
        const getAvailableWindowObject = availableWindows[dateIndex];

        // if the first delivery-time window in the list is disabled, we have to choose an available time window
        if (
          getAvailableWindowObject?.availableTimes?.length &&
          getAvailableWindowObject?.availableTimes[timeIndex].disabled
        ) {
          // find first non-disabled element
          const indexOfNonDisabledElement =
            getAvailableWindowObject?.availableTimes.findIndex((el) => {
              return el.disabled !== true;
            });
          // there should always be at least one element that is not disabled
          // since we are excluding days from the list when all of its availableTimes elements are disabled
          if (indexOfNonDisabledElement >= 0) {
            selectionIndex = indexOfNonDisabledElement;
          }
        } else {
          selectionIndex = timeIndex;
        }
        if (selectionIndex !== null) {
          setSelectedAvailableTime(selectionIndex);
        }
      } else {
        setSelectedAvailableTime(0);
      }
    },
    []
  );

  const resetSelectedAvailableDateAndTime = useCallback(
    (availableWindows: IAvailableDate[]) => {
      setSelectedAvailableDateHandler(0);
      setSelectedAvailableTimeHandler(0, 0, availableWindows);
    },
    [setSelectedAvailableDateHandler, setSelectedAvailableTimeHandler]
  );

  const resetDeliveryContext = useCallback(() => {
    setAvailableWindows([]);
    setSelectedAvailableWindow([]);
    setSelectedAvailableDate(0);
    setSelectedAvailableTime(0);
  }, [
    setAvailableWindows,
    setSelectedAvailableDate,
    setSelectedAvailableTime,
    setSelectedAvailableWindow,
  ]);

  return (
    <DeliveryContext.Provider
      value={{
        availableWindows,
        selectedStore,
        setSelectedStore,
        setAvailableWindows,
        selectedAvailableDate,
        setSelectedAvailableDateHandler,
        selectedAvailableTime,
        setSelectedAvailableTimeHandler,
        resetSelectedAvailableDateAndTime,
        initialSelectedAvailableDate: numberOrZero(
          initialSelectedAvailableDate
        ),
        initialSelectedAvailableTime: numberOrZero(
          initialSelectedAvailableTime
        ),
        resetDeliveryContext,
        selectedAvailableWindow,
        setSelectedAvailableWindow,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};
