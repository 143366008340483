export const flattenStructIntoArrayOfPrimitiveValues = (
  structure: any,
  values?: any[]
) => {
  const typeofStruct = typeof structure;
  let allValues = values?.length ? values : [];
  switch (typeofStruct) {
    case "object":
      if (structure.constructor === Array) {
        // type array
        (structure as Array<any>).forEach((el: any) => {
          allValues = flattenStructIntoArrayOfPrimitiveValues(el, [
            ...allValues,
          ]);
        });
      } else {
        // type object
        Object.keys(structure as Object).forEach((key: string) => {
          allValues = flattenStructIntoArrayOfPrimitiveValues(structure[key], [
            ...allValues,
          ]);
        });
      }
      break;
    case "function":
      allValues = flattenStructIntoArrayOfPrimitiveValues(
        (structure as Function)(),
        [...allValues]
      );
      break;
    default:
      allValues?.push(structure);
      break;
  }
  return [...allValues];
};

/*    getValueFromFullJsonPath
        given a parent object reference: eg: parentObj = { }
        this function returns the value of the path provided by pathKeys
        if parentObj = { someKey: { someNestedKey: "someValue" } };
        then "someValue" will be returned by the following: getValueFromFullJsonPath(parentObj, ["someKey", "someNestedKey"]);
*/
export const getValueFromFullJsonPath = (
  parentContext: any,
  pathKeys: string[]
): any => {
  let referencePointer = parentContext;
  let currentKey;
  for (let looper = 0; looper < pathKeys.length; looper++) {
    currentKey = pathKeys[looper];
    referencePointer = referencePointer[currentKey];
    if (referencePointer === undefined) {
      break;
    }
  }
  return referencePointer;
};
