import { useEffect } from "react";
import { USER_CONTACT_FORM_TYPE } from "@Constants/common";
import { defaultUserContact } from "@wff/context/checkout/CheckoutProvider";
import {
  ILocaleDetail,
  ISetUserContactHandler,
  IUserContact,
} from "@wff/interfaces";

export interface IUseCountryFromLocale {
  locale: ILocaleDetail;
  setUserContactHandler: ISetUserContactHandler;
  formType?: string;
}

export interface IUseCountryFromForm {
  userContact: Partial<IUserContact>;
  setUserContactHandler: ISetUserContactHandler;
  formType?: string;
}

const synchronizeCountryFields = (
  countryShort: string,
  setUserContactHandler: ISetUserContactHandler,
  formType?: string
) => {
  const { state, country, dialCode } = defaultUserContact(countryShort);
  if (!formType) {
    // if we didn't pass a formType then the intention is to update all userContact state references accordingly
    Object.keys(USER_CONTACT_FORM_TYPE).forEach((type) => {
      setUserContactHandler({ state, country, dialCode }, type);
    });
  } else {
    setUserContactHandler({ state, country, dialCode }, formType);
  }
};

export const useCountryFromLocale = ({
  locale,
  setUserContactHandler,
  formType,
}: IUseCountryFromLocale) => {
  // synchronize any locale-related form data on locale reset
  useEffect(() => {
    synchronizeCountryFields(
      locale.countryShort,
      setUserContactHandler,
      formType
    );
  }, [locale.countryShort, setUserContactHandler, formType]);
};

export const useCountryFromForm = ({
  userContact,
  setUserContactHandler,
  formType,
}: IUseCountryFromForm) => {
  // synchronize any locale-related form data on country field change
  useEffect(() => {
    synchronizeCountryFields(
      userContact.country as string,
      setUserContactHandler,
      formType
    );
  }, [userContact.country, setUserContactHandler, formType]);
};
