import { createSlice } from "@reduxjs/toolkit";
import { ICustomerInfo } from "@wff/interfaces";
import { RootState } from ".";

export const initialState: ICustomerInfo = {
  firstName: "",
  lastName: "",
  email: "",
  acceptMarketing: false,
  member: false,
  membershipExpirationDate: "",
  address: [],
  contactEmailId: "",
  contactPickUpEmailId: "",
};

export const CustomerInfoSlice = createSlice({
  name: "customerInfo",
  initialState,
  reducers: {
    setCustomerInfo(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    resetCustomerInfo() {
      return { ...initialState };
    },
    setIsMember(state, action: { payload: boolean }) {
      return {
        ...state,
        member: action.payload,
      };
    },
  },
});

export const { setCustomerInfo, resetCustomerInfo, setIsMember } =
  CustomerInfoSlice.actions;

export const CustomerInfoSelector = (state: RootState) => state.customerInfo;

export default CustomerInfoSlice.reducer;
