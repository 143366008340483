import { getCartIdForHeader, getStoreIdForHeader } from "@Utils/reduxCommon";
import {
  cartIdHeader,
  buildHeaders,
  storeIdHeader,
} from "@wff/configs/HeadersConfig";
import RestEndpoints from "@wff/configs/RestEndpoints";
import ApiService from "@wff/services/ApiService";
import type { ApiConfigTypes } from "@wff/services/ApiService";

/**
 * Prepare Request Config
 * @param _data
 * @param config
 * @returns
 */
const prepareRequestConfig = (_data: any, config: ApiConfigTypes) => {
  const storeId = getStoreIdForHeader();

  config.headers = {
    ...config.headers,
    "X-WILDFORK-CART-ID": getCartIdForHeader(),
  };
  return {
    ...config,
    headers: {
      ...(config?.headers ?? {}),
      ...buildHeaders(cartIdHeader, storeIdHeader(storeId)),
    },
  };
};

/**
 * Constructs api endpoint url
 * @returns {string} - Endpoint url
 */
const prepareEndPoint = (data: any) => {
  return `${RestEndpoints.CHECKOUT.GET_CART_ITEMS}/?checkInventory=${data}`;
};

// NOTE: THIS IS LEGACY CODE. DO NOT USE AS TEMPLATE FOR NEW API SERVICES - ALL NEW ENDPOINTS SHOULD GO IN /API
/**
 * GetCartItemsService Class
 * @returns {*} GetCartItemsService class instance
 */
class GetCartItemsService extends ApiService.Service {
  constructor() {
    super({
      method: ApiService.methods.GET,
      getEndPoint: prepareEndPoint,
      getRequestConfig: prepareRequestConfig,
    });
  }
}

export default new GetCartItemsService();
