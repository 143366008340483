import { doQuery } from "@Libs/contentful/Contentful.helper";
import nextI18nextConfig from "@wff/next-i18next.config";
import logger from "./Logger";

export const getStaticPathsFromSlug = async (
  query: string,
  locales: string[] = nextI18nextConfig.i18n.locales
) => {
  try {
    const promises = locales.map(async (locale) => {
      return doQuery(query, { locale });
    });

    const results = await Promise.all(promises);

    return results
      .map((result: any, index) => {
        const { slugs = [] } = result || {};

        if (!slugs?.items?.length) {
          return null;
        }

        return slugs.items.map((item: any) => {
          return {
            params: {
              slug: item.slug,
              locale: locales[index],
            },
          };
        });
      })
      .filter(Boolean)
      .flat();
  } catch (error) {
    logger.error("[slug] getStaticPaths()", error);
  }
};
