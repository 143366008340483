import {
  SnackBarType,
  SnackBarVariant,
  TriggerSnackBar,
} from "@Types/SnackBar";
import {
  IpriceObject,
  IProductAttributes,
} from "@Types/promo-carousel/product-tile";
import logger from "@Utils/Logger";
import { convertToDollar } from "@Utils/currency";
import { currency } from "@Utils/index";
import { isCanadianLocale } from "@Utils/locale";
import { ICartLineItem } from "@wff/interfaces";
import { AppDispatch } from "@wff/store";
import { triggerSnackBar } from "@wff/store/SnackBarSlice";
import { IDiscount, getCartItems } from "@wff/store/cartSlice";

interface ILocalTriggerSnackBar extends TriggerSnackBar {
  dispatch: AppDispatch;
}

export const poundsPerKG = 2.20462;

export const showSnackbar = ({
  message,
  title,
  type,
  variant,
  timeout,
  dispatch,
}: ILocalTriggerSnackBar) => {
  dispatch(
    triggerSnackBar({
      message,
      title,
      type: type as SnackBarType,
      variant: variant as SnackBarVariant,
      timeout,
    })
  );
};

export const getItemIndex = (lineItems: ICartLineItem[], sku: string) =>
  lineItems?.findIndex((item: ICartLineItem) => item.sku === sku);

export const getLineItem = (items: ICartLineItem[], itemIndex: number) => {
  if (!items) return {} as ICartLineItem;
  return items[itemIndex!] ?? ({} as ICartLineItem);
};

export const getItemQty = (items: ICartLineItem[], itemIndex: number) => {
  if (!items) return null;
  return items[itemIndex!]?.quantity ?? null;
};

export const getLineItemId = (items: ICartLineItem[], itemIndex: number) => {
  if (!items) return "0";
  return items[itemIndex!]?.id ?? "0";
};

export const getUomValue = (attribs: any[]) => {
  const UOM = attribs?.find(
    (el: IProductAttributes) => el.name === "weightUOM"
  );
  return UOM?.value?.label?.toLowerCase() === "pounds"
    ? "LB"
    : UOM?.value?.label?.toLowerCase() === "kilograms"
    ? "KG"
    : "";
};

export const getWeightValue = (attribs: any[]) => {
  const netWeight = attribs?.find(
    (el: IProductAttributes) => el.name === "netWeight"
  );
  return netWeight?.value || "";
};

export const getCTUnitPrice = (attribs: any[]) => {
  const unitPrice = attribs?.find(
    (el: IProductAttributes) => el.name === "unitPrice"
  );
  return unitPrice?.value.centAmount ? [unitPrice] : [{}];
};

export const getQtyPerPackage = (attribs: any[]) => {
  const qtyPerPackage = attribs?.find(
    (el: IProductAttributes) => el.name === "qtyPerPackage"
  );

  const qtyUnitType = attribs?.find(
    (el: IProductAttributes) => el.name === "unitType"
  );

  const qtyRange = attribs?.find(
    (el: IProductAttributes) => el.name === "quantityRange"
  );

  const value = qtyPerPackage?.value || null;
  if (value && typeof value === "number") {
    let unitType = qtyUnitType?.value[0]?.key;
    if (unitType === undefined) {
      unitType = "piece";
    }
    if (qtyRange?.value !== undefined) {
      return qtyRange?.value[0] < qtyRange?.value[1]
        ? `${qtyRange?.value[0]} - ${qtyRange?.value[1]} ${unitType}s |`
        : `${qtyRange?.value[1]} - ${qtyRange?.value[0]} ${unitType}s |`;
    }
    return value > 0
      ? value > 1
        ? `${value} ${unitType}s |`
        : `${value} ${unitType} |`
      : "";
  }
  return "";
};

export const formatNetWeight = (
  weight: number,
  UOM: string,
  currentLocale: string,
  isFixedPrice?: boolean
) => {
  const weightInPounds = weight ? Number(weight) * poundsPerKG : "";

  if (isFixedPrice && weight) {
    const weightInGrams = weight * 1000;
    if (isCanadianLocale(currentLocale) && UOM === "KG") {
      return `${weightInGrams.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })} G`;
    } else {
      return weightInGrams
        ? `${weightInGrams.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })} G`
        : "";
    }
  } else if (weight && isCanadianLocale(currentLocale) && UOM === "KG")
    return `approx ${weight?.toFixed(2)} ${UOM} | ${
      weightInPounds ? weightInPounds.toFixed(1) : ""
    } LB`;
  return weight ? `approx ${weight} ${UOM}` : "";
};

export const getPriceOrDiscounted = (priceData: any) => {
  if (priceData && Array.isArray(priceData)) {
    try {
      return priceData[0]?.discounted?.value
        ? convertToDollar(priceData[0]?.discounted?.value?.centAmount)
        : priceData[0].value?.centAmount
        ? convertToDollar(priceData[0]?.value?.centAmount)
        : 0;
    } catch (error) {
      logger.info("getPriceOrDiscounted failed: ", error);
      return 0;
    }
  } else {
    return 0;
  }
};

interface IGetPricePerUitWithCurrencyCodeAndUOM {
  price: number;
  UOM: string; // Unit Of Measure
  UOMOverride?: string; // this parameter is used as a substitute for UOM
  pricePerUnit: number;
}

export const getPricePerUnitWithCurrencyCodeAndUOM = ({
  price,
  UOM,
  pricePerUnit,
  // currencyCode,
  UOMOverride,
}: IGetPricePerUitWithCurrencyCodeAndUOM) => {
  return price && UOM && pricePerUnit !== Infinity
    ? // removed currency code by business request -- CTIM-2440 -- previous code: ${currencyCode ? " " + currencyCode : ""}
      `${currency.format(pricePerUnit)} / ${UOMOverride || UOM}`
    : price && pricePerUnit !== Infinity
    ? `${currency.format(pricePerUnit)}`
    : "";
};

export const formatUnitPrice = (priceData: IpriceObject[], UOM: string) => {
  const price = getPriceOrDiscounted(priceData);
  return getPricePerUnitWithCurrencyCodeAndUOM({
    price,
    UOM,
    pricePerUnit: price,
  });
};

export const formatUnitPriceCA = (
  // we use this value only for CA locales
  priceData: IpriceObject[],
  weight: string,
  UOM: string,
  currentLocale: string
) => {
  if (!priceData || !Array.isArray(priceData)) return "";
  const price = getPriceOrDiscounted(priceData);
  const weightInPounds =
    weight && !isNaN(Number(weight)) ? Number(weight) * poundsPerKG : 1;
  /* const currencyCode = priceData[0]?.value?.currencyCode // removed currency code by business request -- CTIM-2440 -- previous code: ${currencyCode ? " " + currencyCode : ""}
    ? priceData[0]?.value?.currencyCode
    : ""; */

  // Canada Locales
  if (isCanadianLocale(currentLocale) && UOM !== "LB") {
    const pricePerPound = parseFloat((price / weightInPounds).toFixed(2));
    return getPricePerUnitWithCurrencyCodeAndUOM({
      price,
      UOM,
      pricePerUnit: pricePerPound,
      UOMOverride: "LB",
    });
  } else if (isCanadianLocale(currentLocale)) {
    const pricePerPound = parseFloat((price / Number(weight)).toFixed(2));
    return getPricePerUnitWithCurrencyCodeAndUOM({
      price,
      UOM,
      pricePerUnit: pricePerPound,
      UOMOverride: "LB",
    });
  }
};

export const getMinAndMaxWeight = (attribs: any[], UOM: string) => {
  let minMaxWeightInfo = "Weight: ";
  const variableMinWeightIndex = attribs?.findIndex(
    (el: IProductAttributes) => el.name === "variableMinWeight"
  );
  const variableMaxWeightIndex = attribs?.findIndex(
    (el: IProductAttributes) => el.name === "variableMaxWeight"
  );
  if (variableMinWeightIndex !== -1 && variableMaxWeightIndex == -1) {
    minMaxWeightInfo += `${attribs[variableMinWeightIndex].value} ${UOM}/ min`;
  } else if (variableMinWeightIndex === -1 && variableMaxWeightIndex !== -1) {
    minMaxWeightInfo += `${attribs[variableMaxWeightIndex].value} ${UOM}/ max`;
  } else if (variableMinWeightIndex !== -1 && variableMaxWeightIndex !== -1) {
    minMaxWeightInfo += `${attribs[variableMinWeightIndex].value} ${UOM}/ min | ${attribs[variableMaxWeightIndex].value} ${UOM}/ max`;
  } else {
    return "";
  }
  return minMaxWeightInfo;
};

export const getAllBadges = (attribs: any[], lang: string) => {
  const newBadges = [];
  const langSub = lang?.substring(0, 2) ?? "en";
  const badgesIndex = attribs?.findIndex(
    (el: IProductAttributes) => el.name === "badge"
  );
  if (badgesIndex > -1) {
    const badgeList = attribs[badgesIndex]?.value;
    for (let i = 0; i < badgeList?.length; i++) {
      newBadges.push(badgeList[i]?.label[langSub]);
    }
  }
  return newBadges;
};

export const showErrorMsg = (err: any, dispatch: AppDispatch) =>
  showSnackbar({
    title: "Error",
    message: err.message,
    type: "error" as SnackBarType,
    variant: "filled" as SnackBarVariant,
    dispatch: dispatch,
  });

export const updateCartItems = (dispatch: AppDispatch) =>
  dispatch(
    getCartItems({
      checkInventory: false,
      errorCodeTranslation: {},
    })
  );

export enum FilterItemType {
  ViewItem = "view-item",
}

export const filterItemDetailsForDataLayer = (
  data: any,
  type: FilterItemType
) => {
  if (type === FilterItemType.ViewItem) {
    return {
      item_id: data?.masterVariant?.sku ?? "",
      item_name: data?.name ? data?.name["en-CA"] : "",
      price: getPriceOrDiscounted(data?.masterVariant?.prices) ?? "",
      quantity: 1,
      coupon: "",
    };
  }
};

export const getUpdatedDiscountCodeList = (
  appliedDiscounts: IDiscount[],
  discountCode: string
) => {
  const discountCodeList: string[] = appliedDiscounts.map(
    (discount: any) => discount.code
  );
  const discountIndex = discountCodeList.findIndex(
    (discountCodeFromList: string) => discountCodeFromList === discountCode
  );
  if (discountIndex !== -1) {
    discountCodeList.splice(discountIndex, 1);
  }
  return discountCodeList;
};
