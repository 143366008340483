import { isANumber } from "./validation";

export const numberOrZero = (
  val: number | string | undefined | null
): number => {
  let result = 0;
  if (!val || !isANumber(val)) {
    // if null, undefined, or is a string that cannot be a number
    result = 0;
  } else {
    result = Number(val); // we assert it as a number in case it is a string as a number
  }
  return result;
};
