export const MAX_AGE = "max-age";
export const X_CORRELATION_ID = "x-correlation-id";
export const X_WILDFORK_REQ_ID = "X-WILDFORK-REQ-ID";

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};
