import { useEffect, useRef } from "react";
import { COOKIE_KEYS, STORAGE_KEYS, STORAGE_TYPES } from "@Constants/storage";
import { TDispatch } from "@Types/common";
import { getItem } from "@Utils/browserStorage";
import { getCookie } from "@Utils/cookies";
import { useInitGoogleMaps } from "@wff/hooks/useInitGoogleMaps";
import { usePickupAvailableForCA } from "@wff/hooks/usePickupAvailableForCA";
import { useZipCode } from "@wff/hooks/useZipCode";
import {
  cartSliceSelector,
  getCartItems,
  setCartIdHandler,
  setStoreId,
} from "@wff/store/cartSlice";
import { useAppDispatch, useAppSelector } from "@wff/store/hooks";

export const dispatchCartFromCookie = ({
  dispatch,
}: {
  dispatch: TDispatch;
}) => {
  const cookieValue = getCookie(COOKIE_KEYS.CART_ID);
  dispatch(setCartIdHandler(cookieValue));
  if (cookieValue) {
    dispatch(
      getCartItems({
        checkInventory: false,
        errorCodeTranslation: {},
      })
    );
  }
};

export const useDispatchCartFromCookie = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatchCartFromCookie({
      dispatch,
    });
  }, [dispatch]);
};

export const useDispatchStoreIdFromStorage = () => {
  const { storeId } = useAppSelector(cartSliceSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const currentStoreIdInStorage = getItem(
      STORAGE_KEYS.STORE_ID,
      STORAGE_TYPES.LOCAL
    );
    if (
      currentStoreIdInStorage &&
      (!storeId || currentStoreIdInStorage !== storeId)
    ) {
      dispatch(setStoreId(currentStoreIdInStorage));
    }
  }, [dispatch, storeId]);
};

export interface IUseLaunchZipCodePopUp {
  zipcodePopupOpen: boolean | undefined;
  zipcode: string;
  handleToggle: () => void;
}
export interface IUseOnClickOutside {
  ref: any;
  isCanadianUser: boolean;
  hideCloseButton: string | undefined;
  handleToggle: () => void;
}

export const useLaunchZipCodePopUp = ({
  zipcodePopupOpen,
  zipcode,
  handleToggle,
}: IUseLaunchZipCodePopUp) => {
  useEffect(() => {
    // Toggle the Zipcode Popup Open if there is no zipcode in local storage
    const currentZipcodeInStorage = getItem(
      STORAGE_KEYS.CUSTOMER_ZIP,
      STORAGE_TYPES.LOCAL
    );
    if (!zipcodePopupOpen && !currentZipcodeInStorage && !zipcode.length)
      handleToggle();
  }, [zipcodePopupOpen, handleToggle, zipcode]);
};

export const useOnClickOutside = ({
  ref,
  isCanadianUser,
  hideCloseButton,
  handleToggle,
}: IUseOnClickOutside) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (isCanadianUser) {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        !hideCloseButton && handleToggle();
      }
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, isCanadianUser, hideCloseButton, handleToggle]);
};

export const useDispatchZipCodeFromStorage = () => {
  const { zipcode, handleSubmitZipcode } = useZipCode();
  const { getPickUpAvailable } = usePickupAvailableForCA();
  const { initGoogleMapsLoaded } = useInitGoogleMaps();
  const zipcodeWasValidated = useRef(false);

  useEffect(() => {
    if (!zipcodeWasValidated.current && initGoogleMapsLoaded) {
      if (zipcode) {
        handleSubmitZipcode();
        getPickUpAvailable(zipcode, initGoogleMapsLoaded);
      }
      zipcodeWasValidated.current = true;
    }
  }, [handleSubmitZipcode, getPickUpAvailable, zipcode, initGoogleMapsLoaded]);
};
