import { nextAppEnv } from "@Utils/environment";
import RestEndpoints from "@wff/configs/RestEndpoints";
import ApiService from "@wff/services/ApiService";
import type { ApiConfigTypes } from "@wff/services/ApiService";
import countriesDialCode from "../../constants/countriesDialCode.json";

/**
 * Constructs encoded authorization
 * @returns {string} - Base64 encoded config
 */
const getBasicAuthentication = () => {
  const username = nextAppEnv.twilio.accountSid;
  const password = nextAppEnv.twilio.token;
  const encodeBase64 = Buffer.from(`${username}:${password}`).toString(
    "base64"
  );

  return `Basic ${encodeBase64}`;
};

/**
 * Constructs api header
 * @returns {ApiConfigTypes} - Headers config updated
 */
const prepareRequestConfig = (_data: any, config: ApiConfigTypes) => {
  const basicAuthentication = getBasicAuthentication();
  config.headers = {
    ...config.headers,
    Authorization: basicAuthentication,
  };

  return config;
};

/**
 * Constructs api endpoint url
 * @returns {string} - Endpoint url
 */
const prepareEndPoint = (_data: any) => {
  const country = countriesDialCode.find(
    ({ value }) => value === _data.countryCode
  );

  return `${RestEndpoints.CHECKOUT.TWILIO_PHONE_LOOKUP}${country?.dialCode}${_data.phoneNumber}`;
};

// NOTE: THIS IS LEGACY CODE. DO NOT USE AS TEMPLATE FOR NEW API SERVICES - ALL NEW ENDPOINTS SHOULD GO IN /API
/**
 * PhoneLookup Class
 * @returns {*} PhoneLookup class instance
 */
class PhoneLookupService extends ApiService.Service {
  constructor() {
    super({
      method: ApiService.methods.GET,
      getEndPoint: prepareEndPoint,
      getRequestConfig: prepareRequestConfig,
    });
  }
}

export default new PhoneLookupService();
