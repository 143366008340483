import { AuthProvider } from "context";
import { appWithTranslation } from "next-i18next";
import { Provider } from "react-redux";
import type { Store } from "redux";
import { store } from "store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import type { AppProps } from "next/app";
import Script from "next/script";
import AppGate from "@Components/AppGate";
import ModalEnvComponent from "@Components/modalEnv/ModalEnv";
import SnackBar from "@Components/ui/snackbar/SnackBar";
import { JsonObject } from "@Types/basic";
import logger from "@Utils/Logger";
import { amInNonProdEnvironment, nextAppEnv } from "@Utils/environment";
import { amInLocalQaOrDevDomain } from "@Utils/url";
import RestEndpoints from "@wff/api/constants/RestEndpoints";
import { usePopUpRuleForCA } from "@wff/hooks/usePopUpRuleForCA";
import { usePreserveScrollCA } from "@wff/hooks/usePreserveScrollCA";
import { makeStaticProps } from "@wff/libs/i18n/getStatic";
import appTheme from "../styles/mui/theme";
import { initFontAwesome } from "../utils/initialize";
import "../styles/globals.css";
import "../styles/_base.scss";
import "../styles/style.scss";
import "../styles/ui/_index.scss";
import "react-slideshow-image/dist/styles.css";

initFontAwesome();
interface Props extends AppProps {
  Component: any;
  pageProps: any;
  store: Store;
  pageModel: JsonObject;
}

const theme = createTheme(appTheme);
// TODO/NOTE - we should be using createStyles() in order for the theme to work with typescript
// see MUI docs
// here: https://mui.com/styles/api/#createstyles-styles-styles   &&
// here: https://mui.com/guides/typescript/#main-content

function App(props: Props) {
  const { Component, pageProps } = props;

  // isCanadaUser decide the feature rendering for US and CA
  const isCanadaUser = pageProps?.pageModel?.siteGlobal?.name === "CA Site";
  usePopUpRuleForCA(isCanadaUser);

  usePreserveScrollCA(isCanadaUser);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <AppGate>
            <Component {...pageProps} />
            <Script
              async
              type="text/javascript"
              src={`${RestEndpoints.KLAVIYO.SCRIPT_URL}?company_id=${nextAppEnv.klaviyo.token}`}
              onError={(error: any) => {
                logger.error("Failed to load Klaviyo script", error);
                throw new Error("Failed to load Klaviyo script");
              }}
            />
            {(amInNonProdEnvironment() || amInLocalQaOrDevDomain()) && (
              <ModalEnvComponent />
            )}
            <SnackBar />
          </AppGate>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
}

const getStaticProps = makeStaticProps(["common"]);
export { getStaticProps };

export default appWithTranslation(App);
// export default storeWrapper.withRedux(App);
