import {
  composeValidators,
  validEmail,
  validName,
  validAddress,
  validCity,
  validZip,
  validPhone,
  validCoupon,
  minLengthCurrier,
} from "@Components/forms/helpers/FieldValidator";
import { keyIsNotIgnored } from "@Utils/validation";
import { IUserContact } from "@wff/interfaces";
import {
  IStringValidator,
  IValidateBillingAddressFieldOptions,
} from "@wff/interfaces/validators";

export interface IEverythingBagel {
  [key: string]: any;
}

export interface IBillingAddressFieldValidators {
  [key: string]: IStringValidator;
}

export interface IPickUpAddressFieldValidators {
  [key: string]: IStringValidator;
}

export const billingAddressFieldValidators: IBillingAddressFieldValidators = {
  email: (value) => {
    return composeValidators(value, [validEmail, minLengthCurrier(2)]);
  },
  firstName: (value) => {
    return composeValidators(value, [validName, minLengthCurrier(2)]);
  },
  lastName: (value) => {
    return composeValidators(value, [validName, minLengthCurrier(2)]);
  },
  address1: (value) => {
    return composeValidators(value, [validAddress, minLengthCurrier(2)]);
  },
  address2: (value) => {
    // optional text field: only validate if it has a length
    return !value?.length
      ? true
      : composeValidators(value, [validAddress, minLengthCurrier(1)]);
  },
  city: (value) => {
    return composeValidators(value, [validCity, minLengthCurrier(2)]);
  },
  postalCode: (value, options) => {
    return composeValidators(
      value as string,
      [validZip, minLengthCurrier(2)],
      options
    );
  },
  phone: (value) => {
    return composeValidators(value as string, [
      validPhone,
      minLengthCurrier(2),
    ]);
  },
  state: (value) => {
    return composeValidators(value, [minLengthCurrier(1)]); // defaulted select field / just confirm that it has a value
  },
  country: (value) => {
    return composeValidators(value, [minLengthCurrier(1)]); // defaulted select field / just confirm that it has a value
  },
  dialCode: (value) => {
    return composeValidators(value, [minLengthCurrier(2)]);
  },
};

export const pickUpAddressFieldValidators: IPickUpAddressFieldValidators = {
  email: (value) => {
    return composeValidators(value, [validEmail, minLengthCurrier(2)]);
  },
  firstName: (value) => {
    return composeValidators(value, [validName, minLengthCurrier(2)]);
  },
  lastName: (value) => {
    return composeValidators(value, [validName, minLengthCurrier(2)]);
  },
  phone: (value) => {
    return composeValidators(value, [validPhone, minLengthCurrier(2)]);
  },
};

export const validateBillingAddressFields = (
  userData: IUserContact,
  validatorType: String,
  options?: IValidateBillingAddressFieldOptions
): boolean => {
  if (!Object.keys(userData).length) {
    return false;
  }
  return Object.keys(userData as Object).every((key) => {
    const validator =
      validatorType === "billing"
        ? billingAddressFieldValidators[key]
        : pickUpAddressFieldValidators[key];
    if (keyIsNotIgnored(key, options?.ignoreKeys as string[]) && validator) {
      return validator(userData[key] as string, options);
    } else {
      return true;
    }
  });
};

export const couponCodeFieldValidator: IStringValidator = (value) => {
  return !value.length
    ? true
    : composeValidators(value, [validCoupon, minLengthCurrier(2)]);
};

export const validateAllTheThings = (
  stateObj: IEverythingBagel,
  validationobj: IEverythingBagel,
  options?: IEverythingBagel
): boolean => {
  return Object.keys(stateObj).every((key) => {
    if (
      validationobj[key] &&
      keyIsNotIgnored(key, options?.ignoreKeys as string[])
    ) {
      return validationobj[key](stateObj[key], options);
    } else {
      return true;
    }
  });
};
