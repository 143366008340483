import { TSelectValue } from "./common";

export interface AddressComponentValues {
  long_name: string;
  short_name: string;
}

export interface AddressComponents {
  route: AddressComponentValues;
  locality: AddressComponentValues;
  political: AddressComponentValues;
  administrative_area_level_2: AddressComponentValues;
  administrative_area_level_1: AddressComponentValues;
  country: {
    short_name: "US" | "CA" | "MX";
    long_name: string;
  };
  postal_code: AddressComponentValues;
  [x: string]: AddressComponentValues;
}

export type IBillingAddress = {
  [key: string]: TSelectValue;
  id?: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  phone: string;
  postalCode: string;
  state: TSelectValue;
  dialCode?: string;
  country: string;
};
export interface IShippingAddress extends IBillingAddress {
  email: string;
  saveAddress?: boolean;
}

export enum PaymentMethods {
  CreditCard,
  Paypal,
}

export enum BillingAddressOptions {
  sameAsShippingAddress,
  differentAddress,
}

export type CreditCardFormValues = {
  cardNumber: string;
  nameOnCard: string;
  expirationDate: string;
  securityCode: string;
};

export type IPaymentResponse = {
  resultCode: string;
  sessionData: string;
};

export type ICheckoutResponse = {
  amount: { currency: string; value: number };
  countryCode: string;
  expiresAt: string;
  id: string;
  merchantAccount: string;
  reference: string;
  returnUrl: string;
  sessionData: string;
};

export type IEncryptedFormState = {
  data: {
    riskData: {
      clientData: string;
    };
    paymentMethod: {
      type: string;
      encryptedCardNumber?: string;
      encryptedExpiryMonth?: string;
      encryptedExpiryYear?: string;
      encryptedSecurityCode?: string;
    };
    browserInfo: {};
    clientStateDataIndicator: boolean;
  };
  valid: {
    encryptedCardNumber: boolean;
    encryptedExpiryMonth: boolean;
    encryptedExpiryYear: boolean;
    encryptedSecurityCode: boolean;
  };
  errors: {
    encryptedCardNumber?: "" | null;
    encryptedExpiryMonth?: "" | null;
    encryptedExpiryYear?: "" | null;
    encryptedSecurityCode?: "" | null;
  };
  isValid: boolean;
  selectedBrandValue?: string;
};
export type IEncryptedCardData = {
  encryptedCardNumber?: string;
  encryptedExpiryMonth?: string;
  encryptedExpiryYear?: string;
  encryptedSecurityCode?: string;
};
