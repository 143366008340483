import { useEffect } from "react";
import { getDomain } from "@Utils/url";
import { whereAmI } from "@Utils/whereAmI";
import { environmentSelector, setAppDomain } from "@wff/store/environmentSlice";
import { useAppDispatch, useAppSelector } from "@wff/store/hooks";

export const useDomain = () => {
  const dispatch = useAppDispatch();
  const { appDomain } = useAppSelector(environmentSelector);
  const currentDomain = getDomain(whereAmI(), { removePort: true });
  useEffect(() => {
    if (currentDomain !== appDomain) {
      dispatch(setAppDomain(currentDomain));
    }
  }, [appDomain, currentDomain, dispatch]);
};
