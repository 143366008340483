import { useEffect } from "react";
import { useRouter } from "next/router";
import { setCookie } from "@Utils/cookies";

export interface UseCookieFromQueryParamProps {
  queryParamKey: string;
  cookieName?: string;
  defaultValue?: string;
}

export const useCookieFromQueryParam = ({
  queryParamKey,
  cookieName,
  defaultValue,
}: UseCookieFromQueryParamProps) => {
  const { query } = useRouter();
  const queryParam = query[queryParamKey] || defaultValue;
  const cookieKey = cookieName || queryParamKey;

  useEffect(() => {
    if (queryParam) {
      setCookie(cookieKey, queryParam);
    }
  }, [queryParam, cookieKey]);
};
