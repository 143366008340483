// Customer facing facets is hardcoded in frontend, expected to come from algolia in future
export const customerFacingFilterNames = [
  { key: "subCategories", value: "Category" },
  { key: "programDescription", value: "Type" },
  { key: "portion", value: "Portion Size" },
  { key: "grade", value: "Program & Grade" },
  { key: "cuisine", value: "Cuisine Type" },
  { key: "dietaryAndLifestyle", value: "Dietary & Lifestyle" },
];

export enum AlgoliaFilterType {
  SORT_BY = "sort-by",
  SELECTED_FACETS_LIST = "selectedFacetsList",
  PRICE = "price",
}
export interface FilterName {
  [AlgoliaFilterType.SORT_BY]: number;
  [AlgoliaFilterType.SELECTED_FACETS_LIST]: number;
  [AlgoliaFilterType.PRICE]: number;
}

// ?INFO: selectedFacetsList needs to be at 2nd place because StickyFacetsMenu component identifies the selected filter through the filters present at 1st index.
export const customerFacingFilterOrder: FilterName = {
  [AlgoliaFilterType.SORT_BY]: 1,
  [AlgoliaFilterType.SELECTED_FACETS_LIST]: 2,
  [AlgoliaFilterType.PRICE]: 3,
};
//NOTE: OLD facets
// export const FacetList = [
//   "categories",
//   "subCategories",
//   "ProgramDescription",
//   "CookingMethod",
//   "Portion",
//   "Special",
//   "Cuts",
//   "Grade",
//   "Cuisine",
//   "CountryOfOrigin",
//   "Thickness",
//   "Butchery",
//   "SustainablySourced",
//   "SpiceLevel",
//   "ShrimpSizing",
//   "TypeOfMeat",
//   "DietaryAndLifestyle",
// ];

export const getCustomerFacingFilterSortOrder = (facetName: string) => {
  if (customerFacingFilterOrder[facetName as keyof FilterName]) {
    return customerFacingFilterOrder[facetName as keyof FilterName];
  }
  return Number.MAX_SAFE_INTEGER;
};

export const facetsList = [
  "categories",
  "subCategories",
  "programDescription",
  "cookingMethod",
  "portion",
  "special",
  "cut",
  "grade",
  "cuisine",
  "countryOfOrigin",
  "Thickness",
  "Butchery",
  "sustainablySourced",
  "spiceLevel",
  "shrimpSizing",
  "typeOfMeat",
  "dietaryAndLifestyle",
];

export const PRICE_VALUE = "price.value.centAmount";

export const priceFilterData = {
  type: "price",
  name: "Price",
  items: [
    {
      name: "Under $10",
      value: "under10",
    },
    {
      name: "$10 to $20",
      value: "10to20",
    },
    {
      name: "$20 to $30",
      value: "20to30",
    },
    {
      name: "$30 to $50",
      value: "30to50",
    },
    {
      name: "$50 & Above",
      value: "50andAbove",
    },
  ],
  sortOrder: getCustomerFacingFilterSortOrder(AlgoliaFilterType.PRICE),
};
