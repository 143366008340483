import { StorageType } from "@Utils/browserStorage";

interface IStorageTypes {
  [key: string]: StorageType;
}
export const STORAGE_TYPES: IStorageTypes = {
  SESSION: "session",
  LOCAL: "local",
};
export const STORAGE_KEYS = {
  STORE_ID: "wf-store-id",
  DELIVERY_WINDOW: "deliveryWindow",
  CUSTOMER_ZIP: "wf-customer-zipcode",
  CUSTOMER_ZIP_AVAILABILITY_CA: "wf-ca-customer-zipcode-availability",
  CUSTOMER_ZIP_PICKUP_AVAILABILITY_CA:
    "wf-ca-customer-zipcode-pickup-availability",
  INCREMENT_PAYLOAD_CA: "wf-ca-increment-payload",
  TIMER_TRIGGERED_CA: "wf-ca-timer-triggered",
  ACTIVE_DELIVERY_TYPE_CA: "wf-ca-delivery-type",
  SCROLL_POSITION_PLP_CA: "wf-ca-scroll-position",
  COOKIE_REJECTED_CA: "wf-ca-website-cookie-rejected",
  COOKIE_ACCEPTED_CA: "wf-ca-website-cookie-accepted",
  PAYMENT_METHOD: "wf-ca-payment-method",
  DY_QTY_CHANGE_EVENT_PREV_VALUE: "wf-ca-dy-qtychange-event-prev-value",
  DY_COOKIE_CONSENT: "wf-ca-dy-cookie-consent",
  DY_PRODUCTS_SEARCH_CRITERIA: "wf-ca-dy-products-search-criteria",
};
export const COOKIE_KEYS = {
  CART_ID: "cart_id",
  WEBSITE_COOKIE: "website_cookie",
  DYID: "_dyid",
  DYID_SERVER: "_dyid_server",
};
