import { store } from "store";
import {
  GENERIC_ERR_MSG,
  AUTO_ERROR_CODE_MESSAGE_TRANSLATION,
  AUTO_ERROR_CODE_HEADING_TRANSLATION,
  IGetTranslationFromErrorCode,
} from "@Constants/snackbar";
import { SnackBarType, SnackBarVariant } from "@Types/SnackBar";
import { TDispatch } from "@Types/common";
import { TApiCatchBlock } from "@Types/error";
import {
  IErrorCodeTranslation,
  TErrorCode,
  AXIOS_ERROR_CODES,
} from "@wff/api/constants/error";
import {
  ICodeMessage,
  ICustomBackendError,
  ICustomFrontendError,
} from "@wff/interfaces/error";
import { triggerSnackBar } from "@wff/store/SnackBarSlice";
import logger from "./Logger";
import {
  findErrorCode,
  getErrorHeadingFromApiResponse,
  getErrorMessageFromApiResponse,
  findErrorCodeForCA,
} from "./errors";

let axiosErrorCount = 0;

interface ICustomLogger {
  heading?: string | object | number | boolean;
  details?: string | object | number | boolean;
}

export interface IHeadingAndMessage {
  heading?: string; // if not provided, a fallback value will be generated
  message?: string; // if not provided, a fallback value will be generated
}

export interface IScenario extends IHeadingAndMessage {
  [key: string]: string | undefined;
  code: TErrorCode;
}

interface IAxiosErrorHandlerProps<TData, TError> {
  error: TApiCatchBlock<TData, TError> | unknown;
  dispatch?: TDispatch;
  leadingSnackbar?: ICustomFrontendError;
  fallbackSnackbar?: ICustomFrontendError;
  customLogger?: ICustomLogger;
  scenarios?: IScenario[];
  applyAutoErrorScenarios?: boolean;
  suppressOrphanErrors?: boolean;
  suppressFallback?: boolean;
  errorCodeIgnoreList?: TErrorCode[];
}

interface IStandardSnackbarErrorTrigger {
  heading: string;
  message: string;
  dispatch: TDispatch;
}

export const AXIOS_ERROR_HANDLER_PREPENDED_HEADING =
  "snackbarHandlers.ts axiosErrorHandler()";

export const standardSnackbarErrorTrigger = ({
  heading,
  message,
  dispatch,
}: IStandardSnackbarErrorTrigger) => {
  return dispatch(
    triggerSnackBar({
      message: message,
      title: heading,
      type: "error" as SnackBarType,
      variant: "filled" as SnackBarVariant,
    })
  );
};

export const getScenariosFromErrorCodeTranslation = ({
  errorCodeTranslation,
}: {
  errorCodeTranslation?: IErrorCodeTranslation;
}): IScenario[] => {
  let scenarios: IScenario[] = [];
  if (errorCodeTranslation) {
    scenarios = Object.keys(errorCodeTranslation).map((key) => {
      const scenario: IScenario = {
        code: key as TErrorCode,
        heading: "",
        message: "",
      };
      if (typeof errorCodeTranslation[key as TErrorCode] === "string") {
        scenario.message = errorCodeTranslation[key as TErrorCode] as string;
      } else {
        scenario.heading = (
          errorCodeTranslation[key as TErrorCode] as IHeadingAndMessage
        )?.heading;
        scenario.message = (
          errorCodeTranslation[key as TErrorCode] as IHeadingAndMessage
        )?.message;
      }
      return scenario;
    });
  }
  return scenarios ?? [];
};

export const axiosErrorHandler = <TData = unknown, TError = unknown>({
  error,
  dispatch = store.dispatch,
  leadingSnackbar, // will use a custom heading/message as a leading snackbar that will always render first on any error (also will override a fallbackSnackbar since it would be redundant to render both)
  fallbackSnackbar, // renders IF no scenarios match AND IF leadingSnackbar isn't rendered
  customLogger, // will log additional custom heading/details info as provided
  scenarios, // an array of custom heading/message objects that will render as snackbars only when specific error-code scenario(s) match
  applyAutoErrorScenarios = true, // will automatically add predefined error code scenarios [without needing to specify them in the scenarios prop]
  suppressOrphanErrors = true, // will block the rendering of snackbars for error codes from the response that did not match against provided/auto scenarios [when set to true: Errors in the response which were not represented in scenarios would Not be generically presented at all]
  suppressFallback = false, // will suppress the fallback snackbar; a fallback can still potentially render even if a fallbackSnackbar prop is not provided. To Avoid All fallbacks, set suppressFallback to true
  errorCodeIgnoreList = [], // will completely skip all specified error codes from provided/auto scenarios
}: // eslint-disable-next-line sonarjs/cognitive-complexity
IAxiosErrorHandlerProps<TData, TError>) => {
  axiosErrorCount++;
  const immutableOriginalErrorList: ICodeMessage[] =
    (error as unknown as ICustomBackendError)?.response?.data?.errors?.length >
    0
      ? [...(error as unknown as ICustomBackendError)?.response?.data?.errors]
      : [];
  let errorList =
    immutableOriginalErrorList?.length > 0
      ? [...immutableOriginalErrorList]
      : [];
  const matchedScenarioList: IScenario[] = [];
  // [leading snackbar phase]
  const errorConnectionAborted = findErrorCodeForCA({
    code: AXIOS_ERROR_CODES.ECONNABORTED,
    error,
  }); // if axios error code is ECONNABORTED then hide snackbar

  if (leadingSnackbar && !errorConnectionAborted) {
    const heading =
      leadingSnackbar?.heading || getErrorHeadingFromApiResponse(error);
    const message =
      leadingSnackbar?.message || getErrorMessageFromApiResponse(error);
    standardSnackbarErrorTrigger({ heading, message, dispatch });
  }
  // [scenarios & errorList phase]
  if ((scenarios && scenarios?.length) || applyAutoErrorScenarios) {
    const scenariosToApply = scenarios?.length ? [...scenarios] : [];
    if (applyAutoErrorScenarios) {
      /**  applyScenarios()
       *                    This function adds auto scenarios.
       *                    If scenariosToApply already has the error code scenario then
       *                    it overrides any default auto value(s) with what is provided in scenarios
       **/
      const applyScenarios = (
        translationEntity: IGetTranslationFromErrorCode,
        key: string
      ) => {
        Object.keys(translationEntity).forEach((autoErrCodeKey) => {
          const indexOfErrorCode = scenariosToApply.findIndex((el) => {
            return el.code === autoErrCodeKey;
          });
          if (indexOfErrorCode === -1) {
            scenariosToApply.push({
              code: autoErrCodeKey as TErrorCode,
              [key]: translationEntity[autoErrCodeKey](),
            });
          } else {
            // if the error code is already in scenariosToApply then we check to see if the [heading or message] has an undefined/null value, and if so we apply a corresponding predefined translation if one exists
            if (
              scenariosToApply[indexOfErrorCode] &&
              !scenariosToApply[indexOfErrorCode][key]
            ) {
              scenariosToApply[indexOfErrorCode][key] =
                translationEntity[autoErrCodeKey]?.();
            }
          }
        });
      };
      applyScenarios(AUTO_ERROR_CODE_HEADING_TRANSLATION, "heading");
      applyScenarios(AUTO_ERROR_CODE_MESSAGE_TRANSLATION, "message");
    }

    scenariosToApply.forEach((scenario: IScenario) => {
      const errorCodeFound = findErrorCode({ code: scenario.code, error });
      if (errorCodeFound) {
        if (!scenario.heading) {
          scenario.heading = errorCodeFound.code; // use as a fallback value
        }
        if (!scenario.message) {
          scenario.message = errorCodeFound?.message || GENERIC_ERR_MSG.ERROR(); // use as a fallback value
        }
        matchedScenarioList.push(scenario);
        // remove from errorList so we do not render a snackbar for it twice when suppressOrphanErrors === false
        errorList = errorList.filter((err) => {
          return err.code !== scenario.code;
        });
      }
    });
  }

  // everyErrorIsInIgnoreList is for specific scenarios when we do not want to unintentionally suppress a fallback snackbar merely by using errorCodeIgnoreList
  const everyErrorIsInIgnoreList =
    immutableOriginalErrorList.length > 0 &&
    immutableOriginalErrorList.every((el) => {
      return errorCodeIgnoreList?.includes(el.code);
    });

  const finalMatchedScenarioList = matchedScenarioList.filter((el) => {
    return !errorCodeIgnoreList?.includes(el.code as TErrorCode);
  });

  finalMatchedScenarioList.forEach((scenario) => {
    // render matching scenarios
    standardSnackbarErrorTrigger({
      heading: scenario.heading!,
      message: scenario.message!,
      dispatch,
    });
  });
  // [fallback phase]
  // [suppressOrphanErrors: false] is typically useful for development which will guarantee all error codes are painted to the screen
  if (!suppressOrphanErrors && errorList.length) {
    // will render error codes (as returned by the API) that were not matched in the scenarios list
    // this is last in the list of snackbars (other than the Fallback phase below) because it is in a format the customer cares the least about
    errorList.forEach((err) => {
      standardSnackbarErrorTrigger({
        heading: err.code,
        message: err.message,
        dispatch,
      });
    });
  }
  //  this part of the fallback phase represents either [no error codes returned] or [a failure to match error codes]
  if (
    !suppressFallback &&
    !leadingSnackbar && // if leadingSnackbar already rendered then the fallback is redundant
    finalMatchedScenarioList?.length === 0 && // nothing matched - either there were 0 scenarios (incredibly unlikely but ok lol) or (there were either No errors, or only orphan error codes returned)
    (!errorList?.length || (suppressOrphanErrors && errorList?.length > 0)) && // there were [no error codes returned] Or [only orphan error codes but we suppressed rendering them as provided
    !everyErrorIsInIgnoreList && // if finalMatchedScenarioList is empty and it is Not because all returned errors were in the ignore list
    !errorConnectionAborted // if axios error code is ECONNABORTED then hide snackbar
  ) {
    standardSnackbarErrorTrigger({
      heading:
        fallbackSnackbar?.heading || getErrorHeadingFromApiResponse(error),
      message:
        fallbackSnackbar?.message || getErrorMessageFromApiResponse(error),
      dispatch,
    });
  }
  // [logging phase]
  if (leadingSnackbar) {
    logger.error(
      `Error Group #${axiosErrorCount} ${AXIOS_ERROR_HANDLER_PREPENDED_HEADING} [custom snackbar]`,
      leadingSnackbar
    );
  }
  logger.error(
    `Error Group #${axiosErrorCount} ${AXIOS_ERROR_HANDLER_PREPENDED_HEADING} [standard trace]`,
    error
  );
  if (customLogger) {
    logger.error(
      `Error Group #${axiosErrorCount} ${AXIOS_ERROR_HANDLER_PREPENDED_HEADING} ${
        customLogger?.heading || ""
      } [custom trace]`,
      customLogger?.details || ""
    );
  }
};
