import { useCallback } from "react";
import Router from "next/router";
import {
  GENERIC_ERR_MSG,
  GENERIC_EVENT_ERR_HEADING,
} from "@Constants/snackbar";
import { withAsync } from "@Libs/api/withAsync";
import { axiosErrorHandler } from "@Utils/snackbarHandlers";
import { getCustomerInfo, submitLogoutCustomer } from "@wff/api/customerApi";
import { ICustomerInfo } from "@wff/interfaces";
import {
  CustomerInfoSelector,
  resetCustomerInfo,
  setCustomerInfo,
  setIsMember,
} from "@wff/store/customerInfoSlice";
import { useAppDispatch, useAppSelector } from "@wff/store/hooks";
import { reset as resetOrderHistory } from "@wff/store/orderHistorySlice";

export const useCustomer = () => {
  const dispatch = useAppDispatch();
  const customerInfo = useAppSelector(CustomerInfoSelector);

  const setCustomer = (customer: ICustomerInfo) => {
    dispatch(setCustomerInfo(customer));
  };

  const isMemberHandler = useCallback(
    (isMember: boolean) => {
      dispatch(setIsMember(isMember));
    },
    [dispatch]
  );

  const redirectToHomePage = () => {
    Router.push("/en-CA/");
  };

  const logoutCustomer = () => {
    withAsync(() => submitLogoutCustomer()).then(({ error }) => {
      if (error) {
        axiosErrorHandler({
          error,
          fallbackSnackbar: {
            heading: GENERIC_EVENT_ERR_HEADING.REQUEST_FAILED(),
            message: GENERIC_ERR_MSG.ERROR(),
          },
        });
      }
    });
    dispatch(resetCustomerInfo());
    dispatch(resetOrderHistory());
    redirectToHomePage();
  };

  const getCustomerDetailsIfLoggedIn = useCallback(async () => {
    const { response, error } = await withAsync(() => getCustomerInfo());
    if (error) {
      dispatch(resetCustomerInfo());
    } else {
      dispatch(setCustomerInfo(response?.data));
    }
    return { response, error };
  }, [dispatch]);

  const getCustomerDetails = useCallback(async () => {
    const { error } = await getCustomerDetailsIfLoggedIn();
    if (error) {
      axiosErrorHandler({
        error,
        dispatch,
        fallbackSnackbar: {
          heading: GENERIC_EVENT_ERR_HEADING.REQUEST_FAILED(),
          message: error?.response?.data?.errors?.[0]?.message,
        },
      });
    }
  }, [dispatch, getCustomerDetailsIfLoggedIn]);

  const isMember = !!customerInfo.member;
  const isLoggedIn = !!customerInfo.email;

  return {
    customerInfo: {
      ...customerInfo,
      fullName: `${customerInfo.firstName} ${customerInfo.lastName}`, // TODO from Jared to Mauricio: investigate a possible fix for resetting to empty string so !fullName behaves as expected
    },
    getCustomerDetails,
    getCustomerDetailsIfLoggedIn,
    isMember,
    isLoggedIn,
    logoutCustomer,
    setCustomer,
    isMemberHandler,
  };
};
