import { createSlice } from "@reduxjs/toolkit";
import { IRemoveItemsModalState } from "../types/remove-items-modal";
import { RootState } from ".";

export const initialState: IRemoveItemsModalState = {
  open: false,
};

export const removeItemsModalSlice = createSlice({
  name: "removeItemsModal",
  initialState,
  reducers: {
    toggleModal(state) {
      return { ...state, open: !state.open };
    },
  },
});
export const removeItemsModalActions = removeItemsModalSlice.actions;

export const removeItemsModalSelector = (state: RootState) =>
  state.removeItemsModal;

export default removeItemsModalSlice.reducer;
