import axios from "axios";
import {
  YotpoBottomLineRatingData,
  YotpoBottomLineRatingResult,
} from "@Types/Yotpo";
import { nextAppEnv } from "@Utils/environment";

import RestEndpoints from "./constants/RestEndpoints";

/**
 * API Client for the Yotpo UGC API.
 *
 * Ref: https://apidocs.yotpo.com/reference/welcome
 */
export const yotpoApi = axios.create({
  baseURL: nextAppEnv.yotpo.baseUrl,
  headers: {
    Accept: "application/json",
  },
});

/**
 * Retrieve bottom line (total reviews and average score) for a specific product
 *
 * Ref: https://apidocs.yotpo.com/reference/get-bottom-line-total-reviews-and-average-score
 *
 * @param {string} productId - product id to use for request.
 * @returns {YotpoBottomLineRatingResult}
 */
export const getBottomLineRating = async (
  productId: string
): Promise<YotpoBottomLineRatingData> => {
  const response = await yotpoApi.get<YotpoBottomLineRatingResult>(
    `${RestEndpoints.YOTPO.PRODUCTS}/${nextAppEnv.yotpo.appKey}/${productId}/${RestEndpoints.YOTPO.STAR_RATING}`
  );
  return response.data.response;
};
