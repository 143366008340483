import { JsonObject } from "@Types/basic";
import { isTypeOf } from "@Utils/validation";
import {
  DEFAULT_FETCH_CONFIG,
  getHttpRequestConfig,
} from "@wff/configs/FetchConfig";
import { MAX_AGE, HTTP_METHODS } from "@wff/constants/HTTP";
import logger from "@wff/utils/Logger";

/**
 * Construct Request URL
 * @param {string} path - base path bame
 * @param {object} params - params object
 */
export const getRequestUrl = (path: string, params?: JsonObject) => {
  let url = path;
  params &&
    Object.keys(params).forEach((key) => {
      /* istanbul ignore else */
      if (params[key as string]) {
        if (url && url.indexOf("?") < 0) {
          url += "?";
        }
        url += key + "=" + params[key as string] + "&";
      }
    });

  if (url && url.lastIndexOf("&") === url.length - 1) {
    url = url.substr(0, url.length - 1);
  }

  return url;
};

interface ServicePropsTypes {
  method: string;
  getEndPoint: (data?: any) => string;
  getRequestPayload?: (data: any) => any;
  getResponseError?: (data: any) => any;
  getResponsePayload?: (data: any) => JsonObject;
  getRequestConfig?: (data: any, config: any) => any;
}

export type ApiConfigTypes = {
  headers?: any;
  cache?: RequestCache;
  async?: boolean;
  timeout?: number;
  "max-age"?: number;
  credentials?: RequestCredentials;
};

/**
 *  ApiService Class
 *  @param {object} props
 *  @param {string} props.method - HTTP Method for the request
 *  @param {function} props.getEndPoint - Function to constructs and returns the service endpoint
 *  @param {function} props.getRequestConfig - Function to control any config which are passed in the API call
 *  @param {function} props.getRequestPayload - Function which creates request body
 *  @param {function} props.getResponsePayload - Function to handle success response payload
 *  @param {function} props.getResponseError - Function to find out business errors in success response.
 *  @returns {*} ApiService with default HTTP Methods
 */
class Service {
  serviceProps: ServicePropsTypes;

  constructor(props: ServicePropsTypes) {
    this.serviceProps = props;
  }

  getRequestHeaders(options: JsonObject, url: string) {
    // construct default headers
    // override the default header values
    // headers[X_CORRELATION_ID] = '';
    const newConfig = getHttpRequestConfig({
      url,
      config: DEFAULT_FETCH_CONFIG,
    });
    return { ...newConfig.headers, ...options };
  }

  constructRequestBody(requestPayload: BodyInit) {
    if (isTypeOf(requestPayload, "object")) {
      try {
        return JSON.stringify(requestPayload);
      } catch (e) {
        logger.warn(
          "ApiService.ts constructRequestBody() [Error creating the request payload]",
          e
        );
      }
    }

    // if requestPayload type is not object then submit as form data
    return requestPayload;
  }

  handleFailureResponse(
    eCode?: string,
    eMessage?: string,
    serviceResponse = {}
  ) {
    const errResponse = {
      code: eCode || "API_FAIL",
      message: eMessage || "Request failed, Please try again later.",
      serviceResponse,
    };

    return Promise.reject(errResponse);
  }

  async handleSuccessResponse(response: any) {
    //check if response object present
    if (response) {
      const body = await response
        .text()
        .catch((e: Error) =>
          logger.error(
            "ApiService.ts handleSuccessResponse() [Error parsing response payload]",
            e
          )
        );

      //try to parse the response
      let responsePayload;
      try {
        responsePayload = JSON.parse(body);
      } catch (e) {
        responsePayload = body;
      }

      //construct response with headers
      const serviceResponse = {
        headers: response.headers,
        payload: responsePayload,
        responseCode: response.status,
      };

      //look for errors in response payload
      if (isTypeOf(this.serviceProps.getResponseError, "function")) {
        const payloadError =
          this.serviceProps.getResponseError?.(responsePayload);
        if (payloadError) {
          return this.handleFailureResponse(
            payloadError.code,
            payloadError.message,
            serviceResponse
          );
        }
      }

      // resolve promise only if response ends with ok (status code in the range 200 - 299)
      // reject promise if status code >299 or if found any payload errors
      if (response.ok) {
        if (isTypeOf(this.serviceProps.getResponsePayload, "function")) {
          serviceResponse.payload =
            this.serviceProps.getResponsePayload?.(responsePayload);
        }
        return Promise.resolve(serviceResponse);
      } else {
        return this.handleFailureResponse(
          response.status,
          response.statusText,
          serviceResponse
        );
      }
    }

    //if none of the above fallback to the default error handler
    return this.handleFailureResponse();
  }

  // NOTE: THIS IS LEGACY CODE. DO NOT USE AS TEMPLATE FOR NEW API SERVICES - ALL NEW ENDPOINTS SHOULD GO IN /API
  async invoke(data?: JsonObject, options = {}) {
    //construct service endpoint
    let url = "";
    if (this.serviceProps.method === HTTP_METHODS.GET) {
      url = getRequestUrl(this.serviceProps.getEndPoint(data), data);
    } else {
      url = getRequestUrl(this.serviceProps.getEndPoint(data));
    }

    //prepare default config
    let configs = {
      headers: this.getRequestHeaders(options, url),
      cache: DEFAULT_FETCH_CONFIG.cache,
      async: DEFAULT_FETCH_CONFIG.async,
      timeout: DEFAULT_FETCH_CONFIG.timeout,
      [MAX_AGE]: DEFAULT_FETCH_CONFIG.max_age,
      credentials: "same-origin", // forward cookie details only if the request is made to same origin
    } as ApiConfigTypes;

    if (isTypeOf(this.serviceProps.getRequestConfig, "function")) {
      configs =
        getHttpRequestConfig({
          url,
          config: this.serviceProps.getRequestConfig?.(data, configs),
        }) ?? getHttpRequestConfig({ url, config: configs });
    }

    //construct request payload
    let payload = null;
    if (
      isTypeOf(this.serviceProps.getRequestPayload, "function") &&
      this.serviceProps.method !== HTTP_METHODS.GET &&
      this.serviceProps.method !== HTTP_METHODS.DELETE
    ) {
      payload = this.serviceProps.getRequestPayload?.(data);
    }

    //construct request param
    const reqOptions = {
      ...configs,
      method: this.serviceProps.method,
      body: payload ? this.constructRequestBody(payload) : undefined,
    };

    //fetch request with callbacks
    const fetchApi = fetch || window.fetch;
    logger.info(`APIService.ts [invoke request]`, url);
    const response = await fetchApi(url, reqOptions).catch(async (e) => {
      logger.error(`ApiService.ts [Fetch Exception] for ${url}`, e);
      return await this.handleFailureResponse();
    });

    //process the response
    return this.handleSuccessResponse(response);
  }
}

const ApiService = {
  Service,
  methods: HTTP_METHODS,
};

export default ApiService;
