import { FC, useState, useCallback } from "react";
import { IContactForm } from "@wff/interfaces";
import { contextFactory } from "../helpers/contextFactory";

export interface ContactUsContextProps {
  contactForm: IContactForm;
  setContactFormHandler: (value: string, fieldKey: string) => void;
}

export interface ContactUsProviderProps {
  initialContactForm?: IContactForm;
}

export interface ISetContactFormHandler {
  (value: string, fieldKey: string): void;
}

export const defaultContactForm = () => {
  return {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  };
};

const [useContactUs, ContactUsContext] =
  contextFactory<ContactUsContextProps>();

export { ContactUsContext, useContactUs };

export const ContactUsProvider: FC<ContactUsProviderProps> = ({
  children,
  initialContactForm,
}) => {
  const [contactForm, setContactForm] = useState(
    initialContactForm || {
      ...defaultContactForm(),
    }
  );

  const setContactFormHandler: ISetContactFormHandler = useCallback(
    (value: string, fieldKey: string) => {
      setContactForm((currentContactFormState: IContactForm) => {
        return {
          ...currentContactFormState,
          ...{
            [fieldKey]: value ?? "",
          },
        };
      });
    },
    [setContactForm]
  );

  return (
    <ContactUsContext.Provider value={{ contactForm, setContactFormHandler }}>
      {children}
    </ContactUsContext.Provider>
  );
};
