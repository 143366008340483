import { useEffect, useRef, useState } from "react";
import timerWorkerForCA from "@Components/zipCodePopUp/workers/timerWorkerForCA";
import { STORAGE_KEYS, STORAGE_TYPES } from "@Constants/storage";
import { setItem } from "@Utils/browserStorage";

export const usePopUpRuleForCA = (isCanadaUser = true) => {
  const workerRef = useRef<Worker | null>(null);
  const [workerContextValue, setWorkerContextValue] = useState({
    context: "worker-ca",
    fromWorkerCA: {
      message: {
        twoMintsPassed: false,
      },
    },
  });

  useEffect(() => {
    if (isCanadaUser) {
      if (typeof Worker !== "undefined") {
        const worker = new Worker(
          URL.createObjectURL(new Blob([`(${timerWorkerForCA})()`]))
        );

        // Store a reference to the worker thread
        workerRef.current = worker;

        // Send a message to the worker thread
        worker.postMessage("TIMER_EVENT");

        // Listen for messages from the worker thread
        worker.onmessage = (event) => {
          const workerContextValue = {
            context: "worker-ca",
            fromWorkerCA: event?.data,
          };
          setItem(STORAGE_KEYS.TIMER_TRIGGERED_CA, "true", STORAGE_TYPES.LOCAL);
          setWorkerContextValue(workerContextValue);
        };

        return () => {
          if (workerRef?.current) {
            // Terminate the worker thread
            workerRef?.current?.terminate();
            workerRef.current = null;
          }
        };
      } else {
        console.error("Worker API is not available");
      }
    }
  }, [isCanadaUser]);

  return workerContextValue;
};
