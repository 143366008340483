import { IOrderData } from "@wff/interfaces";

export const formatZipcode = (zipcode: string, country: string) => {
  let newZipcode = zipcode;
  if (country?.toUpperCase() === "CA" && zipcode.length >= 6) {
    const zip = zipcode.match(/^(\w\d\w)\W?(\d\w\d)$/);
    newZipcode = [zip?.[1], zip?.[2]].join(" ").toUpperCase();
  }

  return newZipcode;
};

const SHIPPING_METHODS = ["In-Store", "Same Day Local"];
//CA-ONLY
/**
 * This method returns the Shipping Method related labels for Payment Confirmation page
 * @param cartSlice
 * @param isMember
 * @param orderSubmit
 * @returns
 */

export const renderShippingMethodForCA = (
  cartSlice: any,
  isMember: boolean,
  orderSubmit: IOrderData
) => {
  let message = "";
  const shippingMethod = orderSubmit?.deliveryMethod;
  const shippingTotal = Number(cartSlice?.shippingTotal);

  if (SHIPPING_METHODS.includes(shippingMethod)) {
    message = "checkout.payment.in_store_pickup";
  } else if (isMember) {
    message = "checkout.payment.member_delivery";
  } else if (shippingTotal !== 0) {
    message = "checkout.payment.non_member_delivery";
  }

  return message;
};
