import { createSlice } from "@reduxjs/toolkit";
import { IEnvironmentState } from "@Types/environment";
import { amInLocalQaOrDevDomain } from "@Utils/url";
import { RootState } from ".";

export const initialState: IEnvironmentState = {
  appDomain: "",
  localDevOrQaDomain: false,
};

export const environmentSlice = createSlice({
  name: "environment",
  initialState,
  reducers: {
    // Setters:
    setAppDomain(state, action) {
      return {
        ...state,
        appDomain: action.payload,
        localDevOrQaDomain: amInLocalQaOrDevDomain(),
      };
    },
  },
});
export const { setAppDomain } = environmentSlice.actions;

export const environmentSelector = (state: RootState) => state.environment;

export default environmentSlice.reducer;
