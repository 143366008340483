import localeDetails from "@Constants/localeDetails.json";
import { ILocaleDetail } from "@wff/interfaces";
import { nextAppEnv } from "./environment";
import { globalCache } from "./globalCache";
import countries from "../constants/Country.json";
import stateListCanada from "../constants/states/CA.json";
import stateListMexico from "../constants/states/MX.json";
import stateListUsa from "../constants/states/US.json";

export const getStateList = (country?: string) => {
  let stateList;
  switch (country?.toLowerCase()) {
    case "us":
    case "united states":
      stateList = stateListUsa;
      break;
    case "ca":
    case "canada":
      stateList = stateListCanada;
      break;
    case "mx":
    case "mexico":
      stateList = stateListMexico;
      break;
    default:
      stateList = stateListUsa;
      break;
  }
  return stateList;
};

export const getStateName = (country: string, code: string) => {
  const appliedCache: any = globalCache.applyCountryStateCache(country, code);
  return appliedCache[country][code];
};

export const isCanadianLocale = (locale: Partial<ILocaleDetail> | string) => {
  const langCode = typeof locale === "string" ? locale : locale?.languageCode;
  return (
    langCode?.toLowerCase() === "en-ca" || langCode?.toLowerCase() === "fr-ca"
  );
};

export const findIndexOfLocaleInJsonFile = (newLocale: string): number => {
  if (!newLocale) {
    return -1;
  }
  return localeDetails.findIndex((locale: ILocaleDetail) => {
    return locale.languageCode.toLowerCase() === newLocale.toLowerCase();
  });
};

export const findIndexOfCountryShortInJsonFile = (
  countryShort: string
): number => {
  if (!countryShort) {
    return -1;
  }
  return localeDetails.findIndex((locale: ILocaleDetail) => {
    return locale.countryShort.toLowerCase() === countryShort.toLowerCase();
  });
};

export const getCountryAbbreviationFromLangCode = (
  langCode: string
): string | undefined => {
  const localeIndex = findIndexOfLocaleInJsonFile(langCode);
  if (localeIndex < 0) {
    return undefined;
  }
  return localeDetails[localeIndex].countryShort;
};

export const getLangCodeFromCountryShort = (
  countryCode: string
): string | undefined => {
  const localeIndex = findIndexOfCountryShortInJsonFile(countryCode);
  if (localeIndex < 0) {
    return undefined;
  }
  return localeDetails[localeIndex].languageCode;
};

export const getAlternativeCountryShort = () => {
  return (
    (nextAppEnv.application.locale &&
      getCountryAbbreviationFromLangCode(
        nextAppEnv.application.locale as string
      )) ??
    localeDetails[0].countryShort // first element of localeDetails represents default
  );
};

export const getAlternativeLangCode = () => {
  return nextAppEnv.application.locale || localeDetails[0].languageCode; // first element of localeDetails represents default
};

// somtimes the backend sends us the full country name when we just want the short abbreviation
export const forceCountryAbbreviation = (countryAbbrev: string) => {
  const fieldKey = countryAbbrev?.length <= 3 ? "value" : "text"; // In Case Back end sends Full Country Name
  return countries?.find((el) => {
    return el[fieldKey]?.toLowerCase() === countryAbbrev?.toLowerCase();
  });
};

export const getFullCountryObject = (countryAbbrev: string) => {
  return countries?.find((el: any) => {
    return el.value?.toLowerCase() === countryAbbrev?.toLowerCase();
  });
};
