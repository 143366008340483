import { useEffect } from "react";
import { NextRouter, useRouter } from "next/router";
import { COMMERCE_TOOLS_DOMAINS, shopifyQaDomain } from "@Constants/url";
import { nextAppEnv } from "@Utils/environment";
import {
  amInLocalQaOrDevDomain,
  pathJoinHttp,
  removeTrailingSlash,
} from "@Utils/url";
import { useAppDispatch, useAppSelector } from "@wff/store/hooks";

interface IModifyRouteNavigationCurrier {
  router: NextRouter;
  languageCode: string;
  testEnvironment?: boolean;
}

export const modifyRouteNavCurrier = ({
  router,
  languageCode,
  testEnvironment = false,
}: IModifyRouteNavigationCurrier): ((_toRoute: string) => void) => {
  return (toRoute: string) => {
    const toRouteLower = removeTrailingSlash(toRoute).toLocaleLowerCase();
    const localeLower = languageCode.toLocaleLowerCase();
    if (nextAppEnv.application.domainRedirect === "true" || testEnvironment) {
      const inProdEnv = !amInLocalQaOrDevDomain();
      const domain = inProdEnv
        ? COMMERCE_TOOLS_DOMAINS.PROD.ORIGINAL
        : shopifyQaDomain;
      let newRoute;
      switch (toRouteLower) {
        case `/`:
        case `/${localeLower}`:
          newRoute = `/`;
          break;
        case `/cart`:
        case `/${localeLower}/cart`:
          newRoute = `/cart/`;
          break;
        case `/${localeLower}/account`:
        case `/account`:
          newRoute = `/account/`;
          break;
        case `/${localeLower}/account/login`:
        case `/account/login`:
          newRoute = `/account/login/`;
          break;
        case `/membership-tiers`:
        case `/${localeLower}/membership-tiers`:
          newRoute = `/pages/membership-tiers/`;
          break;
        case `/contact-us`:
        case `/${localeLower}/contact-us`:
          newRoute = `/pages/contact-us/`;
          break;
        case `/checkout?step=0`:
        case `/checkout/?step=0`:
        case `/${localeLower}/checkout?step=0`:
        case `/${localeLower}/checkout/?step=0`:
          newRoute = `/pages/checkout?step=0`;
          break;
        default:
          newRoute = null;
      }
      if (newRoute) {
        router.replace(pathJoinHttp(domain, newRoute));
      }
    }
  };
};

export const useRouteRedirect = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const localeSlice = useAppSelector((state) => state.locale);
  const languageCode = localeSlice?.languageCode;

  const modifyRouteNavCurried = modifyRouteNavCurrier({ router, languageCode });

  useEffect(() => {
    router.events.on("routeChangeStart", modifyRouteNavCurried);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", modifyRouteNavCurried);
    };
  }, [languageCode, dispatch, router, router.events, modifyRouteNavCurried]);
};
