import { isEqual } from "lodash";
import React, { useEffect, useState, useCallback } from "react";
import { nextAppEnv } from "@Utils/environment";
import classes from "./ModalEnv.module.scss";

const ModalComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [pressedKeys, setPressedKeys] = useState<number[]>([]);

  const handleKeyDown = useCallback(
    (event) => {
      const shortcut = [
        "ArrowUp",
        "ArrowUp",
        "ArrowDown",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
        "ArrowLeft",
        "ArrowRight",
        "b",
        "a",
      ];
      const newPressedKeys = [...pressedKeys, event.key];
      setPressedKeys(newPressedKeys);
      if (isEqual(newPressedKeys, shortcut)) {
        setIsOpen(true);
      }
    },
    [pressedKeys]
  );

  useEffect(() => {
    let timer: any;
    if (pressedKeys.length) {
      timer = setTimeout(() => setPressedKeys([]), 1500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [pressedKeys]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
  return (
    <>
      {isOpen ? (
        <>
          <div
            data-testid="BG"
            className={classes["darkBG"]}
            onClick={() => setIsOpen(false)}
          />
          <div className={classes["centered"]}>
            <div className={classes["modal"]}>
              <div className={classes["modalHeader"]}>
                <h1 className={classes["heading"]}>NEXT APP ENV VARS</h1>
              </div>
              <div className={classes["modalContent"]}>
                {Object.keys(nextAppEnv).map((key) => {
                  return (
                    <p key={key}>
                      <h3>{key}</h3>
                      <div>
                        {JSON.stringify((nextAppEnv as any)[key], null, 4)}
                      </div>
                    </p>
                  );
                })}
              </div>
              <div className={classes["modalActions"]}>
                <div className={classes["actionsContainer"]}>
                  <button
                    className={classes["cancelBtn"]}
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ModalComponent;
