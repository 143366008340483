import { createSlice } from "@reduxjs/toolkit";
import { IOrderData } from "@wff/interfaces";
import { RootState } from ".";

const initialStateAddress = {
  id: "",
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
  phone: "",
};

export const initialState: IOrderData = {
  orderNumber: "",
  lineItems: [],
  shippingAddress: {
    ...initialStateAddress,
    email: "",
  },
  billingAddress: {
    ...initialStateAddress,
  },
  tipReceived: 0,
  shippingTotal: 0,
  taxTotal: 0,
  subTotal: 0,
  currencyType: "",
  totalPrice: 0,
  fulfillmentStoreNumber: "",
  createdAt: "",
  deliveryWindow: {
    startTime: 0,
    endTime: 0,
    fulfillmentType: "",
    carrierName: "",
  },
  paymentMethod: "",
  deliveryMethod: "",
  orderState: "",
  removedLineItems: [],
  discountAmount: 0,
};

export const OrderSubmitSlice = createSlice({
  name: "orderSubmit",
  initialState,
  reducers: {
    setOrderSubmitResponse(state, action) {
      return { ...state, ...action?.payload };
    },
  },
});
export const { setOrderSubmitResponse } = OrderSubmitSlice.actions;

export const OrderSubmitSelector = (state: RootState) => state.orderSubmit;

export default OrderSubmitSlice.reducer;
